import { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { AppRouteUrl } from '../../AppRoutes';
import { Localize } from '../../AppLocalization';

// Modely
import { ProjectLite, ProjectFilter, ProjectState, ProjectType, TaskState, TaskType, User, SaveAction, TaskTemplate } from '../../models/Models';

// Utility
import { Export as DataGridExport, Settings as DataGridSettings, LocalizeGrid } from '../../utility/DataGrid';
import { useQuery } from '../../utility/URL';
import { format as dateFormat } from 'date-fns';

// Komponenty
import { DataGrid, GridColDef, GridValueFormatterParams, GridRenderCellParams, GridValueGetterParams, GridFilterModel, GridRowId, GridDensity, GridSortModel } from "@mui/x-data-grid";
import { Button, Chip, Divider, Grid, IconButton, ListItemIcon, Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Search from '../../components/Search';
import { Content, ContentTop, ContentBottom } from '../../layout/Content';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import ProjectCreate, { ProjectCreateProps } from './ProjectCreate';
import DataGridDensity from '../../components/DataGridDensity';
import ProjectsFiltering, { ProjectsFilteringProps } from './ProjectsFiltering';

// Ikony
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import ClearIcon from '@mui/icons-material/Clear';
import Debounce from '../../utility/Debounce';

// Komponent pre zoznam
const Projects = () => {

    // Jazyková mutácia
    const localization = Localize();

    // Číselníky
    const [projectStates, setProjectStates] = useState<ProjectState[]>([]);
    const [projectTypes, setProjectTypes] = useState<ProjectType[]>([]);
    const [taskStates, setTaskStates] = useState<TaskState[]>([]);
    const [taskTypes, setTaskTypes] = useState<TaskType[]>([]);
    const [taskTemplates, setTaskTemplates] = useState<TaskTemplate[]>([]);
    const [users, setUsers] = useState<User[]>([]);

    // Načítam číselníky jedným volaním
    const loadFormData = () => {
        axios
            .get(AppConfig.ApiUri + 'project/formdata')
            .then(response => {
                if (response.data) {
                    applyFormData(response.data);
                }
            });
    };
    useEffect(() => loadFormData(), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Funkcia pre naplnenie stavu číselníkov
    const applyFormData = (data: any) => {
        if (data.projectstate) {
            setProjectStates(data.projectstate);
        }
        if (data.projecttype) {
            setProjectTypes(data.projecttype);
        }
        if (data.taskstate) {
            setTaskStates(data.taskstate);
        }
        if (data.tasktype) {
            setTaskTypes(data.tasktype);
        }
        if (data.tasktemplate) {
            setTaskTemplates(data.tasktemplate);
        }
        if (data.user) {
            setUsers(data.user);
        }
    };

    // Lokálny stav
    const [loading, setLoading] = useState<boolean>(true);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [projectCreate, setProjectCreate] = useState<ProjectCreateProps>({
        open: false,
        keepMounted: true,
        projectStates: [],
        projectTypes: [],
        taskStates: [],
        taskTypes: [],
        taskTemplates: [],
        users: [],
        onSave: (id?: number, action?: SaveAction) => {
            // Obnovím zoznam
            loadDataSafe();

            // Rozšírené funkcie po uložení
            if (id !== undefined && action !== undefined) {
                if (action === SaveAction.SaveAndNew) {
                    handleCreate(0);
                    return;
                }
                if (action === SaveAction.SaveAndContinue) {
                    handleCreate(id ?? 0);
                    return;
                }
                if (action === SaveAction.SaveAndNewFromCopy) {
                    handleCreate(id ?? 0, true);
                    return;
                }
            }
        },
        onClose: () => setProjectCreate(prev => ({ ...prev, open: false }))
    });
    const [projectsFiltering, setProjectsFiltering] = useState<ProjectsFilteringProps>({
        open: false,
        keepMounted: true,
        projectStates: [],
        projectTypes: [],
        onSave: (filter) => setRowsFilter({ ...filter, page: 0 }),
        onClose: () => setProjectsFiltering(prev => ({ ...prev, open: false }))
    });

    // Nastavenie zobrazenia    
    const [density, setDensity] = useState<GridDensity>('standard' as GridDensity);

    // Nastavenia stĺpcov
    const gridSettings = new DataGridSettings({ uid: 'projects' });
    const columnsDefault: GridColDef[] = [
        { field: 'id', headerName: 'Id', hide: true, minWidth: 20, width: 90, type: 'number', align: 'center', headerAlign: 'center' },
        {
            field: 'date', headerName: localization.date, hide: false, minWidth: 50, flex: 0.2, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['date']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? dateFormat((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        { field: 'name', headerName: localization.title, hide: false, minWidth: 50, flex: 0.4 },
        { field: 'projectTypeName', headerName: localization.projectType, hide: false, minWidth: 50, flex: 0.3 },
        {
            field: 'projectStateName', headerName: localization.projectState, hide: false, minWidth: 60, flex: 0.4,
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => {
                let label = (params.row.projectStateName ?? '');
                let color = (params.row.projectStateColor ?? '');
                return <Chip label={label.length > 0 ? label : '...'} size="small" variant="filled" sx={{ bgcolor: '#' + (color.length > 0 ? color : 'f0f0f0'), color: (color.length > 0 ? '#ffffff' : '#222222') }} />
            }
        },
        {
            field: 'createdDate', headerName: localization.created, hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['createdDate']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? dateFormat((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        {
            field: 'updatedDate', headerName: localization.updated, hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => (new Date(params.row['updatedDate'])),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? dateFormat((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        {
            field: 'options', headerName: localization.options, hide: false, width: 120, sortable: false, filterable: false, align: 'right', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => (
                <>
                    <IconButton aria-label={localization.edit} title={localization.edit + ' (enter)'} size="small" onClick={() => handleCreate(params.value ?? 0, false)}>
                        <EditIcon color="primary" fontSize="small" />
                    </IconButton>
                    <IconButton aria-label={localization.copy} title={localization.copy + ' (ctrl + enter)'} size="small" onClick={() => handleCreate(params.value ?? 0, true)}>
                        <ContentCopyIcon fontSize="small" />
                    </IconButton>
                    <IconButton aria-label={localization.delete} title={localization.delete + ' (delete)'} size="small" onClick={() => handleDelete(params.value ?? 0, params.row.name)}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </>
            )
        }
    ];

    // Aplikujem uložené nastavenia
    useEffect(() => setColumns(gridSettings.columnApply(columns)), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Tabuľka
    const [rows, setRows] = useState<ProjectLite[]>([]);
    const [rowsSelected, setRowsSelected] = useState<GridRowId[]>([])
    const [rowsSelectedMenuEl, setRowsSelectedMenuEl] = useState<null | HTMLElement>(null);
    const [rowsCount, setRowsCount] = useState<number>(0);
    const [rowsFilter, setRowsFilter] = useState<ProjectFilter>({ page: 0, pageSize: gridSettings.pageSizeApply(25) });
    const [columns, setColumns] = useState<GridColDef[]>(columnsDefault);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();
    const [sortModel, setSortModel] = useState<GridSortModel>();

    // Funkcia pre získanie počtu aktívnych filtrov
    const filterCount = (): number => {
        let count = 0;
        if (rowsFilter.dateFrom !== undefined) { count++; }
        if (rowsFilter.dateTo !== undefined) { count++; }
        if ((rowsFilter.projectStateIds ?? '').length > 0) { count++; }
        if ((rowsFilter.projectTypeIds ?? '').length > 0) { count++; }
        return count;
    };

    // Ak sa v zozname zmení poradie, tak automaticky upravím filter
    useEffect(() => {
        // Predvolené
        let sort: string = '';
        let sortAsc: boolean = false;
        // Vybrané
        if (sortModel !== undefined && sortModel.length > 0) {
            sort = sortModel[0].field;
            sortAsc = (sortModel[0].sort === 'asc');
        }
        if (rowsFilter.sort !== sort || rowsFilter.sortAsc !== sortAsc) {
            setRowsFilter(prev => ({ ...prev, sort: sort, sortAsc: sortAsc }));
        }
    }, [sortModel]); // eslint-disable-line react-hooks/exhaustive-deps

    // Pridať upraviť záznam
    const handleCreate = (id: number, copy?: boolean, field?: string) => {
        setProjectCreate(prev => ({
            ...prev,
            id: id,
            copy: copy ?? false,
            open: true,
            autoFocus: field
        }));
    };

    // Úprava záznamu podľa "id" v URL
    const history = useHistory();
    const requestId: number = parseInt(useQuery().get('id') ?? '0');
    useEffect(() => {
        if (requestId > 0) {
            history.push(AppRouteUrl.PROJECTS);
            handleCreate(requestId);
        }
    }, [requestId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Vymazať záznam
    const handleDelete = (id: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: localization.deleteConfirmation, onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'project/' + id)
                    .then(response => {
                        if (response.data === true) {
                            setRows(prev => [...prev.filter(r => r.id !== id)]);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    const handleDeleteList = (ids: number[]) => {
        if (ids.length === 0) {
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: localization.delete + ': ' + ids.length, children: localization.deleteSeletedConfirmation, onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'project/list', {
                        params: {
                            'ids': ids
                        }
                    })
                    .then(response => {
                        if (response.data === true) {
                            setRows(prev => [...prev.filter(r => !ids.includes(r?.id ?? 0))]);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    const handleExportCSV = () => {
        if (rowsSelected.length === 0) {
            return;
        }
        DataGridExport({
            type: 'csv',
            columns: columns,
            columnsSkip: ['options'],
            rows: rows,
            ids: rowsSelected.map(r => r as number)
        });
    };

    const handleExportXML = () => {
        if (rowsSelected.length === 0) {
            return;
        }
        DataGridExport({
            type: 'xml',
            columns: columns,
            columnsSkip: ['options'],
            rows: rows,
            ids: rowsSelected.map(r => r as number)
        });
    };

    // Funkcia pre načítanie dát z API
    const loadData = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'project', {
                params: rowsFilter
            })
            .then(response => {
                setRows((response.data?.list ?? []) as ProjectLite[]);
                setRowsCount(response.data?.itemsCount ?? 0);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Funkcia pre bezpečné obnovenie zoznamu pomocou zmeny stavu (cudzie vlákno môže mať problém so stavom filtra)
    const loadDataSafe = () => {
        setRowsFilter(prev => ({ ...prev }));
    }

    // Automatická obnova zoznamu po otvorení stránky alebo zmene filtru
    const loadDataDebounce = Debounce(() => loadData(), 100);
    useEffect(() => loadDataDebounce(), [rowsFilter]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Formulár pre nový záznam */}
            <ProjectCreate {...projectCreate} projectStates={projectStates} projectTypes={projectTypes} taskStates={taskStates} taskTypes={taskTypes} taskTemplates={taskTemplates} users={users} />

            {/* Formulár pre filtráciu */}
            <ProjectsFiltering {...projectsFiltering} projectStates={projectStates} projectTypes={projectTypes} />

            {/* Obsah */}
            <Content>
                <ContentTop>
                    <Grid container alignItems="center">

                        {/* Možnosti */}
                        <Grid item xs={9} md lg>

                            {/* Nový záznam */}
                            <Button variant="contained" sx={{ mr: 1 }} size="large" startIcon={<AddIcon />} onClick={() => handleCreate(0)}>{localization.newRecord}</Button>

                            {/* Označené záznamy (možnosti) */}
                            <Button variant="text" size="large" disabled={rowsSelected?.length === 0} aria-controls="menu-selected" aria-haspopup="true" onClick={(e) => setRowsSelectedMenuEl(e.currentTarget)} endIcon={<ExpandMoreIcon />}>{localization.selected} {'(' + rowsSelected.length.toString() + ')'}</Button>
                            <Menu id="menu-selected" anchorEl={rowsSelectedMenuEl} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} sx={{ mt: '55px' }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} open={Boolean(rowsSelectedMenuEl)} onClose={() => setRowsSelectedMenuEl(null)} >
                                <MenuItem onClick={() => { handleExportCSV(); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> {localization.saveAs} CSV (Excel)
                                </MenuItem>
                                <MenuItem onClick={() => { handleExportXML(); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> {localization.saveAs} XML
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { handleDeleteList(rowsSelected.map(r => r as number)); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon> {localization.delete}
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { setRowsSelected([]); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><ClearIcon fontSize="small" /></ListItemIcon> {localization.cancelSelection}
                                </MenuItem>
                            </Menu>
                        </Grid>

                        {/* Zobrazenie */}
                        <Grid item xs md={1} textAlign="right">
                            <DataGridDensity onDensityChanged={setDensity} />
                        </Grid>

                        {/* Vyhľadávanie */}
                        <Grid item xs={12} md={4} sx={{ mt: { xs: 1, md: 0 } }}>
                            <Search
                                onSearch={s => setRowsFilter(prev => ({ ...prev, page: 0, search: s }))}
                                onClear={() => {
                                    setRowsFilter(prev => ({
                                        page: 0,
                                        pageSize: prev.pageSize,
                                        sort: prev.sort,
                                        sortAsc: prev.sortAsc
                                    }));
                                }}
                                autoFocus={true}
                                filter={true}
                                filterCount={filterCount()}
                                onFilter={() => setProjectsFiltering(prev => ({ ...prev, filter: rowsFilter, open: true }))}
                            />
                        </Grid>
                    </Grid>
                </ContentTop>
                <ContentBottom>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <DataGrid
                            getRowId={row => row.id}
                            density={density}
                            checkboxSelection
                            disableSelectionOnClick

                            columns={columns}
                            rows={rows}
                            rowCount={rowsCount}

                            pagination
                            paginationMode="server"
                            page={rowsFilter.page}
                            pageSize={rowsFilter.pageSize}

                            rowsPerPageOptions={[10, 25, 50]}
                            onPageChange={(page) => setRowsFilter(prev => ({ ...prev, page: page }))}
                            onPageSizeChange={(pageSize) => {
                                setRowsFilter(prev => ({ ...prev, page: 0, pageSize: pageSize }));
                                gridSettings.pageSizeChanged(pageSize);
                            }}

                            sortingMode="server"
                            sortModel={sortModel}
                            onSortModelChange={(model) => setSortModel(model)}

                            localeText={LocalizeGrid()}
                            loading={loading}

                            // Dvoj-klik (úprava)
                            onCellDoubleClick={(e) => {
                                handleCreate(e.row.id, false, e.field);
                            }}

                            // Klávesnica (shift+enter => upraviť, shift+delete => vymazať, shift+space => označiť, vstavaná funkcia)
                            onCellKeyDown={(e, c) => {
                                if (c.code === 'Enter' && c.ctrlKey) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    handleCreate(e.row.id, true, e.field);
                                    return;
                                }
                                if (c.code === 'Enter' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    handleCreate(e.row.id, false, e.field);
                                    return;
                                }
                                if (c.code === 'Delete' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    handleDelete(e.row.id, e.row.name);
                                    return;
                                }
                            }}

                            // Filtrácia
                            filterModel={filterModel}
                            onFilterModelChange={e => setFilterModel(e)}

                            // Vybrané záznamy
                            selectionModel={rowsSelected}
                            onSelectionModelChange={e => setRowsSelected(e)}

                            // Stĺpce (automatické ukladanie nastavení)
                            onColumnVisibilityChange={e => gridSettings.columnVisibilityChanged(e, columnsDefault)}
                        />
                    </div>
                </ContentBottom>
            </Content>

        </>
    )
}

export default Projects;