import React from 'react';
import { Localize } from '../../AppLocalization';

// Modely
import { TaskState, TaskStateHistory, User } from '../../models/Models';

// Utility
import { format as dateFormat } from 'date-fns';

// Komponenty
import { Avatar, Box, Chip, Typography } from '@mui/material';
import { NullMinDate } from '../../utility/Date';
import Timeline from '@mui/lab/Timeline';
import { TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from '@mui/lab';
import TimelineOppositeContent, { timelineOppositeContentClasses, } from '@mui/lab/TimelineOppositeContent';

// Ikony
import PersonIcon from '@mui/icons-material/Person';

interface TaskCreateHistoryProps {
    users: User[];
    taskStates: TaskState[];
    stateHistory?: TaskStateHistory[];
}

const TaskCreateHistory = (props: TaskCreateHistoryProps) => {

    // Všeobecne
    const localization = Localize();

    // Formátovanie dátumu
    const formatDate = (date: any): string => {
        if (NullMinDate(date) === null) {
            return '-';
        }
        return dateFormat(new Date(date), 'dd.MM.yyyy HH:mm');
    }

    return (
        <>
            {(props.stateHistory?.length ?? 0) === 0 && <Typography>{localization.noRecords}</Typography>}
            <Timeline
                sx={{
                    mt: 3,
                    [`& .${timelineOppositeContentClasses.root}`]: {
                        flex: 0.65,
                    },
                }}>
                {
                    props.stateHistory?.map((history, index) => {

                        // Nájdem relačné záznamy
                        let user: User = props.users.find(u => u.id === (history.createdUserId ?? 0)) ?? {};
                        var state = props.taskStates.find(state => state.id === history.taskStateId) ?? { name: '', color: '333333' };

                        return (
                            <TimelineItem key={index}>
                                <TimelineOppositeContent>
                                    <Chip label={state.name} sx={{ mt: -0.6, bgcolor: '#' + ((state.color ?? '').length > 0 ? state.color : 'f0f0f0'), color: ((state.color ?? '').length > 0 ? '#ffffff' : '#222222') }} />
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot variant='outlined' />
                                    {(index + 1) < (props.stateHistory?.length ?? 0) && <TimelineConnector />}
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Box sx={{ mt: -1.2, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        {
                                            user.fileIsImage ?
                                                <Avatar alt={user.name} src={user.fileSrcSmall} /> :
                                                <Avatar><PersonIcon /></Avatar>
                                        }
                                        <Box sx={{ ml: 2 }}>
                                            <Typography sx={{ fontWeight: 'bold' }}>{user.name}</Typography>
                                            <Typography variant="body2" color="secondary">{formatDate(history.createdDate)}</Typography>
                                        </Box>
                                    </Box>
                                </TimelineContent>
                            </TimelineItem>
                        );
                    })
                }
            </Timeline>
        </>
    )
}

export default TaskCreateHistory