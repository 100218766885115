import axios from 'axios';
import React, { useState } from 'react'
import { AppConfig } from '../../AppConfig';
import { Alert, AlertColor, Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, InputAdornment } from '@mui/material';
import { Localize } from '../../AppLocalization';

// Ikony
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

interface Props {
    onClose: () => void;
}

const PasswordChange = (props: Props) => {

    // Jazyková mutácia
    const localization = Localize();

    const [loading, setLoading] = React.useState(false);
    const [warning, setWarning] = useState('');
    const [warningSeverity, setWarningSeverity] = useState('warning');

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        const password = data.get('password')?.toString() ?? '';
        const passwordVerify = data.get('passwordverify')?.toString() ?? '';

        if(password.length === 0) {
            setWarningSeverity('warning');
            setWarning(localization.mustFill + ': ' + localization.password + '!');
            return;
        }
        if(password !== passwordVerify) {
            setWarningSeverity('warning');
            setWarning(localization.passwordNotMatch);
            return;
        }

        setWarning('');
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'user/passwordchange', null, { params: {
                'password': password
            }})
            .then(response => {
                if(response.data === 'success') { 
                    setWarningSeverity('success');
                    setWarning(localization.saved);
                    (event.target as HTMLFormElement).reset();
                } else {
                    setWarningSeverity('error');
                    setWarning(localization.saveFailed);
                }
            })
            .finally(() => {
                setLoading(false);
            }); 
    };

    return (
        <>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog keepMounted={false} maxWidth="xs" fullWidth open={true} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Box component="form" onSubmit={handleSubmit} noValidate>
                    <DialogTitle>{localization.changePassword}</DialogTitle>
                    <DialogContent>
                        <TextField margin="dense" autoFocus name="password" type="password" required label={localization.password} fullWidth variant="outlined" autoComplete="new-password"
                            InputProps={{
                                endAdornment: (
                                <InputAdornment position="end">
                                    <LockOutlinedIcon />
                                </InputAdornment>
                                ),
                            }}
                        />
                        <TextField margin="dense" name="passwordverify" type="password" required label={localization.passwordAgain} fullWidth variant="outlined" autoComplete="new-password" 
                            InputProps={{
                                endAdornment: (
                                <InputAdornment position="end">
                                    <LockOutlinedIcon />
                                </InputAdornment>
                                ),
                            }}
                        />
                        {(warning.length > 0 && <Alert sx={{ mt: 1 }} severity={ warningSeverity as AlertColor }>{warning}</Alert>)}  
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.onClose}>{localization.back}</Button>
                        <Button type="submit" variant="contained">{localization.save}</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default PasswordChange;
