interface AppConfigType {
    /** Koncový bod API */
    ApiUri: string;
    /** Jazyk */
    Language: string;
    /** Názvy pre súbory cookie */
    CookieName: {
        /** Názov cookies pre aktuálnu reláciu prihlásenia */
        LoggedSession: string;
        /** Názov cookies pre automatické prihlasovanie */
        LoggedSessionRemember: string;
    },
    /** Nastavenia zoznamu */
    DataGrid: {
        /** Použiť modifikačnú klávesu pre úravu */
        UseShiftKey: boolean;
    },
    /** Nahrávanie súborov */
    FileUpload: {
        /** Maximálna veľkosť (MB) nahrávaného súboru (pri fotkách sa kontroluje veľkosť po zmenšení) */
        MaxFileSize: number;        
        /** Maximálny rozmer (px) obrázku pre automatické zmenšenie na strane klienta */
        ResizeMaxSize: number;
    }
}

export const AppConfig: AppConfigType = {
    ...((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 
    {
        // Vývoj 
        ApiUri: 'http://localhost:7005/api/',
        TemplatesUri: 'http://localhost:7005/templates/'
    }
    : 
    {   
        // Produkcia
        ApiUri: '/api/'
    }),
    Language: 'fr',
    // Ostatné (nezávislé od produkcie a vývoja)
    CookieName: {
        LoggedSession: '_s',
        LoggedSessionRemember: '_r'
    },
    DataGrid: {
        UseShiftKey: false
    },
    FileUpload: {
        MaxFileSize: 200, // Rovnaký limit 200 MB, je nastaený aj v API
        ResizeMaxSize: 2000
    }
};