import React, { useContext, useState } from 'react';
import { Localize } from '../../AppLocalization';
import { AppContext } from '../../AppContext';

// Modely
import { Task, TaskNote, User } from '../../models/Models';
import TaskCreateNote, { TaskCreateNoteProps } from './TaskCreateNote';

// Utility
import { format as dateFormat } from 'date-fns';
import parse from 'html-react-parser';

// Komponenty
import { Avatar, Button, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import Confirm, { ConfirmProps } from '../../components/Confirm';

// Ikony
import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';

// Vstupné parametre
export interface TasksDetailNotesProps {
    readonly?: boolean;
    users: User[];
    source: Task;
    onAddNote: (note: TaskNote) => void;
}

const TasksDetailNotes = (props: TasksDetailNotesProps) => {

    // Globálne premenné
    const appContext = useContext(AppContext);

    // Jazyková mutácia
    const localization = Localize();

    // Stav (editácia položky)
    const [taskCreateNote, setTaskCreateNote] = useState<TaskCreateNoteProps>({
        open: false,
        onSave: (note) => props.onAddNote(note),
        onClose: () => setTaskCreateNote(prev => ({ ...prev, open: false }))
    });

    // Pridať upraviť záznam
    const handleCreate = () => setTaskCreateNote(prev => ({ ...prev, open: true }));

    return (
        <>

            {/* Nový záznam */}
            <TaskCreateNote {...taskCreateNote} />

            <Grid container columnSpacing={1}>

                {(props.readonly ?? false) === false && (
                    <Grid item xs={12} mb={0}>
                        <Button disabled={props.readonly} variant="contained" size="small" startIcon={<AddIcon />} color="secondary" sx={{ mr: 1 }} onClick={() => handleCreate()}>{localization.newRecord}</Button>
                    </Grid>
                )}

                {/* Zoznam */}
                {
                    props.source.notes?.map((note, index) => {

                        let userId: number = (note.id ?? 0) > 0 ? (note.createdUserId ?? 0) : (appContext.user?.id ?? 0);
                        let user: User = props.users.find(u => u.id === userId) ?? {};

                        return (
                            <Grid item xs={12} mt={2} key={index}>
                                <Card>
                                    <CardHeader
                                        avatar={
                                            user.fileIsImage ?
                                                <Avatar alt={user.name} src={user.fileSrcSmall} /> :
                                                <Avatar><PersonIcon /></Avatar>
                                        }
                                        title={<Typography sx={{ fontWeight: 'bold' }}>{user.name}</Typography>}
                                        subheader={dateFormat(new Date(note.createdDate ?? new Date()), 'dd.MM.yyyy HH:mm')}
                                    />
                                    <CardContent sx={{ py: 0 }}>
                                        <div className='html-description'>
                                            {parse(note.note ?? '')}
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    )
}

export default TasksDetailNotes;