import { Localize } from '../../AppLocalization';

// Modely
import { TaskState, Activity, ActivityType } from '../../models/Models';

// Utility
import { format as dateFormat } from 'date-fns';

// Komponenty
import { Alert, Avatar, Box, Chip, Link, Typography } from '@mui/material';
import { TimelineItem, TimelineOppositeContent, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from '@mui/lab';

// Ikony
import PersonIcon from '@mui/icons-material/Person';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';

interface ActivitiesItemProps {
    item: Activity;
    connector: boolean;
    taskStates: TaskState[];
    onTaskDetail: (id: number) => void;
}

const ActivitiesItem = (props: ActivitiesItemProps) => {

    // Jazyková mutácia
    const localization = Localize();

    // Vráti stav úlohy
    const createTaskStateChip = (taskStateId: number): JSX.Element => {
        var taskState = props.taskStates.find(taskState => taskState.id === taskStateId);
        return <Chip size="small" label={taskState?.name} sx={{ mx: 1, bgcolor: '#' + ((taskState?.color ?? '').length > 0 ? taskState?.color : 'f0f0f0'), color: ((taskState?.color ?? '').length > 0 ? '#ffffff' : '#222222') }} />;
    }

    return (
        <TimelineItem>
            <TimelineOppositeContent color="textSecondary">
                {dateFormat(new Date(props.item.createdDate ?? new Date()), 'HH:mm')}
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot />
                {props.connector && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent sx={{ mt: -0.4, pb: 3 }}>
                <Chip
                    sx={{ mb: 1 }}
                    label={props.item.createdUser?.name}
                    avatar={(
                        props.item.createdUser?.fileIsImage ?
                            <Avatar sx={{ width: 32, height: 32 }} alt={props.item.createdUser?.name} src={props.item.createdUser?.fileSrcSmall} /> :
                            <Avatar sx={{ width: 32, height: 32 }}><PersonIcon /></Avatar>
                    )}
                />
                <Box sx={{ px: 1 }}>

                    {props.item.activityType === ActivityType.TaskCreated &&
                        <>
                            <Typography component='span' variant='body2'>{localization.activityTaskCreated}: </Typography>
                            <Link component="a" variant="body2" href="#" onClick={(e) => { props.onTaskDetail(props.item.itemId ?? 0); e.preventDefault(); e.stopPropagation(); }}>{props.item.itemName}.</Link>
                        </>}

                    {props.item.activityType === ActivityType.TaskUpdated &&
                        <>
                            <Typography component='span' variant='body2'>{localization.activityTaskUpdated}: </Typography>
                            <Link component="a" variant="body2" href="#" onClick={(e) => { props.onTaskDetail(props.item.itemId ?? 0); e.preventDefault(); e.stopPropagation(); }}>{props.item.itemName}</Link>&nbsp;
                            <Typography component='span' variant='body2' color='silver'>({props.item.note?.toLowerCase()}).</Typography>
                        </>}

                    {props.item.activityType === ActivityType.TaskStateUpdated &&
                        <>
                            <Typography component='span' variant='body2'>{localization.activityTaskStateUpdated}: </Typography>
                            <Link component="a" variant="body2" href="#" onClick={(e) => { props.onTaskDetail(props.item.itemId ?? 0); e.preventDefault(); e.stopPropagation(); }}>{props.item.itemName}.</Link>
                            {createTaskStateChip(props.item.valueId ?? 0)}
                        </>}

                    {props.item.activityType === ActivityType.TaskNoteCreated &&
                        <>
                            <Typography component='span' variant='body2'>{localization.activityTaskNoteCreated}: </Typography>
                            <Link component="a" variant="body2" href="#" onClick={(e) => { props.onTaskDetail(props.item.itemId ?? 0); e.preventDefault(); e.stopPropagation(); }}>{props.item.itemName}.</Link>
                            <Alert severity='info' sx={{ mt: 1 }} icon={<SpeakerNotesIcon />}>{props.item.note}</Alert>
                        </>}

                    {props.item.activityType === ActivityType.TaskNoteUpdated &&
                        <>
                            <Typography component='span' variant='body2'>{localization.activityTaskNoteUpdated}: </Typography>
                            <Link component="a" variant="body2" href="#" onClick={(e) => { props.onTaskDetail(props.item.itemId ?? 0); e.preventDefault(); e.stopPropagation(); }}>{props.item.itemName}.</Link>
                            <Alert severity='info' sx={{ mt: 1 }} icon={<SpeakerNotesIcon />}>{props.item.note}</Alert>
                        </>}
                </Box>

            </TimelineContent>
        </TimelineItem>
    )
}

export default ActivitiesItem;