import { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import { Localize } from '../../AppLocalization';

// Modely
import { PriorityColors, TaskLite, TaskState } from '../../models/Models';

// Utility
import { Settings as DataGridSettings, LocalizeGrid } from '../../utility/DataGrid';
import { format as dateFormat } from 'date-fns';

// Komponenty
import { DataGrid, GridColDef, GridValueFormatterParams, GridRenderCellParams, GridValueGetterParams, GridFilterModel } from "@mui/x-data-grid";
import { Box, Chip, IconButton, SxProps, Theme } from '@mui/material';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface TasksSimpleProps {
    tasks: TaskLite[];
    taskStates: TaskState[];
    onCreate: (id: number, copy?: boolean) => void;
    onDelete: (id: number, name: string) => void;
}

const TasksSimple = (props: TasksSimpleProps) => {

    // Jazyková mutácia
    const localization = Localize();

    // Nastavenia stĺpcov
    const gridSettings = new DataGridSettings({ uid: 'tasks-simple' });
    const columnsDefault: GridColDef[] = [
        { field: 'id', headerName: 'Id', hide: true, minWidth: 20, width: 90, type: 'number', align: 'center', headerAlign: 'center' },
        {
            field: 'start', headerName: localization.start, hide: false, minWidth: 50, flex: 0.2, type: 'start',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['start']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? dateFormat((params?.value as Date), 'dd.MM.yyyy HH:mm') : '-')
        },
        {
            field: 'end', headerName: localization.end, hide: false, minWidth: 50, flex: 0.2, type: 'end',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['end']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? dateFormat((params?.value as Date), 'dd.MM.yyyy HH:mm') : '-')
        },
        {
            field: 'finish', headerName: localization.deadline, hide: false, minWidth: 50, flex: 0.2, type: 'finish',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['finish']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? dateFormat((params?.value as Date), 'dd.MM.yyyy HH:mm') : '-')
        },
        { field: 'name', headerName: localization.title, hide: false, minWidth: 50, flex: 0.3 },
        {
            field: 'priority', headerName: localization.priority, hide: true, minWidth: 50, flex: 0.1,
            renderCell: (params: GridRenderCellParams<number>) => {
                return <Chip label={(params.row.priority ?? 1)} size="small" variant="filled" sx={{ bgcolor: '#' + PriorityColors[(params.row.priority ?? 1) - 1], color: '#ffffff' }} />
            }
        },
        { field: 'projectName', headerName: localization.project, hide: true, minWidth: 50, flex: 0.3 },
        { field: 'taskName', headerName: localization.task, hide: true, minWidth: 50, flex: 0.3 },
        { field: 'taskTypeName', headerName: localization.taskType, hide: true, minWidth: 50, flex: 0.2 },
        {
            field: 'taskStateName', headerName: localization.taskState, hide: false, minWidth: 60, flex: 0.2, sortable: false,
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => {
                let label = (params.row.taskStateName ?? '');
                let color = (params.row.taskStateColor ?? '');
                return <Chip label={label.length > 0 ? label : '...'} size="small" variant="filled" sx={{ bgcolor: '#' + (color.length > 0 ? color : 'f0f0f0'), color: (color.length > 0 ? '#ffffff' : '#222222') }} />
            }
        },
        {
            field: 'createdDate', headerName: localization.created, hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['createdDate']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? dateFormat((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        {
            field: 'updatedDate', headerName: localization.updated, hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => (new Date(params.row['updatedDate'])),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? dateFormat((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        {
            field: 'options', headerName: localization.options, hide: false, width: 110, sortable: false, filterable: false, align: 'right', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => (
                <>
                    {(params.row.canWrite === true) && (
                        <>
                            <IconButton aria-label={localization.edit} title={localization.edit + ' (enter)'} size="small" onClick={() => props.onCreate(params.value ?? 0)}>
                                <EditIcon color="primary" fontSize="small" />
                            </IconButton>
                            <IconButton aria-label={localization.copy} title={localization.copy + ' (ctrl + enter)'} size="small" onClick={() => props.onCreate(params.value ?? 0, true)}>
                                <ContentCopyIcon fontSize="small" />
                            </IconButton>
                            <IconButton aria-label={localization.delete} title={localization.delete + ' (delete)'} size="small" onClick={() => props.onDelete(params.value ?? 0, params.row.name)}>
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </>
                    )}
                </>
            )
        }
    ];

    // Aplikujem uložené nastavenia
    useEffect(() => setColumns(gridSettings.columnApply(columns)), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Tabuľka
    const [pageSize, setPageSize] = useState<number>(gridSettings.pageSizeApply(25));
    const [columns, setColumns] = useState<GridColDef[]>(columnsDefault);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();

    // Generovanie štýlu pre tabuľku (iba pri zmene zoznamu stavov, ktoré obsahujú farbu)
    const [tasksGridStyles, setTasksGridStyles] = useState<SxProps<Theme>>({});
    useEffect(() => {
        let gridStyles: SxProps<Theme> = { '& .gridstyle-state-0': { borderLeft: '3px solid #f3f3f3' } };
        props.taskStates?.forEach(taskState => {
            let styleName = '& .gridstyle-state-' + (taskState.id ?? 0).toString();
            let styleColor = (taskState.color ?? '');
            if (styleColor.length > 0) {
                gridStyles = {
                    ...gridStyles, [styleName]: { color: '#' + styleColor, borderLeft: '3px solid #' + styleColor }
                }
            };
        });
        setTasksGridStyles(gridStyles);
    }, [props.taskStates]);

    return (
        <Box sx={{ height: '100%', width: '100%', ...tasksGridStyles }}>
            <DataGrid
                getRowClassName={(params) => 'gridstyle-state-' + (params.row.taskStateId ?? 0)}
                density='compact'
                autoHeight={true}
                disableSelectionOnClick
                columns={columns}
                rows={props.tasks ?? []}
                localeText={LocalizeGrid()}
                rowsPerPageOptions={[10, 25, 50]}
                pageSize={pageSize}
                onPageSizeChange={(pageSize) => {
                    setPageSize(pageSize);
                    gridSettings.pageSizeChanged(pageSize);
                }}
                onCellKeyDown={(e, c) => {

                    // Skontrolujem oprávnenia
                    if ((e.row.canWrite ?? false) === false) {
                        return;
                    }

                    if (c.code === 'Enter' && c.ctrlKey) {
                        c.preventDefault();
                        c.stopPropagation();
                        props.onCreate(e.row.id, true);
                        return;
                    }
                    if (c.code === 'Enter' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                        c.preventDefault();
                        c.stopPropagation();
                        props.onCreate(e.row.id);
                        return;
                    }
                    if (c.code === 'Delete' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                        c.preventDefault();
                        c.stopPropagation();
                        props.onDelete(e.row.id, e.row.name);
                        return;
                    }
                }}
                onCellDoubleClick={(e) => {
                    // Skontrolujem oprávnenia
                    if ((e.row.canWrite ?? false) === false) {
                        return;
                    }

                    props.onCreate(e.row.id);
                }}
                filterModel={filterModel}
                onFilterModelChange={e => setFilterModel(e)}
                onColumnVisibilityChange={e => gridSettings.columnVisibilityChanged(e, columnsDefault)}
            />
        </Box>
    )
}

export default TasksSimple;
