
// Priorita (od najvyššej)
export const PriorityColors: string[] = [
	'c0392b',
	'd35400',
	'f1c40f',
	'2980b9',
	'16a085'
];

export const BasicColors: string[] = [
	'121212',
	'2e7d32',
	'b71c1c',
	'1565c0',
	'e65100',
	'9500ae',
	'df487f',
	'827717',
	'e6d200'
];

export interface Authorization {
	email: string;
	password: string;
	remember: boolean;
}

// Možnosti pri uložení
export enum SaveAction {
	SaveAndContinue,
	SaveAndNew,
	SaveAndNewFromCopy,
	SaveAndPrint
}

export interface Activity {
	id?: number;
	createdDate?: Date;
	createdUser?: ActivityUserInfo;
	activityType?: ActivityType;
	itemId?: number;
	itemName?: string;
	valueId?: number;
	note?: string;
}

export interface ActivityList {
	days?: ActivityListDay[];
}

export interface ActivityListDay {
	date?: Date;
	isToday?: boolean;
	items?: Activity[];
}

export enum ActivityType {
	None = 0,
	TaskCreated = 1,
	TaskUpdated = 2,
	TaskStateUpdated = 3,
	TaskNoteCreated = 4,
	TaskNoteUpdated = 5,
}

export interface ActivityUserInfo extends FileBase {
	id?: number;
	name?: string;
}

export interface Breed {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	ordinalNumber?: number;
	name?: string;
	note?: string;
	numberFree?: boolean;
}

export interface BreedingStation {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	ordinalNumber?: number;
	name?: string;
	note?: string;
}

export interface Country {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	ordinalNumber?: number;
	name?: string;
	note?: string;
}

export interface CustomerFilter {
	page?: number;
	pageSize?: number;
	sort?: string;
	sortAsc?: boolean;
	search?: string;
	supplier?: number;
}

export interface CustomerLite {
	canWrite?: boolean;
	canRead?: boolean;

	id?: number;
	createdDate?: Date;
	updatedDate?: Date;
	supplier?: boolean;
	name?: string;
	city?: string;
	street?: string;
	houseNumber?: string;
	postcode?: string;
	country?: string;
	regId?: string;
	taxId?: string;
	vatId?: string;
	email?: string;
	phone?: string;
	note?: string;
}

export interface CustomerBase {
	id?: number;
	name?: string;
	city?: string;
	street?: string;
	houseNumber?: string;
	postcode?: string;
	countryId?: number;
	country?: string;
	regId?: string;
	taxId?: string;
	vatId?: string;
	email?: string;
	phone?: string;
}

export interface Customer extends CustomerBase {
	canWrite?: boolean;
	canRead?: boolean;

	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	supplier?: boolean;
	note?: string;
}

// Naplním triedu CustomerBase z triedy Customer
export const CustomerBaseFromCustomer = (customer: Customer): CustomerBase => {
	return {
		id: customer.id,
		name: customer.name,
		city: customer.city,
		street: customer.street,
		houseNumber: customer.houseNumber,
		postcode: customer.postcode,
		countryId: customer.countryId,
		country: customer.country,
		regId: customer.regId,
		taxId: customer.taxId,
		vatId: customer.vatId,
		email: customer.email,
		phone: customer.phone
	}
};

// Aby som nemusel meniť pri importe vyhradený názov "File", tak som použil "FileItem"
export interface FileItem extends FileBase {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	changed?: boolean;
	itemType?: number;
	itemId?: number;
	fileName?: string;
	fileExtension?: string;
	fileSize?: number;
	ordinalNumber?: number;
	name?: string;
}

export interface FileBase {
	fileExtension?: string;
	fileIsImage?: boolean;
	fileName?: string;
	fileNameSmall?: string;
	fileNameMedium?: string;
	fileSrc?: string;
	fileSrcSmall?: string;
	fileSrcMedium?: string;
}

export interface Invoice {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	customer?: CustomerBase;
	supplier?: CustomerBase;
	date?: any;
	number?: number;
	subject?: string;
	priceTotal?: number;
	priceTotalWithVat?: number;
	note?: string;
	items?: InvoiceItem[];
}

export interface InvoiceItem {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	invoiceId?: number;
	ordinalNumber?: number;
	name?: string;
	price?: number;
	priceVatRate?: number;
}

export interface InvoiceLite {
	id?: number;
	createdDate?: Date;
	updatedDate?: Date;
	supplierName?: string;
	supplierCity?: string;
	supplierStreet?: string;
	supplierHouseNumber?: string;
	supplierPostcode?: string;
	supplierCountryName?: string;
	supplierRegId?: string;
	supplierTaxId?: string;
	supplierVatId?: string;
	supplierEmail?: string;
	supplierPhone?: string;
	customerName?: string;
	customerCity?: string;
	customerStreet?: string;
	customerHouseNumber?: string;
	customerPostcode?: string;
	customerCountryName?: string;
	customerRegId?: string;
	customerTaxId?: string;
	customerVatId?: string;
	customerEmail?: string;
	customerPhone?: string;
	date?: any;
	number?: number;
	subject?: string;
	priceTotal?: number;
	priceTotalWithVat?: number;
	note?: string;
}

export interface InvoiceFilter {
	page?: number;
	pageSize?: number;
	sort?: string;
	sortAsc?: boolean;
	search?: string;
	number?: number;
	year?: number;
	dateFrom?: Date;
	dateTo?: Date;
	supplierId?: number;
	customerId?: number;
}

export interface ProductionFilter {
	page?: number;
	pageSize?: number;
	sort?: string;
	sortAsc?: boolean;
	search?: string;
	breedId?: number;
	breedingStationId?: number;
	genderMale?: number;
	color?: string;
	countsFrom?: Date;
	countsTo?: Date;
	dateOfBirthFrom?: Date;
	dateOfBirthTo?: Date;
	dateOfEntryFrom?: Date;
	dateOfEntryTo?: Date;
	dateOfExitFrom?: Date;
	dateOfExitTo?: Date;
}

export interface Production {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	number?: string;
	breedId?: number;
	breed?: string;
	breedingStationId?: number;
	breedingStation?: string;
	genderMale?: boolean;
	color?: string;
	dateOfBirth?: Date;
	dateOfEntry?: Date;
	dateOfExit?: Date;
	name?: string;
	note?: string;
	counts?: ProductionCount[];
}

export interface ProductionLite {
	id?: number;
	createdDate?: Date;
	updatedDate?: Date;
	number?: string;
	breed?: string;
	breedingStation?: string;
	genderMale?: boolean;
	color?: string;
	dateOfBirth?: Date;
	dateOfEntry?: Date;
	dateOfExit?: Date;
	name?: string;
	note?: string;
	counts?: ProductionCount[];
	countsRendered?: JSX.Element;
}

export interface ProductionCount {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	productionId?: number;
	date?: any;
	dateOfFinish?: any;
	countOfBirths?: number;
	countOfChipped?: number;
	note?: string;
	locked?: boolean; // pomocná premenná
}

export interface Project {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	projectTypeId?: number;
	projectStateId?: number;
	date?: any;
	name?: string;
	description?: string;
	templates?: ProjectTemplate[];
}

export interface ProjectTemplate {
	id?: number;
	users?: number[];
}

export interface ProjectLite {
	id?: number;
	createdDate?: Date;
	updatedDate?: Date;
	projectTypeName?: string;
	projectStateId?: number;
	projectStateName?: string;
	projectStateColor?: string;
	date?: any;
	name?: string;
}

export interface ProjectFilter {
	page?: number;
	pageSize?: number;
	sort?: string;
	sortAsc?: boolean;
	search?: string;
	dateFrom?: Date;
	dateTo?: Date;
	projectTypeIds?: number[];
	projectStateIds?: number[];
}

export interface ProjectState {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	ordinalNumber?: number;
	color?: string;
	name?: string;
	note?: string;
}

export interface ProjectType {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	ordinalNumber?: number;
	name?: string;
	note?: string;
}

export interface ScheduleOptions {
	startOfWeek?: Date;
	endOfWeek?: Date;
	mode?: number;
}

export interface Task {
	canWrite?: boolean;
	canWriteState?: boolean;
	canRead?: boolean;

	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	changeName?: string; // pomocná premenná pre čiastkové zmeny
	projectId?: number;
	projectName?: string;
	taskId?: number;
	taskName?: string;
	taskTypeId?: number;
	taskStateId?: number;
	start?: Date;
	end?: Date;
	finish?: Date;
	priority?: number;
	name?: string;
	description?: string;
	reminder?: boolean;
	reminderDate?: Date;
	reminderText?: string;
	users?: number[];
	notes?: TaskNote[];
	files?: FileItem[];
	stateHistory?: TaskStateHistory[];
}

export interface TaskLite {
	canWrite?: boolean;

	id?: number;
	createdDate?: Date;
	createdUser?: User;
	updatedDate?: Date;
	updatedUser?: User;
	projectName?: string;
	taskName?: string;
	taskTypeName?: string;
	taskStateId?: number;
	taskStateName?: string;
	taskStateColor?: string;
	start?: Date;
	end?: Date;
	finish?: Date;
	priority?: number;
	name?: string;
	users?: User[];
}

export interface TaskFilter {
	page?: number;
	pageSize?: number;
	sort?: string;
	sortAsc?: boolean;
	search?: string;
	calendarView?: boolean;
	startFrom?: Date;
	startTo?: Date;
	name?: string;
	projectId?: number;
	taskId?: number;
	taskTypeIds?: number[];
	taskStateIds?: number[];
	priorityIds?: number[];
	userIds?: number[];
}

export interface TaskNote {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	changed?: boolean;
	taskId?: number;
	note?: string;
}

export interface TaskState {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	ordinalNumber?: number;
	color?: string;
	name?: string;
	note?: string;
}

export interface TaskStateHistory {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	taskStateId?: number;
}

export interface TaskTemplate {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	projectTypeId?: number;
	projectType?: string;
	taskTypeId?: number;
	taskType?: string;
	name?: string;
	description?: string;
	startDays?: number;
	endHours?: number;
	finishDays?: number;
	priority?: number;
	reminder?: boolean;
	reminderHours?: number;
	reminderText?: string;
	note?: string;
}

export interface TaskType {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	ordinalNumber?: number;
	name?: string;
	note?: string;
}

export interface User extends FileBase {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	actived?: boolean;
	admin?: boolean;
	email?: string;
	password?: string;
	name?: string;
	nameShort?: string;
	note?: string;
	workingPeriods?: UserWorkingPeriod[];
	workingTimes?: UserWorkingTime[];
}

export interface UserWorkingPeriod {
	userId?: number;
	start?: Date;
	end?: Date;
}

export interface UserWorkingTime {
	userId?: number;
	day?: number;
	start?: any;
	startHour?: number;
	startMinute?: number;
	end?: any;
	endHour?: number;
	endMinute?: number;
	priority?: number;
}
