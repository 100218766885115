import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import isSameWeek from 'date-fns/isSameWeek';
import { GetDateLocale } from '../AppLocalization';

interface CustomPickerDayProps extends PickersDayProps<Date> {
    isSelected: boolean;
    isHovered: boolean;
}

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
})<CustomPickerDayProps>(({ theme, isSelected, isHovered, day }) => ({
    borderRadius: 0,
    ...(isSelected && {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.main,
        },
    }),
    ...(isHovered && {
        backgroundColor: theme.palette.primary[theme.palette.mode],
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary[theme.palette.mode],
        },
    }),
    ...(day.getDay() === 1 && { borderTopLeftRadius: '50%', borderBottomLeftRadius: '50%', }),
    ...(day.getDay() === 0 && { borderTopRightRadius: '50%', borderBottomRightRadius: '50%', }),
})) as React.ComponentType<CustomPickerDayProps>;


const isInSameWeek = (dayA: Date, dayB: Date | null | undefined) => {
    return (dayB == null ? false : isSameWeek(dayA, dayB, { locale: GetDateLocale() }))
};

function Day(
    props: PickersDayProps<Date> & {
        selectedDay?: Date | null;
        hoveredDay?: Date | null;
    },
) {
    const { day, selectedDay, hoveredDay, ...other } = props;

    return (
        <CustomPickersDay
            {...other}
            day={day}
            sx={{ px: 2.5 }}
            disableMargin
            selected={false}
            isSelected={isInSameWeek(day, selectedDay)}
            isHovered={isInSameWeek(day, hoveredDay)}
        />
    );
}

function getWeekStartAndEnd(date: Date): { startOfWeek: Date, endOfWeek: Date } {
    const dayOfWeek = date.getDay(); // 0 (Sunday) to 6 (Saturday)
    const startOfWeek = new Date(date);
    const endOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1));
    endOfWeek.setDate(date.getDate() + (dayOfWeek === 0 ? 0 : 7 - dayOfWeek));
    startOfWeek.setHours(0, 0, 0, 0);
    endOfWeek.setHours(23, 59, 59, 999);
    return { startOfWeek, endOfWeek };
}

interface WeekPickerProps {
    value?: Date;
    onChange: (startOfWeek: Date, endOfWeek: Date) => void;
}

export default function WeekPicker(props: WeekPickerProps) {
    const [hoveredDay, setHoveredDay] = useState<Date | null>(null);
    const [value, setValue] = useState<Date | null>(props.value ?? new Date());

    useEffect(() => {
        const { startOfWeek, endOfWeek } = getWeekStartAndEnd(value ?? new Date());
        props.onChange(startOfWeek, endOfWeek);
    }, [value]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={GetDateLocale()}>
            <DateCalendar
                value={value}
                onChange={(newValue) => setValue(newValue)}
                showDaysOutsideCurrentMonth
                displayWeekNumber
                slots={{ day: Day }}
                slotProps={{
                    day: (ownerState) =>
                        ({
                            selectedDay: value,
                            hoveredDay,
                            onPointerEnter: () => setHoveredDay(ownerState.day),
                            onPointerLeave: () => setHoveredDay(null),
                        }) as any,
                }}
            />
        </LocalizationProvider>
    );
}
