import { FormEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { GetDateLocale, Localize } from '../../AppLocalization';
import { AppContext } from '../../AppContext';

// Utility
import { NullMinDate } from '../../utility/Date';
import { addHours } from 'date-fns';

// Modely
import { Project, ProjectState, ProjectType, SaveAction, TaskFilter, TaskLite, TaskState, TaskTemplate, TaskType, User } from '../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, FormControl, Chip, InputLabel, MenuItem, Select, Tab, Tabs, Avatar, Divider, ListItemAvatar, ListItemIcon, ListItemText } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import TinyMCE from '../../components/TinyMCE';
import { TabContext, TabPanel } from '@mui/lab';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import TaskCreate, { TaskCreateProps } from '../task/TaskCreate';
import TasksSimple from '../task/TasksSimple';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): Project => ({
    id: 0,
    date: addHours(new Date(new Date().toDateString()), 8),
    name: '',
    description: ''
});

// Vstupné parametre
export interface ProjectCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    projectStates: ProjectState[];
    projectTypes: ProjectType[];
    taskStates: TaskState[];
    taskTypes: TaskType[];
    taskTemplates: TaskTemplate[];
    users?: User[];
    onSave?: (id?: number, action?: SaveAction) => void;
    onClose: () => void;
}

const ProjectCreate = (props: ProjectCreateProps) => {

    // Všeobecne
    const localization = Localize();
    const formRef = useRef<HTMLFormElement>(null);
    const context = useContext(AppContext);

    // Stav
    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<Project>(EmptySource());
    const [tabValue, setTabValue] = useState<string>('description');

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            setTabValue('description');
            return;
        }

        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        handleReset();
        if ((props.id ?? 0) === 0) {
            return;
        }

        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'project/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    let entity = response.data as Project;
                    if (props.copy) {
                        entity.id = 0;
                    }
                    setSource(entity);
                }
            })
            .finally(() => setLoading(false));
    }, [props.id, props.copy, props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        
        // Nemením hodnotu pri čiastočne zadanom dátume "Invalid Date" (kontrolovaný vstup by sa nedal prepísať)
        if (property === 'date') {
            if (value !== null && isNaN(value)) {
                return;
            }
        }

        setSource(prev => {
            const changed = { ...prev, [property]: value };
            if (property === 'projectTypeId') {
                changed.templates = [];
            }
            return changed;
        });
    }
    const handleChangeTemplates = (templateId: number, users: number[]) => {
        setSource(prev => {
            const templates = (prev.templates ?? []).filter(t => t.id !== templateId);
            templates.push({
                id: templateId,
                users: users
            });
            return { ...prev, templates: templates };
        });
    }


    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event?: FormEvent<HTMLFormElement>, action?: SaveAction) => {
        if (event !== undefined) {
            event.preventDefault();
            event.stopPropagation();
        }

        // Manuálna kontrola
        if (event === undefined && formRef) {
            if (formRef.current?.checkValidity() === false) {
                setWarning({ ...EmptyWarning, text: localization.mustFileRequiredFields });
                return;
            }
        }

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'project', source)
            .then(response => {
                if (response.data > 0) {
                    props.onClose();
                    if (props.onSave) {
                        props.onSave();
                        props.onSave((response.data ?? 0) as number, action);
                    }
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: localization.saveFailed });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Reset formuláru
    const handleReset = (keepId?: boolean) => {
        setSource(prev => ({
            ...EmptySource(),
            id: (keepId ?? false) === true ? prev.id : 0,
            projectStateId: props.projectStates[0]?.id ?? 0
        }));
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => input?.focus(), 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    // ---------------------------------------------------------------------------------
    // Vnorené úlohy
    // ---------------------------------------------------------------------------------

    const [tasks, setTasks] = useState<TaskLite[]>([]);
    const [tasksFilter, setTasksFilter] = useState<TaskFilter>({ page: 0, pageSize: 999 });

    // Načítanie úloh po zmene id nadradenej úlohy
    const loadTasksSafe = () => setTasksFilter(prev => ({ ...prev }));
    const loadTasks = () => {
        if ((source.id ?? 0) === 0) {
            setTasks([]);
            return;
        }
        axios
            .get(AppConfig.ApiUri + 'task', {
                params: { ...tasksFilter, projectId: props.id } as TaskFilter
            })
            .then(response => setTasks((response.data?.list ?? []) as TaskLite[]));

    };
    useEffect(() => loadTasksSafe(), [source.id]);
    useEffect(() => loadTasks(), [tasksFilter]);

    const [taskCreate, setTaskCreate] = useState<TaskCreateProps>({
        open: false,
        keepMounted: false,
        taskStates: [],
        taskTypes: [],
        taskTemplates: [],
        users: [],
        onSave: (id?: number, action?: SaveAction) => {
            loadTasksSafe(); // Obnovím zoznam

            // Rozšírené funkcie po uložení
            if (id !== undefined && action !== undefined) {
                if (action === SaveAction.SaveAndNew) {
                    handleTaskCreate(0);
                    return;
                }
                if (action === SaveAction.SaveAndContinue) {
                    handleTaskCreate(id ?? 0);
                    return;
                }
                if (action === SaveAction.SaveAndNewFromCopy) {
                    handleTaskCreate(id ?? 0, true);
                    return;
                }
            }
        },
        onClose: () => setTaskCreate(prev => ({ ...prev, id: 0, copy: false, task: undefined, open: false }))
    });

    // Pridať upraviť záznam
    const handleTaskCreate = (id: number, copy?: boolean) => setTaskCreate(prev => ({
        ...prev,
        id: id,
        copy: copy ?? false,
        open: true,
        task: {
            projectId: props.id,
            projectName: source.name
        }
    }));

    // Vymazať záznam
    const handleTaskDelete = (id: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: localization.deleteConfirmation, onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'task/' + id)
                    .then(response => {
                        if (response.data === true) {
                            loadTasks();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    // ---------------------------------------------------------------------------------
    // Šablóny
    // ---------------------------------------------------------------------------------

    // Zoznam šablón podľa vybraného typu (obnovím automaticky)
    const [taskTemplatesByType, setTaskTemplatesByType] = useState<TaskTemplate[]>([]);

    useEffect(() => {
        setTaskTemplatesByType(props.taskTemplates?.filter(t => t.projectTypeId === (source.projectTypeId ?? 0)) ?? []);
    }, [source.id, source.projectTypeId, props.taskTemplates]);

    return (
        <>
            {/* Formulár pre nový záznam (vnorený) */}
            {(props.open && taskCreate.open && (props.id ?? 0) > 0 && <TaskCreate {...taskCreate} taskStates={props.taskStates} taskTypes={props.taskTypes} taskTemplates={props.taskTemplates} users={props.users ?? []} />)}

            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="md" fullWidth open={props.open} scroll="body" disableEnforceFocus onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>

                {/* Preloader  */}
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                {/* Potvrdzovacie okno */}
                <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

                <Box ref={formRef} component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((source?.id ?? 0) === 0 && localization.new + ': ')} {localization.project}
                        {(props.copy && ' (' + localization.copied + ')')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} sm={8}>
                                <TextField required margin="dense" name="name" label={localization.title} fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={GetDateLocale()}>
                                    <FormControl margin="dense" fullWidth required>
                                        <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label={localization.date} value={NullMinDate(source.date)} onChange={(d) => { handleChange('date', d) }} slotProps={{ textField: { required: true } }} inputRef={input => { props.autoFocus === 'date' && setFocus(input) }} />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="projectcreate-project-type">{localization.projectType}</InputLabel>
                                    <Select labelId="projectcreate-project-type" label={localization.projectType} value={source.projectTypeId ?? 0} name="projectTypeId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => { (props.autoFocus === 'projectTypeName' || props.autoFocus === 'projectTypeId') && setFocus(input) }}>
                                        <MenuItem key={0} value={0}>-</MenuItem>
                                        {props.projectTypes?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="projectcreate-project-state">{localization.projectState}</InputLabel>
                                    <Select labelId="projectcreate-project-state" label={localization.projectState} value={source.projectStateId ?? 0} name="projectStateId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => { (props.autoFocus === 'projectStateName' || props.autoFocus === 'projectStateId') && setFocus(input) }}
                                        renderValue={(value) => {
                                            if (value === 0) {
                                                return <>-</>
                                            }
                                            var state = props.projectStates.find(item => item.id === value) ?? { name: '', color: '333333' };
                                            return (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: .3, height: 22 }}>
                                                    <Chip size="small" key={value} label={state.name} sx={{ m: 0, bgcolor: '#' + ((state.color ?? '').length > 0 ? state.color : 'f0f0f0'), color: ((state.color ?? '').length > 0 ? '#ffffff' : '#222222') }} />
                                                </Box>
                                            )
                                        }}>
                                        <MenuItem key={0} value={0}>-</MenuItem>
                                        {props.projectStates.map(item => (<MenuItem key={item.id} value={item.id} sx={{ color: '#' + ((item.color ?? '').length > 0 ? item.color : 'f0f0f0') }}>{item.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>


                            <Grid item xs={12} mt={2} mb={0}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={tabValue} onChange={(e, v) => setTabValue(v)} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                                        <Tab value="description" label={localization.description + ((source.description ?? '').length > 0 ? ' (' + localization.yes.toLowerCase() + ')' : '')} />
                                        <Tab value="tasks" label={localization.tasks + ' (' + (tasks.length ?? 0) + ')'} />
                                        {(context.user?.admin ?? false) === true && (
                                            <Tab value="templates" label={localization.taskTemplates} />
                                        )}
                                    </Tabs>
                                </Box>
                                <TabContext value={tabValue}>
                                    <TabPanel value="description" sx={{ px: 0 }}>
                                        <TinyMCE onChange={(s) => handleChange('description', s)} content={source.description ?? ''} />
                                    </TabPanel>
                                    <TabPanel value="tasks" sx={{ px: 0 }}>
                                        <Grid container columnSpacing={1}>
                                            {(source.id ?? 0) === 0 && (
                                                <Grid item xs={12} mb={0}>
                                                    <Alert severity='info' action={<Button variant='contained' color="info" size="small" onClick={(e) => handleSave(undefined, SaveAction.SaveAndContinue)}>{localization.saveAndContinue}</Button>}>
                                                        {localization.onlyForSavedRecords}
                                                    </Alert>
                                                </Grid>
                                            )}
                                            {(source.id ?? 0) > 0 && (
                                                <Grid item xs={12} mb={0}>
                                                    <Button variant="contained" size="small" startIcon={<AddIcon />} color="secondary" sx={{ mr: 1 }} onClick={() => handleTaskCreate(0)}>{localization.newRecord}</Button>
                                                </Grid>
                                            )}
                                            {(tasks.length ?? 0) > 0 && (
                                                <Grid item xs={12} mt={2}>
                                                    <TasksSimple tasks={tasks} taskStates={props.taskStates} onCreate={(id: number, copy?: boolean) => handleTaskCreate(id, copy)} onDelete={(id: number, name: string) => handleTaskDelete(id, name)} />
                                                </Grid>
                                            )}

                                        </Grid>
                                    </TabPanel>
                                    {(context.user?.admin ?? false) === true && (
                                        <TabPanel value="templates" sx={{ px: 0 }}>
                                            <Grid container columnSpacing={1}>
                                                {taskTemplatesByType.length > 0 && (
                                                    <Grid item xs={12} mb={1}>
                                                        <Alert severity='info'>{localization.projectTemplatesInfoSave}</Alert>
                                                    </Grid>
                                                )}
                                                {taskTemplatesByType.length === 0 && (
                                                    <Grid item xs={12} mb={1}>
                                                        <Alert severity='warning'>{localization.projectTemplatesInfoNotFound}</Alert>
                                                    </Grid>
                                                )}
                                                {taskTemplatesByType.map(template => {
                                                    return (
                                                        <Grid item xs={12} key={template.id}>
                                                            <FormControl margin="dense" fullWidth>
                                                                <InputLabel>{template.name}</InputLabel>
                                                                <Select multiple label={template.name} value={source.templates?.find(t => t.id === template.id)?.users ?? []}
                                                                    onChange={(e) => {
                                                                        let values = e.target.value as number[];
                                                                        if (values.includes(-1)) {
                                                                            values = Array.from(props.users?.map(user => user.id ?? 0) ?? []);
                                                                        }
                                                                        if (values.includes(-2)) {
                                                                            values = [];
                                                                        }
                                                                        handleChangeTemplates(template.id ?? 0, values);
                                                                    }}
                                                                    renderValue={(selected) => (
                                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, py: 0, pl: 1, /*height: 22*/ }}>
                                                                            {selected.map((value) => {
                                                                                var user = props.users?.find(item => item.id === value) ?? { name: '' };
                                                                                return (
                                                                                    <Box key={value} sx={{ display: 'inline-block', position: 'relative', paddingLeft: '35px' }}>
                                                                                        <Box sx={{ position: 'absolute', top: '-5px', left: '-5px' }}>
                                                                                            {(
                                                                                                user.fileIsImage ?
                                                                                                    <Avatar sx={{ width: 32, height: 32 }} alt={user.name} src={user.fileSrcSmall} /> :
                                                                                                    <Avatar sx={{ width: 32, height: 32 }}><PersonIcon /></Avatar>
                                                                                            )}
                                                                                        </Box>
                                                                                        {user.name}
                                                                                    </Box>
                                                                                )
                                                                            })}
                                                                        </Box>
                                                                    )}>
                                                                    {props.users?.map(user => (
                                                                        <MenuItem key={user.id} value={user.id}>
                                                                            <ListItemAvatar>
                                                                                {user.fileIsImage ?
                                                                                    <Avatar alt={user.name} src={user.fileSrcSmall} /> :
                                                                                    <Avatar><PersonIcon /></Avatar>}
                                                                            </ListItemAvatar>
                                                                            <ListItemText>
                                                                                {user.name}
                                                                            </ListItemText>
                                                                        </MenuItem>
                                                                    ))}
                                                                    <Divider />
                                                                    <MenuItem key={-1} value={-1}>
                                                                        <ListItemIcon>
                                                                            <CheckBoxIcon />
                                                                        </ListItemIcon>
                                                                        <ListItemText primary={localization.selectAll} />
                                                                    </MenuItem>
                                                                    <MenuItem key={-2} value={-2}>
                                                                        <ListItemIcon>
                                                                            <CheckBoxOutlineBlankIcon />
                                                                        </ListItemIcon>
                                                                        <ListItemText primary={localization.cancelSelection} />
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                        </TabPanel>
                                    )}
                                </TabContext>
                            </Grid>
                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => handleReset(true)} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>{localization.reset}</Button>
                        <Button disabled={loading} onClick={props.onClose}>{localization.back}</Button>
                        <Button disabled={loading} type="submit" variant="contained">{localization.save}</Button>
                    </DialogActions>
                </Box>

            </Dialog >
        </>
    )
}

export default ProjectCreate;