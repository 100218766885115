// Poznámky
// ---------------------
// Bežné problémy s časovými pásmami:
// - https://github.com/mui/mui-x/issues/8620
// - https://github.com/sequelize/sequelize/issues/11597

// Vráti hodnotu null ak je dátum menší alebo rovný ako 01.01.1900
export const NullMinDate = (date?: Date, minYear?: number): Date | null => {
    if (date === undefined || date === null || isNaN(Date.parse(date?.toString() ?? ''))) {
        return null;
    }
    try {
        if (new Date(date).getFullYear() <= (minYear ?? 1900)) {
            return null;
        }
    } catch { return null; }
    return new Date(date?.toString());
};
