import { useState } from 'react';

// Modely
import { ProductionCount } from '../../models/Models';

// Utility
import { NullMinDate } from '../../utility/Date';
import { Localize } from '../../AppLocalization';
import { format as dateFormat } from 'date-fns';

// Komponenty
import { Chip, Menu, Typography } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import { TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent, TimelineOppositeContent } from '@mui/lab';


export interface ProductionsCountsProps {
    number?: string;
    counts?: ProductionCount[];
}

const ProductionsCounts = (props: ProductionsCountsProps) => {

    // Všeobecne
    const localization = Localize();
    const [anchorCountsEl, setAnchorCountsEl] = useState<null | HTMLElement>(null);

    // Formátovanie dátumu
    const formatDate = (date: any): string => {
        if (NullMinDate(date) === null) {
            return '-';
        }
        return dateFormat(new Date(date), 'dd.MM.yyyy');
    }

    return (
        <>
            {(props.counts?.length ?? 0) > 0 &&
                <>
                    {
                        props.counts?.map((item, index) => (
                            <Chip key={index} size="small" sx={{ mr: .3 }} variant='outlined' onClick={(e) => setAnchorCountsEl(e.currentTarget.parentElement ?? e.currentTarget)}
                                label={
                                    <>
                                        <Typography variant='caption' mr={.5}>
                                            {formatDate(item.date)}
                                        </Typography>
                                        <Typography variant='caption' fontWeight='bold' color='white' sx={{ backgroundColor: 'black', borderRadius: 1, m: 0, py: .2, px: 0.5 }}>
                                            {item.countOfBirths} / {item.countOfChipped}
                                        </Typography>
                                    </>
                                } />
                        ))
                    }
                    <Menu id="menu-language" PaperProps={{ style: { minWidth: 350 } }} anchorEl={anchorCountsEl} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }} open={Boolean(anchorCountsEl)} onClose={() => setAnchorCountsEl(null)} >
                        {props.number && <Typography display='block' fontWeight='bold' textAlign='center' mt={3} variant='h5' component='span'>{props.number}</Typography>}
                        <Timeline sx={{ mt: 3 }}>
                            {
                                props.counts?.map((item, index) => (
                                    <TimelineItem key={index}>
                                        <TimelineOppositeContent>
                                            <Chip label={formatDate(item.date)} />
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineDot variant='outlined' />
                                            {(index + 1) < (props.counts?.length ?? 0) && <TimelineConnector />}
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Typography variant="body2" color="primary">{item.countOfBirths} - {localization.born}</Typography>
                                            <Typography variant="body2">{item.countOfChipped} - {localization.chipped}</Typography>
                                        </TimelineContent>
                                    </TimelineItem>
                                ))
                            }
                        </Timeline>
                    </Menu>
                </>}
        </>
    )
}

export default ProductionsCounts