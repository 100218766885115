import { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';

// Utility
import { FileOpen } from '../../utility/File';
import { CookieRead } from '../../utility/Cookies';

// Komponenty
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import Iframe from '../../components/Iframe';
import { GetLanguage, Localize } from '../../AppLocalization';

export interface ReportingProps {
    id?: number;                // id záznamu, napr. invoice id
    type: string;               // typ reportu, napr. 'productions'
    settings: string;           // nastavenia reportu, napr. ako serializovaný json
    open: boolean;
    preview?: boolean;          // true = doklad sa zobrazí cez iframe (s automatickou tlačou podľa: print), false = doklad sa otvori v novom okne
    format?: string;            // html, pdf
    print?: boolean;            // automatická tlač po zobrazení
    onClose: () => void;
}

const Reporting = (props: ReportingProps) => {
    const localization = Localize();

    // Náhľad dokladu
    const [previewSrc, setPreviewSrc] = useState<string>('');

    // Automaticky zbalím tlačidlá po otvorení okna
    useEffect(() => {
        if (!props.open) {
            return;
        }

        // Zložím URL pre tlač
        const printUrl = AppConfig.ApiUri + 'reporting'
            + '?language=' + GetLanguage()
            + '&format=' + (props.format ?? 'html')
            + '&session=' + CookieRead(AppConfig.CookieName.LoggedSession)
            + '&id=' + (props.id ?? 0)
            + '&type=' + props.type
            + '&settings=' + props.settings;

        // Zobrazím cez preview dialog
        if (props.preview === true) {
            setPreviewSrc(printUrl);
        } else {
            FileOpen(printUrl, '_blank');
        }
    }, [props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Manuálna tlač
    const handlePrint = () => {
        var frame = document.getElementById('ReportingIframe') as HTMLIFrameElement;
        if (frame) {
            frame.contentWindow?.print();
        }
    };

    return (
        <>
            <Dialog keepMounted={false} open={props.open} maxWidth="xl" fullWidth scroll="paper" onClose={props.onClose}>
                <DialogContent dividers={true} sx={{ p: 1, height: '80vh' }}>
                    <Iframe id='ReportingIframe' title={'Preview'} src={previewSrc} print={props.print} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{localization.back}</Button>
                    <Button onClick={handlePrint} variant="contained">{localization.print}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Reporting;