import { Localize } from './AppLocalization';

// Podstránky
import Activities from './views/activity/Activities';
import Tasks from './views/task/Tasks';
import Projects from './views/project/Projects';
import Productions from './views/production/Productions';
import Invoices from './views/invoice/Invoices';
import Customers from './views/customer/Customers';
import Users from './views/user/Users';
import Breeds from './views/breed/Breeds';
import ProjectTypes from './views/project_type/ProjectTypes';
import ProjectStates from './views/project_state/ProjectStates';
import TaskTemplates from './views/task_template/TaskTemplates';
import TaskTypes from './views/task_type/TaskTypes';
import TaskStates from './views/task_state/TaskStates';
// import Blank from './views/Blank';
import Countries from './views/country/Countries';
import BreedingStations from './views/breeding_station/BreedingStations';

// Pomôcky: 
// https://betterprogramming.pub/the-best-way-to-manage-routes-in-a-react-project-with-typescript-c4e8d4422d64
// https://www.youtube.com/watch?v=J6jzDfHoj-Y&ab_channel=TheNerdyCanuck

// Zoznam všetkých URL adries
export enum AppRouteUrl {

    // Agendy
    ACTIVITIES = '/',
    TASKS = '/tasks/',
    PROJECTS = '/projects/',
    PRODUCTIONS = '/productions/',
    INVOICES = '/invoices/',
    CUSTOMERS = '/customers/',

    // Nastavenia
    USERS = '/users/',
    PROJECT_STATES = '/project-states/',
    PROJECT_TYPES = '/project-types/',
    TASK_TEMPLATES = '/task-templates/',
    TASK_STATES = '/task-states/',
    TASK_TYPES = '/task-types/',
    BREEDS = '/breeds/',
    BREEDING_STATIONS = '/breeding-stations/',
    COUNTRIES = '/countries/'

};

// Zoznam všetkých podstránok
export function GetRoutes(isAdmin: boolean): AppRouteType[] {

    // Jazyková mutácia
    const localization = Localize();

    return [

        // Agendy
        { page: AppRouteUrl.ACTIVITIES, title: localization.activities, component: Activities, exact: true },
        { page: AppRouteUrl.TASKS, title: localization.tasks, component: Tasks, exact: true },
        { page: AppRouteUrl.PROJECTS, title: localization.projects, component: Projects, exact: true },
        { page: AppRouteUrl.PRODUCTIONS, title: localization.productions, component: Productions, exact: true },
        { page: AppRouteUrl.INVOICES, title: localization.invoices, component: Invoices, exact: true },
        { page: AppRouteUrl.CUSTOMERS, title: localization.addressBook, component: Customers, exact: true },

        // Nastavenia (len admin)
        ...(isAdmin === true ? [
            { page: AppRouteUrl.USERS, title: localization.users, component: Users, exact: true },
            { page: AppRouteUrl.TASK_TEMPLATES, title: localization.taskTemplates, component: TaskTemplates, exact: true },
            { page: AppRouteUrl.TASK_STATES, title: localization.taskStates, component: TaskStates, exact: true },
            { page: AppRouteUrl.TASK_TYPES, title: localization.taskTypes, component: TaskTypes, exact: true },
            { page: AppRouteUrl.PROJECT_STATES, title: localization.projectStates, component: ProjectStates, exact: true },
            { page: AppRouteUrl.PROJECT_TYPES, title: localization.projectTypes, component: ProjectTypes, exact: true },
            { page: AppRouteUrl.BREEDS, title: localization.breeds, component: Breeds, exact: true },
            { page: AppRouteUrl.BREEDING_STATIONS, title: localization.breedingStations, component: BreedingStations, exact: true },
            { page: AppRouteUrl.COUNTRIES, title: localization.countries, component: Countries, exact: true },
        ] : [])
    ];
}

// Predpis pre podstránku 
interface AppRouteType {
    page: AppRouteUrl;
    title: string;
    exact: boolean;
    component: any;
    props?: any;
};