import { FormEvent, useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { Localize } from '../../AppLocalization';

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown } from '../../utility/Convert';

// Modely
import { PriorityColors, ProjectType, TaskTemplate, TaskType } from '../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, FormControl, InputLabel, MenuItem, Select, Typography, Accordion, AccordionDetails, AccordionSummary, Switch, FormControlLabel, Chip } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TinyMCE from '../../components/TinyMCE';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import EventIcon from '@mui/icons-material/Event';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): TaskTemplate => ({
    id: 0,
    projectTypeId: 0,
    taskTypeId: 0,
    name: '',
    note: '',
    priority: 5
});

// Vstupné parametre
export interface TaskTemplateCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const TaskTemplateCreate = (props: TaskTemplateCreateProps) => {

    // Jazyková mutácia
    const localization = Localize();

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<TaskTemplate>(EmptySource());
    const [projectTypes, setProjectTypes] = useState<ProjectType[]>([]);
    const [taskTypes, setTaskTypes] = useState<TaskType[]>([]);

    // Harmonika
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);

    // Funkcie pre získanie informácie o tom, či je harmonika otvorená a zmenu stavu
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    // Načítam číselníky
    const loadProjectTypes = () => {
        axios
            .get(AppConfig.ApiUri + 'projecttype')
            .then(response => setProjectTypes(response.data));
    };
    useEffect(() => loadProjectTypes(), []);

    const loadTaskTypes = () => {
        axios
            .get(AppConfig.ApiUri + 'tasktype')
            .then(response => setTaskTypes(response.data));
    };
    useEffect(() => loadTaskTypes(), []);

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            setAccordionExpanded([]);
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'tasktemplate/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as TaskTemplate);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (source.name === '') {
            setWarning({ ...EmptyWarning, name: 'name', text: localization.mustFill + ': ' + localization.title + '!' });
            return;
        }

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'tasktemplate', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: localization.saveFailed });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => input?.focus(), 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="md" fullWidth open={props.open} scroll="body" disableEnforceFocus onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((source?.id ?? 0) === 0 && localization.new + ': ')} {localization.taskTemplate}
                        {(props.copy && ' (' + localization.copied + ')')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} sm={6}>
                                <TextField required margin="dense" name="name" label={localization.title} fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="tasktemplate-project-type">{localization.projectType}</InputLabel>
                                    <Select labelId="tasktemplate-project-type" label={localization.projectType} value={source.projectTypeId ?? 0} name="projectTypeId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => { props.autoFocus === 'projectTypeId' && setFocus(input) }}>
                                        <MenuItem key={0} value={0}>-</MenuItem>
                                        {projectTypes?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="tasktemplate-task-type">{localization.taskType}</InputLabel>
                                    <Select labelId="tasktemplate-task-type" label={localization.taskType} value={source.taskTypeId ?? 0} name="taskTypeId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => { props.autoFocus === 'taskTypeId' && setFocus(input) }}>
                                        <MenuItem key={0} value={0}>-</MenuItem>
                                        {taskTypes?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <TextField fullWidth margin="dense" name="startDays" type="text" label={localization.start + ' (' + localization.datePlusDays.toLowerCase() + ')'} variant="outlined" value={source.startDays ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'startDays' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EventIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <TextField fullWidth margin="dense" name="endHours" type="text" label={localization.end + ' (' + localization.startPlusHours.toLowerCase() + ')'} variant="outlined" value={source.endHours ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'endHours' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <QueryBuilderIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <TextField fullWidth margin="dense" name="finishDays" type="text" label={localization.deadline + ' (' + localization.datePlusDays.toLowerCase() + ')'} variant="outlined" value={source.finishDays ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'finishDays' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SportsScoreIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="user-create-role">{localization.priority}</InputLabel>
                                    <Select labelId="user-create-role" label={localization.priority} value={source.priority ?? 0} name="priority" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => { props.autoFocus === 'priority' && setFocus(input) }}
                                        renderValue={(value) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: .3, height: 22 }}>
                                                <Chip size="small" key={value} label={value} sx={{ m: 0, bgcolor: '#' + PriorityColors[value - 1], color: '#ffffff' }} />
                                            </Box>)}>
                                        <MenuItem value={1} sx={{ color: '#' + PriorityColors[0] }}>1 ({localization.highest.toLowerCase()})</MenuItem>
                                        <MenuItem value={2} sx={{ color: '#' + PriorityColors[1] }}>2</MenuItem>
                                        <MenuItem value={3} sx={{ color: '#' + PriorityColors[2] }}>3</MenuItem>
                                        <MenuItem value={4} sx={{ color: '#' + PriorityColors[3] }}>4</MenuItem>
                                        <MenuItem value={5} sx={{ color: '#' + PriorityColors[4] }}>5 ({localization.lowest.toLowerCase()})</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} mt={2}>
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('reminder')} onChange={() => handleToggleAccordion('reminder')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>{localization.reminder} {source.reminder === true ? ' (' + localization.yes.toLowerCase() + ')' : ''}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>

                                            <Grid item xs={12} md mb={1}>
                                                <FormControlLabel sx={{ mt: 2 }} control={<Switch checked={source.reminder} name="reminder" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label={localization.active} />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <TextField fullWidth margin="dense" name="reminderHours" type="text" label={localization.reminderTime + ' (' + localization.startPlusHours.toLowerCase() + ')'} variant="outlined" value={source.reminderHours ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                                    inputRef={input => props.autoFocus === 'reminderHours' && setFocus(input)}
                                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EventIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextField fullWidth multiline rows={5} margin="dense" name="reminderText" label={localization.reminderText} variant="outlined" value={source.reminderText ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputProps={{ maxLength: 1000 }}
                                                    inputRef={input => { props.autoFocus === 'reminderText' && setFocus(input) }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EditIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('note')} onChange={() => handleToggleAccordion('note')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>{localization.note} {(source.note?.length ?? 0) > 0 ? ' (' + localization.yes.toLowerCase() + ')' : ''}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12}>
                                                <TextField fullWidth multiline rows={5} margin="dense" name="note" label={localization.note} variant="outlined" value={source.note ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputProps={{ maxLength: 1000 }}
                                                    inputRef={input => { props.autoFocus === 'note' && setFocus(input) }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EditIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid item xs={12} mt={3}>
                                <TinyMCE onChange={(s) => handleChange('description', s)} content={source.description ?? ''} />
                            </Grid>

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>{localization.reset}</Button>
                        <Button disabled={loading} onClick={props.onClose}>{localization.back}</Button>
                        <Button disabled={loading} type="submit" variant="contained">{localization.save}</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default TaskTemplateCreate;