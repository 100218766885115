import { createTheme } from '@mui/material';
import ThemeComponents from './ThemeComponents'
import { skSK, enUS, frFR } from '@mui/material/locale';
import { GetLanguage } from '../AppLocalization';

/*  Ako to funguje 
    -------------------------------
    Hlavný App element aplikuje tému "Theme" (predvolená) alebo "ThemeDark" (dedí z Theme a nahrádza vlastnosti).
    Všeobecné nastavenia pre komponenty sú oddelené v súbore "ThemeComponents" a vkladajú sa to "Theme".
    Súbor "StylesGlobal" obsahuje len CSS, ktoré nie je cielené na farebnú schému.
    Súbor "Styles" obsahuje CSS pre predvolenú farebnú schému.
    Súbor "StylesDark" obsahuje CSS pre tmavú farebnú schému.
    Hlavný App vloží do obsahu "StylesGlobal" a popri tom strieda farebné css "Styles" a "StylesDark" podľa vybranej témy
    Komponent použije háčik: const theme = useTheme(); získanie témy.
    
    POZOR!
    -------------------------------
    Pri zmene témy sa GlobalStyle obnovia iba 1x kvôli <StrictMode> v roote aplikácie: https://github.com/mui-org/material-ui/issues/23334
    Strict mód je aktívny iba pri debug v produkcii to problém robiť nebude.
*/

// Rozšírenia témy pre typescript
declare module '@mui/material/styles' {
    interface Theme {
        layout: {
            sidebar: {
                backgroundColor: string;
                borderColor: string;
                /** Podradený element v sidebare */
                collapse: {
                    backgroundColor: string;
                    borderColor: string;
                }
            },
            content: {
                backgroundColor: string;
            }
        };
    }
    interface ThemeOptions {
        layout?: {
            body?: {
                backgroundColor?: string;
            },
            sidebar?: {
                backgroundColor?: string;
                borderColor?: string;
                collapse?: {
                    backgroundColor?: string;
                    borderColor?: string;
                }
            },
            content?: {
                backgroundColor?: string;
            }
        };
    }
}

// Predvolené nastavenie témy
const ThemeDefault = createTheme({
    typography: {
        fontFamily: "'Roboto Condensed', Helvetica, Arial, sans-serif"
    },
    palette: {
        mode: 'light',
        primary: {
            light: '#d19963',
            main: '#744838',
            dark: '#ad9551',
        },
        secondary: {
            light: '#8e98a0',
            main: '#6c757d',
            dark: '#525a62',
        },
        background: {
            default: '#f5f6f8'
        }
    },
    components: {
        /* Komponenty (rieši rozloženie, nie farby) */
        ...ThemeComponents.components,
        /* DataGrid */
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    backgroundColor: '#ffffff'
                }
            }
        }
    },
    layout: {
        sidebar: {
            backgroundColor: '#ffffff',
            borderColor: '#e8e7e3',
            collapse: {
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                borderColor: '#ad9551'
            }
        },
        content: {
            backgroundColor: '#ffffff'
        }
    }
},
    (
        GetLanguage() === 'en' ? enUS :
            GetLanguage() === 'fr' ? frFR :
                skSK
    )
);

export default ThemeDefault;
