import React, { useContext, useState } from 'react';
import { Localize } from '../../AppLocalization';
import { AppContext } from '../../AppContext';

// Modely
import { Task, TaskNote, User } from '../../models/Models';
import TaskCreateNote, { TaskCreateNoteProps } from './TaskCreateNote';

// Utility
import { format as dateFormat } from 'date-fns';
import parse from 'html-react-parser';

// Komponenty
import { Avatar, Button, Card, CardContent, CardHeader, Grid, IconButton, Typography } from '@mui/material';
import Confirm, { ConfirmProps } from '../../components/Confirm';

// Ikony
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonIcon from '@mui/icons-material/Person';

// Vstupné parametre
export interface TaskCreateNotesProps {
    readonly?: boolean;
    users: User[];
    source: Task;
    setSource: (action: React.SetStateAction<Task>) => void;
}

const TaskCreateNotes = (props: TaskCreateNotesProps) => {

    // Globálne premenné
    const appContext = useContext(AppContext);

    // Jazyková mutácia
    const localization = Localize();

    // Lokálny stav
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });

    // Stav (editácia položky)
    const [taskCreateNote, setTaskCreateNote] = useState<TaskCreateNoteProps>({
        open: false,
        onSave: (note, noteIndex) => {
            props.setSource(prev => {
                let notes: TaskNote[] = [];
                note.changed = true;

                // Aktualizujem alebo vytvorím záznam
                if (noteIndex !== undefined && noteIndex >= 0) {
                    notes = [...prev.notes ?? []];
                    notes[noteIndex] = { ...note };
                }
                else {
                    notes = [...prev.notes ?? [], note];
                }

                // Vrátim aktualizované záznamy
                return ({ ...prev, notes: notes });
            });
        },
        onClose: () => setTaskCreateNote(prev => ({ ...prev, open: false }))
    });

    // Pridať upraviť záznam
    const handleCreate = (index?: number) => {
        let note: TaskNote | undefined = undefined;

        // Iba na čítanie
        if ((props.readonly ?? false) === true) {
            return;
        }

        // Úprava položky podľa indexu
        if (index !== undefined && index >= 0) {
            note = (props.source?.notes ?? [])[index ?? 0];
        }

        setTaskCreateNote(prev => ({
            ...prev,
            note: note,
            noteIndex: index,
            open: true
        }));
    };

    // Vymazať záznam
    const handleDelete = (index: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: localization.deleteConfirmation, onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                props.setSource(prev => {
                    const notes = prev.notes ?? [];
                    return ({ ...prev, notes: [...notes.filter(note => note !== notes[index]) ?? []] });
                });
            }
        }));
    };

    return (
        <>

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Nový záznam */}
            <TaskCreateNote {...taskCreateNote} />

            <Grid container columnSpacing={1}>

                {(props.readonly ?? false) === false && (
                    <Grid item xs={12} mb={0}>
                        <Button disabled={props.readonly} variant="contained" size="small" startIcon={<AddIcon />} color="secondary" sx={{ mr: 1 }} onClick={() => handleCreate()}>{localization.newRecord}</Button>
                    </Grid>
                )}

                {/* Zoznam */}
                {
                    props.source.notes?.map((note, index) => {

                        // Pri novej poznámke použijem id prihláseného užívateľa
                        let userId: number = (note.id ?? 0) > 0 ? (note.createdUserId ?? 0) : (appContext.user?.id ?? 0);
                        let user: User = props.users.find(u => u.id === userId) ?? {};

                        return (
                            <Grid item xs={12} mt={2} key={index}>
                                <Card>
                                    <CardHeader
                                        avatar={
                                            user.fileIsImage ?
                                                <Avatar alt={user.name} src={user.fileSrcSmall} /> :
                                                <Avatar><PersonIcon /></Avatar>
                                        }
                                        action={
                                            <>
                                                {(props.readonly ?? false) === false && (<>
                                                    <IconButton aria-label={localization.edit} size="small" onClick={() => handleCreate(index)}>
                                                        <EditIcon fontSize="inherit" />
                                                    </IconButton>
                                                    <IconButton aria-label={localization.delete} size="small" onClick={() => handleDelete(index, localization.note)}>
                                                        <DeleteIcon fontSize="inherit" />
                                                    </IconButton>
                                                </>)}
                                            </>
                                        }
                                        title={<Typography sx={{ fontWeight: 'bold' }}>{user.name}</Typography>}
                                        subheader={dateFormat(new Date(note.createdDate ?? new Date()), 'dd.MM.yyyy HH:mm')}
                                    />
                                    <CardContent sx={{ py: 0 }}>
                                        <div className='html-description'>
                                            {parse(note.note ?? '')}
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    )
}

export default TaskCreateNotes;