import { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { AppRouteUrl } from '../../AppRoutes';
import { Localize } from '../../AppLocalization';

// Modely
import { InvoiceLite, InvoiceFilter, Country, CustomerLite, CustomerFilter } from '../../models/Models';

// Utility
import { Export as DataGridExport, Settings as DataGridSettings, LocalizeGrid } from '../../utility/DataGrid';
import { useQuery } from '../../utility/URL';
import { NullMinDate } from '../../utility/Date';
import { format as dateFormat } from 'date-fns';
import { FormatDecimal } from '../../utility/Format';

// Komponenty
import { DataGrid, GridColDef, GridValueFormatterParams, GridRenderCellParams, GridValueGetterParams, GridFilterModel, GridRowId, GridDensity, GridSortModel } from "@mui/x-data-grid";
import { Button, Divider, Grid, IconButton, ListItemIcon, Menu, Box, darken, lighten, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Search from '../../components/Search';
import { Content, ContentTop, ContentBottom } from '../../layout/Content';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import InvoiceCreate, { InvoiceCreateProps } from './InvoiceCreate';
import DataGridDensity from '../../components/DataGridDensity';
import InvoicesFiltering, { InvoicesFilteringProps } from './InvoicesFiltering';
import Reporting, { ReportingProps } from '../report/Reporting';

// Ikony
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import ClearIcon from '@mui/icons-material/Clear';
import Debounce from '../../utility/Debounce';
import PrintIcon from '@mui/icons-material/Print';
import { ConvertToInt } from '../../utility/Convert';
import { LoadLocalSettings, SaveLocalSettings } from '../../utility/LocalSettings';

// Komponent pre zoznam
const Invoices = () => {

    // Jazyková mutácia
    const localization = Localize();

    // Lokálny stav
    const [loading, setLoading] = useState<boolean>(true);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [countries, setCountries] = useState<Country[]>([]);
    const [invoiceCreate, setInvoiceCreate] = useState<InvoiceCreateProps>({
        open: false,
        keepMounted: true,
        countries: [],
        onSave: () => loadDataSafe(),
        onClose: () => setInvoiceCreate(prev => ({ ...prev, open: false }))
    });
    const [invoicesFiltering, setInvoicesFiltering] = useState<InvoicesFilteringProps>({
        open: false,
        keepMounted: true,
        onSave: (filter) => setRowsFilter({ ...filter, page: 0 }),
        onClose: () => setInvoicesFiltering(prev => ({ ...prev, open: false }))
    });
    const [suppliers, setSuppliers] = useState<CustomerLite[]>([]);

    // Načítam zoznam krajín
    const loadCountries = () => {
        axios.get(AppConfig.ApiUri + 'country').then(response => setCountries(response.data as Country[]));
    };
    useEffect(() => loadCountries(), []);

    // Načítam zoznam dodávateľov
    const loadSuppliers = () => {
        axios
            .get(AppConfig.ApiUri + 'customer', {
                params: { page: 0, pageSize: 99, sort: 'name', sortAsc: true, supplier: 1 } as CustomerFilter
            })
            .then(response => {
                setSuppliers(response.data?.list ?? []);
            });
    };
    useEffect(() => loadSuppliers(), []);

    // Tlač dokladu
    const [reporting, setReporting] = useState<ReportingProps>({
        type: 'invoice',
        settings: '',
        open: false,
        preview: true,
        print: false,
        format: 'html',
        onClose: () => setReporting((prev: any) => ({ ...prev, open: false }))
    })

    // Nastavenie zobrazenia    
    const [density, setDensity] = useState<GridDensity>('standard' as GridDensity);

    // Nastavenia stĺpcov
    const gridSettings = new DataGridSettings({ uid: 'invoices' });
    const columnsDefault: GridColDef[] = [
        { field: 'id', headerName: 'Id', hide: true, minWidth: 20, width: 90, type: 'number', align: 'center', headerAlign: 'center' },
        {
            field: 'date', headerName: localization.date, hide: false, minWidth: 50, flex: 0.2, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['date']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? dateFormat((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        { field: 'number', headerName: localization.number, hide: false, minWidth: 50, flex: 0.2 },
        { field: 'subject', headerName: localization.subject, hide: false, minWidth: 50, flex: 0.4 },
        { field: 'supplierName', headerName: localization.supplier, hide: false, minWidth: 50, flex: 0.4 },
        { field: 'customerName', headerName: localization.customer, hide: false, minWidth: 50, flex: 0.4 },
        { field: 'customerCity', headerName: localization.city, hide: true, minWidth: 50, flex: 0.2 },
        { field: 'customerStreet', headerName: localization.street, hide: true, minWidth: 50, flex: 0.2 },
        { field: 'customerHouseNumber', headerName: localization.houseNumber, hide: true, minWidth: 50, flex: 0.2 },
        { field: 'customerPostcode', headerName: localization.postcode, hide: true, minWidth: 50, flex: 0.2 },
        { field: 'customerCountryName', headerName: localization.country, hide: false, minWidth: 50, flex: 0.2 },
        { field: 'customerRegId', headerName: localization.regId, hide: true, minWidth: 50, flex: 0.2 },
        { field: 'customerTaxId', headerName: localization.taxId, hide: true, minWidth: 50, flex: 0.2 },
        { field: 'customerVatId', headerName: localization.vatId, hide: false, minWidth: 50, flex: 0.2 },
        { field: 'customerEmail', headerName: localization.email, hide: false, minWidth: 50, flex: 0.2 },
        { field: 'customerPhone', headerName: localization.phone, hide: false, minWidth: 50, flex: 0.2 },
        { field: 'note', headerName: localization.note, hide: true, minWidth: 50, flex: 0.4, sortable: false },
        {
            field: 'createdDate', headerName: localization.created, hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['createdDate']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? dateFormat((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        {
            field: 'updatedDate', headerName: localization.updated, hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => (new Date(params.row['updatedDate'])),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? dateFormat((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        {
            field: 'priceTotal', headerName: localization.subtotal, hide: false, minWidth: 60, flex: 0.2, align: 'right', headerAlign: 'right', type: 'number',
            valueFormatter: (params: GridValueFormatterParams) => FormatDecimal((params?.value as number) ?? 0, 2)
        },
        {
            field: 'priceTotalWithVat', headerName: localization.total, hide: false, minWidth: 60, flex: 0.2, align: 'right', headerAlign: 'right', type: 'number',
            valueFormatter: (params: GridValueFormatterParams) => FormatDecimal((params?.value as number) ?? 0, 2)
        },
        {
            field: 'options', headerName: localization.options, hide: false, width: 140, sortable: false, filterable: false, align: 'right', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => (
                <>
                    <IconButton aria-label={localization.print} title={localization.print} size="small" onClick={(e) => handlePrint(params.value ?? 0)}>
                        <PrintIcon color="info" fontSize="small" />
                    </IconButton>
                    <IconButton aria-label={localization.edit} title={localization.edit + ' (enter)'} size="small" onClick={() => handleCreate(params.value ?? 0, false)}>
                        <EditIcon color="primary" fontSize="small" />
                    </IconButton>
                    <IconButton aria-label={localization.copy} title={localization.copy + ' (ctrl + enter)'} size="small" onClick={() => handleCreate(params.value ?? 0, true)}>
                        <ContentCopyIcon fontSize="small" />
                    </IconButton>
                    <IconButton aria-label={localization.delete} title={localization.delete + ' (delete)'} size="small" onClick={() => handleDelete(params.value ?? 0, params.row.number)}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </>
            )
        }
    ];

    // Aplikujem uložené nastavenia
    useEffect(() => setColumns(gridSettings.columnApply(columns)), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Tabuľka
    const [rows, setRows] = useState<InvoiceLite[]>([]);
    const [rowsSelected, setRowsSelected] = useState<GridRowId[]>([])
    const [rowsSelectedMenuEl, setRowsSelectedMenuEl] = useState<null | HTMLElement>(null);
    const [rowsCount, setRowsCount] = useState<number>(0);
    const [rowsFilter, setRowsFilter] = useState<InvoiceFilter>({ page: 0, pageSize: gridSettings.pageSizeApply(25), supplierId: parseInt(LoadLocalSettings('invoices-supplier', '0')) });
    const [columns, setColumns] = useState<GridColDef[]>(columnsDefault);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();
    const [sortModel, setSortModel] = useState<GridSortModel>();

    // Funkcia pre získanie počtu aktívnych filtrov
    const filterCount = (): number => {
        let count = 0;
        if ((rowsFilter.number ?? 0) !== 0) { count++; }
        if ((rowsFilter.year ?? 0) !== 0) { count++; }
        if (rowsFilter.dateFrom !== undefined) { count++; }
        if (rowsFilter.dateTo !== undefined) { count++; }
        return count;
    };

    // Ak sa v zozname zmení poradie, tak automaticky upravím filter
    useEffect(() => {
        // Predvolené
        let sort: string = '';
        let sortAsc: boolean = false;
        // Vybrané
        if (sortModel !== undefined && sortModel.length > 0) {
            sort = sortModel[0].field;
            sortAsc = (sortModel[0].sort === 'asc');
        }
        if (rowsFilter.sort !== sort || rowsFilter.sortAsc !== sortAsc) {
            setRowsFilter(prev => ({ ...prev, sort: sort, sortAsc: sortAsc }));
        }
    }, [sortModel]); // eslint-disable-line react-hooks/exhaustive-deps

    // Pridať upraviť záznam
    const handleCreate = (id: number, copy?: boolean, field?: string) => {
        setInvoiceCreate(prev => ({
            ...prev,
            id: id,
            supplierId: rowsFilter.supplierId,
            copy: copy ?? false,
            open: true,
            autoFocus: field
        }));
    };

    const handlePrint = (id: number) => {
        if (id > 0) {
            setReporting(prev => ({ ...prev, id: id, open: true }));
            return;
        }
    };

    // Úprava záznamu podľa "id" v URL
    const history = useHistory();
    const requestId: number = parseInt(useQuery().get('id') ?? '0');
    useEffect(() => {
        if (requestId > 0) {
            history.push(AppRouteUrl.INVOICES);
            handleCreate(requestId);
        }
    }, [requestId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Vymazať záznam
    const handleDelete = (id: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: localization.invoice + ' #' + name, children: localization.deleteConfirmation, onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'invoice/' + id)
                    .then(response => {
                        if (response.data === true) {
                            setRows(prev => [...prev.filter(r => r.id !== id)]);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    const handleDeleteList = (ids: number[]) => {
        if (ids.length === 0) {
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: localization.delete + ': ' + ids.length, children: localization.deleteSeletedConfirmation, onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'invoice/list', {
                        params: {
                            'ids': ids
                        }
                    })
                    .then(response => {
                        if (response.data === true) {
                            setRows(prev => [...prev.filter(r => !ids.includes(r?.id ?? 0))]);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    const handleExportCSV = () => {
        if (rowsSelected.length === 0) {
            return;
        }
        DataGridExport({
            type: 'csv',
            columns: columns,
            columnsSkip: ['options'],
            rows: rows,
            ids: rowsSelected.map(r => r as number)
        });
    };

    const handleExportXML = () => {
        if (rowsSelected.length === 0) {
            return;
        }
        DataGridExport({
            type: 'xml',
            columns: columns,
            columnsSkip: ['options'],
            rows: rows,
            ids: rowsSelected.map(r => r as number)
        });
    };

    // Funkcia pre načítanie dát z API
    const loadData = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'invoice', {
                params: rowsFilter
            })
            .then(response => {
                setRows((response.data?.list ?? []) as InvoiceLite[]);
                setRowsCount(response.data?.itemsCount ?? 0);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Funkcia pre bezpečné obnovenie zoznamu pomocou zmeny stavu (cudzie vlákno môže mať problém so stavom filtra)
    const loadDataSafe = () => {
        setRowsFilter(prev => ({ ...prev }));
    }

    // Automatická obnova zoznamu po otvorení stránky alebo zmene filtru
    const loadDataDebounce = Debounce(() => loadData(), 100);
    useEffect(() => loadDataDebounce(), [rowsFilter]); // eslint-disable-line react-hooks/exhaustive-deps

    // Prepočíta farbu (zosvetlenie alebo stmavenie, napr. pre hover)
    const getHoverBackgroundColor = (color: string, mode: string) => mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

    return (
        <>
            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Formulár pre nový záznam */}
            <InvoiceCreate {...invoiceCreate} countries={countries} />

            {/* Obsah */}
            <Content>
                <ContentTop>
                    <Grid container alignItems="center">

                        {/* Možnosti */}
                        <Grid item xs={9} md lg>

                            {/* Nový záznam */}
                            <Button variant="contained" sx={{ mr: 1 }} size="large" startIcon={<AddIcon />} onClick={() => handleCreate(0)}>{localization.newRecord}</Button>

                            {/* Označené záznamy (možnosti) */}
                            <Button variant="text" size="large" disabled={rowsSelected?.length === 0} aria-controls="menu-selected" aria-haspopup="true" onClick={(e) => setRowsSelectedMenuEl(e.currentTarget)} endIcon={<ExpandMoreIcon />}>{localization.selected} {'(' + rowsSelected.length.toString() + ')'}</Button>
                            <Menu id="menu-selected" anchorEl={rowsSelectedMenuEl} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} sx={{ mt: '55px' }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} open={Boolean(rowsSelectedMenuEl)} onClose={() => setRowsSelectedMenuEl(null)} >
                                <MenuItem onClick={() => { handleExportCSV(); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> {localization.saveAs} CSV (Excel)
                                </MenuItem>
                                <MenuItem onClick={() => { handleExportXML(); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> {localization.saveAs} XML
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { handleDeleteList(rowsSelected.map(r => r as number)); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon> {localization.delete}
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { setRowsSelected([]); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><ClearIcon fontSize="small" /></ListItemIcon> {localization.cancelSelection}
                                </MenuItem>
                            </Menu>
                        </Grid>

                        {/* Zobrazenie */}
                        <Grid item xs md={1} textAlign="right">
                            <DataGridDensity onDensityChanged={setDensity} />
                        </Grid>

                        {/* Dodávateľ */}
                        <Grid item xs md={2} sx={{ pr: 1 }}>
                            <Select size="small" value={rowsFilter.supplierId ?? 0} fullWidth
                                name="supplierId" onChange={(e) => {
                                    setRowsFilter(prev => ({ ...prev, page: 0, supplierId: ConvertToInt(e.target.value) }));
                                    SaveLocalSettings('invoices-supplier', e.target.value.toString());
                                }}>
                                <MenuItem key={0} value={0}>{localization.supplier}</MenuItem>
                                {suppliers?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                            </Select>
                        </Grid>

                        {/* Vyhľadávanie */}
                        <Grid item xs={12} md={4} sx={{ mt: { xs: 1, md: 0 } }}>
                            <Search
                                onSearch={s => setRowsFilter(prev => ({ ...prev, page: 0, search: s }))}
                                onClear={() => {
                                    setRowsFilter(prev => ({
                                        page: 0,
                                        pageSize: prev.pageSize,
                                        sort: prev.sort,
                                        sortAsc: prev.sortAsc,
                                        supplierId: prev.supplierId
                                    }));
                                }}
                                label={localization.enterKeyword + ', ' + localization.number.toLowerCase() + ': #123'}
                                autoFocus={true}
                                filter={true}
                                filterCount={filterCount()}
                                onFilter={() => setInvoicesFiltering(prev => ({ ...prev, filter: rowsFilter, open: true }))}
                            />
                        </Grid>
                    </Grid>
                </ContentTop>
                <ContentBottom>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <Box sx={{
                            height: '100%', width: '100%',
                            '& .gridstyle-out': {
                                opacity: .4,
                                bgcolor: (theme) => '#f0f0f0',
                                '&:hover': {
                                    bgcolor: (theme) => getHoverBackgroundColor('#f3f4f5', theme.palette.mode),
                                },
                            },
                        }}>
                            <DataGrid
                                getRowClassName={(params) => {
                                    return (NullMinDate(params.row.dateOfExit) !== null ? 'gridstyle-out' : '');
                                }}
                                getRowId={row => row.id}
                                density={density}
                                checkboxSelection
                                disableSelectionOnClick

                                columns={columns}
                                rows={rows}
                                rowCount={rowsCount}

                                pagination
                                paginationMode="server"
                                page={rowsFilter.page}
                                pageSize={rowsFilter.pageSize}

                                rowsPerPageOptions={[10, 25, 50]}
                                onPageChange={(page) => setRowsFilter(prev => ({ ...prev, page: page }))}
                                onPageSizeChange={(pageSize) => {
                                    setRowsFilter(prev => ({ ...prev, page: 0, pageSize: pageSize }));
                                    gridSettings.pageSizeChanged(pageSize);
                                }}

                                sortingMode="server"
                                sortModel={sortModel}
                                onSortModelChange={(model) => setSortModel(model)}

                                localeText={LocalizeGrid()}
                                loading={loading}

                                // Dvoj-klik (úprava)
                                onCellDoubleClick={(e) => {
                                    handleCreate(e.row.id, false, e.field);
                                }}

                                // Klávesnica (shift+enter => upraviť, shift+delete => vymazať, shift+space => označiť, vstavaná funkcia)
                                onCellKeyDown={(e, c) => {
                                    if (c.code === 'Enter' && c.ctrlKey) {
                                        c.preventDefault();
                                        c.stopPropagation();
                                        handleCreate(e.row.id, true, e.field);
                                        return;
                                    }
                                    if (c.code === 'Enter' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                        c.preventDefault();
                                        c.stopPropagation();
                                        handleCreate(e.row.id, false, e.field);
                                        return;
                                    }
                                    if (c.code === 'Delete' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                        c.preventDefault();
                                        c.stopPropagation();
                                        handleDelete(e.row.id, e.row.number);
                                        return;
                                    }
                                }}

                                // Filtrácia
                                filterModel={filterModel}
                                onFilterModelChange={e => setFilterModel(e)}

                                // Vybrané záznamy
                                selectionModel={rowsSelected}
                                onSelectionModelChange={e => setRowsSelected(e)}

                                // Stĺpce (automatické ukladanie nastavení)
                                onColumnVisibilityChange={e => gridSettings.columnVisibilityChanged(e, columnsDefault)}
                            />
                        </Box>
                    </div>
                </ContentBottom>
            </Content>

            {/* Formulár pre filtráciu */}
            <InvoicesFiltering {...invoicesFiltering} />

            {/* Tlač dokladu */}
            <Reporting {...reporting} />
        </>
    )
}

export default Invoices;