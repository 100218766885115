import { FormEvent, useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Utility
import { Localize } from '../../AppLocalization';

// Modely
import { ScheduleOptions } from '../../models/Models';

// Komponenty
import { Alert, AlertColor, Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, Typography, FormControl, InputLabel, MenuItem, Select, Backdrop, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import WeekDatePicker from '../../components/WeekDatePicker';
import Reporting, { ReportingProps } from '../report/Reporting';

// Ikony

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Vstupné parametre
export interface ProductionsScheduleProps {
    open: boolean;
    keepMounted?: boolean;
    onClose: () => void;
}

const ProductionsSchedule = (props: ProductionsScheduleProps) => {

    // Všobecne
    const localization = Localize();

    // Stav
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState<string>('');
    const [source, setSource] = useState<ScheduleOptions>({ mode: 0 });

    // Tlač dokladu
    const [reporting, setReporting] = useState<ReportingProps>({
        type: 'schedule',
        settings: '',
        open: false,
        preview: true,
        print: false,
        format: 'html',
        onClose: () => setReporting(prev => ({ ...prev, open: false }))
    });

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        // Skontrolujem, či je vybrané obdobie
        if (source.startOfWeek === undefined || source.endOfWeek === undefined) {
            setWarning({ ...EmptyWarning, name: 'email', text: localization.mustFill + ': ' + localization.workingPeriod + '!' });
            return;
        }

        setWarning(EmptyWarning);
        setLoading(true);
        setLoadingText(localization.scheduleCalculation);

        axios
            .post(AppConfig.ApiUri + 'schedule', source)
            .then(response => {
                if (response.data === 1) {
                    setReporting(prev => ({ ...prev, open: true, settings: encodeURIComponent(JSON.stringify(source)) }));
                } else {
                    setWarning({ ...EmptyWarning, text: localization.unknownFail });
                }
            })
            .catch(() => setWarning({ ...EmptyWarning, text: localization.unknownFail }))
            .finally(() => {
                setLoading(false);
                setLoadingText('');
            });
    };

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="xs" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                    <Typography ml={2}>{loadingText}</Typography>
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {localization.schedule}
                        <Typography variant='h6' component="div">
                            {source.startOfWeek?.toLocaleDateString()} - {source.endOfWeek?.toLocaleDateString()}
                        </Typography>
                    </DialogTitle>
                    <DialogContent sx={{ maxHeight: '75vh' }}>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12}>
                                <WeekDatePicker onChange={(startOfWeek: Date, endOfWeek: Date) => {
                                    handleChange('startOfWeek', startOfWeek);
                                    handleChange('endOfWeek', endOfWeek);
                                }} />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="schedule-mode">{localization.options}</InputLabel>
                                    <Select labelId="schedule-mode" label={localization.options} value={source.mode ?? 0} name="mode" onChange={(e) => { handleChange(e.target.name, e.target.value) }}>
                                        <MenuItem value={0}>{localization.scheduleModeGenerateMissing}</MenuItem>
                                        <MenuItem value={1}>{localization.scheduleModeGenerateAll}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} mt={1}>
                                <Alert severity='info'>
                                    {(source.mode ?? 0) === 0 ? localization.scheduleModeGenerateMissingInfo : localization.scheduleModeGenerateAllInfo}
                                </Alert>
                            </Grid>

                            {(warning.text.length > 0 && <Grid item xs={12} mt={1}><Alert severity={warning.color as AlertColor}>{warning.text}</Alert></Grid>)}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.onClose}>{localization.back}</Button>
                        <Button type="submit" variant="contained">{localization.print}</Button>
                    </DialogActions>
                </Box>

                {/* Tlač dokladu */}
                <Reporting {...reporting} />
            </Dialog>
        </>
    )
}

export default ProductionsSchedule;