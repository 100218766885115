import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { GetRoutes } from '../AppRoutes';
import { AppBar, Avatar, IconButton, ListItemIcon, Menu, MenuItem, Stack, Toolbar, Tooltip, Typography } from '@mui/material';
import { Localize, SetLanguage } from '../AppLocalization';
import { AppContext } from '../AppContext';

// Utility

// Komponenty
import Logout from '../views/system/Logout';
import PasswordChange from '../views/system/PasswordChange';

// Ikony
import MenuIcon from '@mui/icons-material/Menu';
import LanguageIcon from '@mui/icons-material/Language';
import FlagIcon from '@mui/icons-material/Flag';
import LockIcon from '@mui/icons-material/Lock';
import { AccountCircle } from '@mui/icons-material';

interface Props {
    onToggleSidebar?: () => void;
    onToggleDarkMode?: () => void;
}

const Header = (props: Props) => {

    // Všeobecne
    const context = useContext(AppContext);
    const localization = Localize();

    // Lokálny stav
    const [passwordChangeOpen, setPasswordChangeOpen] = useState(false);

    // Pri každej zmene zistím podľa aktuálnej URL nadpis z routes
    const location = useLocation();
    const [title, setTitle] = useState<String>('');
    const findTitle = () => {
        setTitle(GetRoutes(context.user?.admin ?? false).find(route => route.page === location.pathname)?.title ?? '');
        // @ts-ignore
        document.title = title;
    }
    useEffect(findTitle, [findTitle]);

    // Overenie zobrazenia na mobile

    // Elementy
    const [anchorLanguageEl, setAnchorLanguageEl] = useState<null | HTMLElement>(null);
    const [anchorAccountEl, setAnchorAccountEl] = useState<null | HTMLElement>(null);

    return (
        <>
            {(passwordChangeOpen && <PasswordChange onClose={() => { setPasswordChangeOpen(!passwordChangeOpen) }} />)}

            <AppBar position="absolute" sx={{ backgroundColor: '#ffffff' }}>
                <Toolbar>
                    <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={() => { if (props?.onToggleSidebar) { props?.onToggleSidebar() } }} sx={{ mr: 2 }} >
                        <MenuIcon sx={{ color: 'black' }} />
                    </IconButton>
                    <Typography noWrap variant="h6" component="div" sx={{ flexGrow: 2, maxHeight: '30px', overflow: 'hidden', color: 'black' }}>
                        {title}
                    </Typography>

                    {/******* Jazyk *******/}
                    <IconButton size="large" aria-controls="menu-language" aria-haspopup="true" onClick={(e) => setAnchorLanguageEl(e.currentTarget)} color="inherit">
                        <LanguageIcon sx={{ color: 'silver' }} />
                    </IconButton>
                    <Menu id="menu-language" anchorEl={anchorLanguageEl} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right' }} open={Boolean(anchorLanguageEl)} onClose={() => setAnchorLanguageEl(null)} >
                        <MenuItem dense onClick={() => { SetLanguage('fr'); }}>
                            <ListItemIcon><FlagIcon fontSize="small" /></ListItemIcon> Français
                        </MenuItem>
                        <MenuItem dense onClick={() => { SetLanguage('en'); }}>
                            <ListItemIcon><FlagIcon fontSize="small" /></ListItemIcon> English
                        </MenuItem>
                        <MenuItem dense onClick={() => { SetLanguage('sk'); }}>
                            <ListItemIcon><FlagIcon fontSize="small" /></ListItemIcon> Slovenčina
                        </MenuItem>
                    </Menu>

                    {/******* Užívateľský účet *******/}
                    <IconButton size={context.user?.avatar?.length ? 'small' : 'large'} aria-label={context?.user?.name} aria-controls="menu-account" aria-haspopup="true" onClick={(e) => setAnchorAccountEl(e.currentTarget)} color="inherit">
                        <Tooltip title={context?.user?.name ?? ''}>
                            {context.user?.avatar?.length ? <Avatar alt={context.user.name} sx={{ width: 40, height: 40, p: 0 }} src={context.user.avatar} /> : <AccountCircle sx={{ color: 'silver' }} />}
                        </Tooltip>
                    </IconButton>
                    <Menu id="menu-account" PaperProps={{ style: { minWidth: 200 }}} anchorEl={anchorAccountEl} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right' }} open={Boolean(anchorAccountEl)} onClose={() => setAnchorAccountEl(null)} >
                        <Stack direction="column" justifyContent="center" alignItems="center" sx={{ width: 1, height: "200px" }}>
                            {context.user?.avatar?.length ? <Avatar sx={{ width: 110, height: 110, p: 0, outline: 'solid 10px whitesmoke' }} src={context.user.avatar} /> : <Avatar sx={{ opacity: .2, width: 110, height: 110, p: 0, outline: 'solid 10px whitesmoke' }}><AccountCircle sx={{ fontSize: 100 }} /></Avatar>}
                            <Typography fontWeight="bold" mt={2} color="secondary" variant="caption">{context?.user?.name ?? ''}</Typography>
                        </Stack>
                        <MenuItem dense onClick={() => { setPasswordChangeOpen(true) }}>
                            <ListItemIcon><LockIcon fontSize="small" /></ListItemIcon> {localization.changePassword}
                        </MenuItem>
                        <Logout />
                    </Menu>
                </Toolbar>
            </AppBar>
        </>
    )
}

export default Header;