import { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { AppRouteUrl } from '../../AppRoutes';
import { Localize } from '../../AppLocalization';

// Modely
import { CustomerLite, CustomerFilter, Country } from '../../models/Models';

// Utility
import { Export as DataGridExport, Settings as DataGridSettings, LocalizeGrid } from '../../utility/DataGrid';
import { useQuery } from '../../utility/URL';
import { format as dateFormat } from 'date-fns';

// Komponenty
import { DataGrid, GridColDef, GridValueFormatterParams, GridRenderCellParams, GridValueGetterParams, GridFilterModel, GridRowId, GridDensity, GridSortModel } from "@mui/x-data-grid";
import { Button, Divider, Grid, IconButton, ListItemIcon, Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Search from '../../components/Search';
import { Content, ContentTop, ContentBottom } from '../../layout/Content';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import CustomerCreate, { CustomerCreateProps } from './CustomerCreate';
import DataGridDensity from '../../components/DataGridDensity';

// Ikony
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import ClearIcon from '@mui/icons-material/Clear';
import Debounce from '../../utility/Debounce';

// Vstupné parametre
export interface CustomerProps {
    filter?: CustomerFilter;
    onSelectionChanged?: (rows: CustomerLite[], confirm: boolean) => void;
}

// Komponent pre zoznam
const Customers = (props?: CustomerProps) => {

    // Jazyková mutácia
    const localization = Localize();

    // Lokálny stav
    const [loading, setLoading] = useState<boolean>(true);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [countries, setCountries] = useState<Country[]>([]);
    const [customerCreate, setCustomerCreate] = useState<CustomerCreateProps>({
        open: false,
        keepMounted: true,
        countries: [],
        onSave: () => loadDataSafe(),
        onClose: () => setCustomerCreate(prev => ({ ...prev, open: false }))
    });

    // Načítam zoznam krajín
    const loadCountries = () => {
        axios.get(AppConfig.ApiUri + 'country').then(response => setCountries(response.data as Country[]));
    };
    useEffect(() => loadCountries(), []);

    // Nastavenie zobrazenia    
    const [density, setDensity] = useState<GridDensity>('standard' as GridDensity);

    // Nastavenia stĺpcov
    const gridSettings = new DataGridSettings({ uid: 'customers' });
    const columnsDefault: GridColDef[] = [
        { field: 'id', headerName: 'Id', hide: true, minWidth: 20, width: 90, type: 'number', align: 'center', headerAlign: 'center' },
        { field: 'supplier', headerName: localization.supplier, hide: false, minWidth: 50, flex: 0.2, type: 'boolean' },
        { field: 'name', headerName: localization.title, hide: false, minWidth: 50, flex: 0.8 },
        { field: 'regId', headerName: localization.regId, hide: false, minWidth: 50, flex: 0.3 },
        { field: 'taxId', headerName: localization.taxId, hide: false, minWidth: 50, flex: 0.3 },
        { field: 'vatId', headerName: localization.vatId, hide: false, minWidth: 50, flex: 0.3 },
        { field: 'email', headerName: localization.email, hide: false, minWidth: 50, flex: 0.4 },
        { field: 'phone', headerName: localization.phone, hide: false, minWidth: 50, flex: 0.4 },
        { field: 'street', headerName: localization.street, hide: true, minWidth: 50, flex: 0.4 },
        { field: 'houseNumber', headerName: localization.houseNumber, hide: true, minWidth: 50, flex: 0.3 },
        { field: 'city', headerName: localization.city, hide: true, minWidth: 50, flex: 0.4 },
        { field: 'postcode', headerName: localization.postcode, hide: true, minWidth: 50, flex: 0.3 },
        { field: 'country', headerName: localization.country, hide: true, minWidth: 50, flex: 0.4 },
        { field: 'note', headerName: localization.note, hide: false, minWidth: 50, flex: 0.4, sortable: false },
        {
            field: 'createdDate', headerName: localization.created, hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['createdDate']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? dateFormat((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        {
            field: 'updatedDate', headerName: localization.updated, hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => (new Date(params.row['updatedDate'])),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? dateFormat((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        {
            field: 'options', headerName: localization.options, hide: false, width: 120, sortable: false, filterable: false, align: 'right', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => (
                <>
                    {(params.row.canWrite ?? false) === true && (<>
                        <IconButton aria-label={localization.edit} title={localization.edit + ' (enter)'} size="small" onClick={() => handleCreate(params.value ?? 0, false)}>
                            <EditIcon color="primary" fontSize="small" />
                        </IconButton>
                        <IconButton aria-label={localization.copy} title={localization.copy + ' (ctrl + enter)'} size="small" onClick={() => handleCreate(params.value ?? 0, true)}>
                            <ContentCopyIcon fontSize="small" />
                        </IconButton>
                        <IconButton aria-label={localization.delete} title={localization.delete + ' (delete)'} size="small" onClick={() => handleDelete(params.value ?? 0, params.row.name)}>
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </>)}
                </>
            )
        }
    ];

    // Aplikujem uložené nastavenia
    useEffect(() => setColumns(gridSettings.columnApply(columns)), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Tabuľka
    const [rows, setRows] = useState<CustomerLite[]>([]);
    const [rowsSelected, setRowsSelected] = useState<GridRowId[]>([])
    const [rowsSelectedMenuEl, setRowsSelectedMenuEl] = useState<null | HTMLElement>(null);
    const [rowsCount, setRowsCount] = useState<number>(0);
    const [rowsFilter, setRowsFilter] = useState<CustomerFilter>({ ...props?.filter ?? {}, page: 0, pageSize: gridSettings.pageSizeApply(25) });
    const [columns, setColumns] = useState<GridColDef[]>(columnsDefault);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();
    const [sortModel, setSortModel] = useState<GridSortModel>();

    // Ak sa na vstupe zmení filter, tak ho automaticky premietnem do aktuálneho filtra
    useEffect(() => {
        setRowsFilter(prev => ({ ...prev, ...props?.filter, page: 0 }));
    }, [props?.filter]); // eslint-disable-line react-hooks/exhaustive-deps

    // Ak sa v zozname zmení poradie, tak automaticky upravím filter
    useEffect(() => {
        // Predvolené
        let sort: string = 'name';
        let sortAsc: boolean = true;
        // Vybrané
        if (sortModel !== undefined && sortModel.length > 0) {
            sort = sortModel[0].field;
            sortAsc = (sortModel[0].sort === 'asc');
        }
        if (rowsFilter.sort !== sort || rowsFilter.sortAsc !== sortAsc) {
            setRowsFilter(prev => ({ ...prev, sort: sort, sortAsc: sortAsc }));
        }
    }, [sortModel]); // eslint-disable-line react-hooks/exhaustive-deps

    // Automatické odovzdanie označených záznamov, ak je nastavená udalosť
    useEffect(() => {
        if (props?.onSelectionChanged) {
            const ids = rowsSelected.map(r => r as number);
            props.onSelectionChanged(rows.filter(item => ids.includes((item.id ?? 0))) ?? [], false);
        }
    }, [rowsSelected]); // eslint-disable-line react-hooks/exhaustive-deps

    // Dvoj-klik v zozname
    const handleDoubleClick = (id: number, field: string) => {
        // Ak požadujem výber, tak po dvoj-kliku prenesiem konkrétny záznam
        if (props?.onSelectionChanged) {
            const item = rows.find(item => item.id === id);
            if (item !== undefined) {
                props.onSelectionChanged([item], true);
                return;
            }
        }
        // Predvolená akcia pre úpravu záznamu
        handleCreate(id, false, field);
    };

    // Pridať upraviť záznam
    const handleCreate = (id: number, copy?: boolean, field?: string) => {
        setCustomerCreate(prev => ({
            ...prev,
            id: id,
            copy: copy ?? false,
            open: true,
            autoFocus: field
        }));
    };

    // Úprava záznamu podľa "id" v URL
    const history = useHistory();
    const requestId: number = parseInt(useQuery().get('id') ?? '0');
    useEffect(() => {
        if (requestId > 0) {
            history.push(AppRouteUrl.CUSTOMERS);
            handleCreate(requestId);
        }
    }, [requestId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Vymazať záznam
    const handleDelete = (id: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: localization.deleteConfirmation, onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'customer/' + id)
                    .then(response => {
                        if (response.data === true) {
                            setRows(prev => [...prev.filter(r => r.id !== id)]);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    const handleDeleteList = (ids: number[]) => {
        if (ids.length === 0) {
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: localization.delete + ': ' + ids.length, children: localization.deleteSeletedConfirmation, onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'customer/list', {
                        params: {
                            'ids': ids
                        }
                    })
                    .then(response => {
                        if (response.data === true) {
                            setRows(prev => [...prev.filter(r => !ids.includes(r?.id ?? 0))]);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    const handleExportCSV = () => {
        if (rowsSelected.length === 0) {
            return;
        }
        DataGridExport({
            type: 'csv',
            columns: columns,
            columnsSkip: ['options'],
            rows: rows,
            ids: rowsSelected.map(r => r as number)
        });
    };

    const handleExportXML = () => {
        if (rowsSelected.length === 0) {
            return;
        }
        DataGridExport({
            type: 'xml',
            columns: columns,
            columnsSkip: ['options'],
            rows: rows,
            ids: rowsSelected.map(r => r as number)
        });
    };

    // Funkcia pre načítanie dát z API
    const loadData = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'customer', {
                params: rowsFilter
            })
            .then(response => {
                setRows(response.data?.list ?? []);
                setRowsCount(response.data?.itemsCount ?? 0);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Funkcia pre bezpečné obnovenie zoznamu pomocou zmeny stavu (cudzie vlákno môže mať problém so stavom filtra)
    const loadDataSafe = () => {
        setRowsFilter(prev => ({ ...prev }));
    }

    // Automatická obnova zoznamu po otvorení stránky alebo zmene filtru
    const loadDataDebounce = Debounce(() => loadData(), 100);
    useEffect(() => loadDataDebounce(), [rowsFilter]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Formulár pre nový záznam */}
            <CustomerCreate {...customerCreate} countries={countries} />

            {/* Obsah */}
            <Content>
                <ContentTop>
                    <Grid container alignItems="center">

                        {/* Možnosti */}
                        <Grid item xs={9} md lg>

                            {/* Nový záznam */}
                            <Button variant="contained" sx={{ mr: 1 }} size="large" startIcon={<AddIcon />} onClick={() => handleCreate(0)}>{localization.newRecord}</Button>

                            {/* Označené záznamy (možnosti) */}
                            <Button variant="text" size="large" disabled={rowsSelected?.length === 0} aria-controls="menu-selected" aria-haspopup="true" onClick={(e) => setRowsSelectedMenuEl(e.currentTarget)} endIcon={<ExpandMoreIcon />}>{localization.selected} {'(' + rowsSelected.length.toString() + ')'}</Button>
                            <Menu id="menu-selected" anchorEl={rowsSelectedMenuEl} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} sx={{ mt: '55px' }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} open={Boolean(rowsSelectedMenuEl)} onClose={() => setRowsSelectedMenuEl(null)} >
                                <MenuItem onClick={() => { handleExportCSV(); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> {localization.saveAs} CSV (Excel)
                                </MenuItem>
                                <MenuItem onClick={() => { handleExportXML(); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> {localization.saveAs} XML
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { handleDeleteList(rowsSelected.map(r => r as number)); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon> {localization.delete}
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { setRowsSelected([]); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><ClearIcon fontSize="small" /></ListItemIcon> {localization.cancelSelection}
                                </MenuItem>
                            </Menu>
                        </Grid>

                        {/* Zobrazenie */}
                        <Grid item xs md={2} textAlign="right">
                            <DataGridDensity onDensityChanged={setDensity} />
                        </Grid>

                        {/* Vyhľadávanie */}
                        <Grid item xs={12} md={4} sx={{ mt: { xs: 1, md: 0 } }}>
                            <Search
                                onSearch={s => setRowsFilter(prev => ({ ...prev, page: 0, search: s }))}
                                onClear={() => {
                                    setRowsFilter(prev => ({
                                        page: 0,
                                        pageSize: prev.pageSize,
                                        sort: prev.sort,
                                        sortAsc: prev.sortAsc,
                                        ...props?.filter ?? {}
                                    }));
                                }}
                                autoFocus={true}
                            />
                        </Grid>
                    </Grid>
                </ContentTop>
                <ContentBottom>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <DataGrid
                            getRowId={row => row.id}
                            density={density}
                            checkboxSelection
                            disableSelectionOnClick

                            columns={columns}
                            rows={rows}
                            rowCount={rowsCount}

                            pagination
                            paginationMode="server"
                            page={rowsFilter.page}
                            pageSize={rowsFilter.pageSize}

                            rowsPerPageOptions={[10, 25, 50]}
                            onPageChange={(page) => setRowsFilter(prev => ({ ...prev, page: page }))}
                            onPageSizeChange={(pageSize) => {
                                setRowsFilter(prev => ({ ...prev, page: 0, pageSize: pageSize }));
                                gridSettings.pageSizeChanged(pageSize);
                            }}

                            sortingMode="server"
                            sortModel={sortModel}
                            onSortModelChange={(model) => setSortModel(model)}

                            localeText={LocalizeGrid()}
                            loading={loading}

                            // Dvoj-klik (úprava)
                            onCellDoubleClick={(e) => {

                                // Skontrolujem oprávnenia
                                if ((e.row.canWrite ?? false) === false) {
                                    // Ak požadujem výber (ten je povolený), tak po dvoj-kliku prenesiem konkrétny záznam
                                    if (props?.onSelectionChanged) {
                                        const item = rows.find(item => item.id === e.row.id);
                                        if (item !== undefined) {
                                            props.onSelectionChanged([item], true);
                                            return;
                                        }
                                    }
                                    return;
                                }

                                handleDoubleClick(e.row.id, e.field);
                            }}

                            // Klávesnica (shift+enter => upraviť, shift+delete => vymazať, shift+space => označiť, vstavaná funkcia)
                            onCellKeyDown={(e, c) => {

                                // Skontrolujem oprávnenia
                                if ((e.row.canWrite ?? false) === false) {
                                    return;
                                }

                                if (c.code === 'Enter' && c.ctrlKey) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    handleCreate(e.row.id, true, e.field);
                                    return;
                                }
                                if (c.code === 'Enter' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    handleCreate(e.row.id, false, e.field);
                                    return;
                                }
                                if (c.code === 'Delete' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    handleDelete(e.row.id, e.row.name);
                                    return;
                                }
                            }}

                            // Filtrácia
                            filterModel={filterModel}
                            onFilterModelChange={e => setFilterModel(e)}

                            // Vybrané záznamy
                            selectionModel={rowsSelected}
                            onSelectionModelChange={e => setRowsSelected(e)}

                            // Stĺpce (automatické ukladanie nastavení)
                            onColumnVisibilityChange={e => gridSettings.columnVisibilityChanged(e, columnsDefault)}
                        />
                    </div>
                </ContentBottom>
            </Content>
        </>
    )
}

export default Customers;