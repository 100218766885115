import { FormEvent, useEffect, useState } from 'react';

// Utility
import { GetDateLocale, Localize } from '../../AppLocalization';
import { NullMinDate } from '../../utility/Date';

// Modely
import { ProjectState, ProjectType, ProjectFilter as ProjectsFilter } from '../../models/Models';

// Komponenty
import { Alert, AlertColor, Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, FormControl, Chip, InputLabel, MenuItem, Select } from '@mui/material';
import Grid from '@mui/material/Grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Ikony

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Vstupné parametre
export interface ProjectsFilteringProps {
    open: boolean;
    filter?: ProjectsFilter;
    autoFocus?: string;
    keepMounted?: boolean;
    projectStates: ProjectState[];
    projectTypes: ProjectType[];
    onSave: (filter: ProjectsFilter) => void;
    onClose: () => void;
}

// Prázdny filter
const EmptyFilter: ProjectsFilter = {
    page: 0,
    pageSize: 25,
    sort: '',
    sortAsc: false
}

const ProjectsFiltering = (props: ProjectsFilteringProps) => {

    // Všobecne
    const localization = Localize();

    // Stav
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<ProjectsFilter>({ ...EmptyFilter });

    // Automaticky naplním zdroj
    useEffect(() => {
        setSource(props.filter ?? EmptyFilter);
    }, [props.filter, props.open]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Reset formuláru (resetujem iba tie hodnoty, ktoré viem upraviť cez tento formulár)
    const handleReset = () => {
        setSource(prev => ({
            page: 0,
            pageSize: prev.pageSize,
            sort: prev.sort,
            sortAsc: prev.sortAsc
        }))
    };

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        props.onSave(source);
        props.onClose();
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {localization.filter}
                    </DialogTitle>
                    <DialogContent sx={{ maxHeight: '75vh' }}>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={GetDateLocale()}>
                                    <FormControl margin="dense" fullWidth>
                                        <DatePicker format='dd.MM.yyyy' label={localization.date + ' (' + localization.from.toLowerCase() + ')'} value={NullMinDate(source.dateFrom)} onChange={(d) => { handleChange('dateFrom', d) }} inputRef={input => { props.autoFocus === 'dateFrom' && setFocus(input) }} />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={GetDateLocale()}>
                                    <FormControl margin="dense" fullWidth>
                                        <DatePicker format='dd.MM.yyyy' label={localization.date + ' (' + localization.to.toLowerCase() + ')'} value={NullMinDate(source.dateTo)} onChange={(d) => { handleChange('dateTo', d) }} inputRef={input => { props.autoFocus === 'dateTo' && setFocus(input) }} />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth margin="dense">
                                    <InputLabel id="projects-filter-projectstate">{localization.projectState}</InputLabel>
                                    <Select multiple labelId="projects-filter-projectstate" label={localization.projectState} name="projectStateIds" value={source.projectStateIds ?? []} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: .3, height: 22 }}>
                                                {selected.map((value) => {
                                                    var item = props.projectStates.find(item => item.id === value) ?? { name: '', color: '333333' };
                                                    return (
                                                        <Chip size="small" key={value} label={item.name} sx={{ m: 0, bgcolor: '#' + ((item.color ?? '').length > 0 ? item.color : 'f0f0f0'), color: ((item.color ?? '').length > 0 ? '#ffffff' : '#222222') }} />
                                                    )
                                                })}
                                            </Box>
                                        )}>
                                        {props.projectStates.map(item => (<MenuItem key={item.id} value={item.id} sx={{ color: '#' + ((item.color ?? '').length > 0 ? item.color : 'f0f0f0') }}>{item.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth margin="dense">
                                    <InputLabel id="projects-filter-projecttype">{localization.projectType}</InputLabel>
                                    <Select multiple labelId="projects-filter-projecttype" label={localization.projectType} name="projectTypeIds" value={source.projectTypeIds ?? []} onChange={(e) => { handleChange(e.target.name, e.target.value) }}>
                                        {props.projectTypes.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleReset} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>{localization.reset}</Button>
                        <Button onClick={props.onClose}>{localization.back}</Button>
                        <Button type="submit" variant="contained">{localization.use}</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default ProjectsFiltering;