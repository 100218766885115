import { createContext } from 'react';

// Poznámky
// --------------------------------------
// Príklad zmeny kontextu cez vnorený komponent: appContext.setContext?.(prev => ({ ...prev, user: { ...appContext.user, name: 'New Name' } }));
// 
// 
// 
// 
// 

// Predpis pre globálny obsah aplikácie
export interface AppContextModel {

    // Callback pre zmenu kontextu z podradených elementov
    setContext?: (action: React.SetStateAction<AppContextModel>) => void;

    // Hodnoty v kontexte
    user?: AppContextUser;

};

// Informácie o užívateľovi
export interface AppContextUser {
    id?: number;
    admin?: boolean;
    name?: string;
    avatar?: string;
}

// Zabezpečuje prenos globálnych premenných, nachádza sa v najvyššej úrovni aplikácie
export const AppContext = createContext<AppContextModel>({
        
});
