import { FormEvent, useCallback, useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { GetDateLocale, Localize } from '../../AppLocalization';

// Modely
import { FileItem, User } from '../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, FormControlLabel, Switch, Card, CardMedia, CardActions, IconButton, Accordion, AccordionDetails, AccordionSummary, Typography, FormControl, Table, TableBody, TableCell, TableHead, TableRow, MenuItem, Select } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import FileUpload from '../../components/FileUpload';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { NullMinDate } from '../../utility/Date';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ReplayIcon from '@mui/icons-material/Replay';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import SortIcon from '@mui/icons-material/Sort';
import { ConvertToInt } from '../../utility/Convert';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    user: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    user: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): User => ({
    id: 0,
    actived: true,
    admin: false,
    email: '',
    name: '',
    password: '',
    note: '',
    fileName: '',
    workingPeriods: [],
    workingTimes: []
});

// Vstupné parametre
export interface UserCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const daysColors: string[] = ['#fff7e1', '#e8ffe1', '#e1fff3', '#e1f0ff', '#f4e1ff', '#e1e2ff', '#ffe1e1'];

const UserCreate = (props: UserCreateProps) => {

    // Jazyková mutácia
    const localization = Localize();

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<User>(EmptySource());
    const [sourcePasswordVerify, setSourcePasswordVerify] = useState<string | undefined>(undefined);
    const [avatars, setAvatars] = useState<FileItem[]>([]);

    // Harmonika
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    const loadAvatars = () => {
        axios
            .get(AppConfig.ApiUri + 'user/avatar/')
            .then(response => setAvatars(response.data));
    };
    useEffect(() => loadAvatars(), []);

    // Vložiť nahrané súbory
    const handleFileUpload = (files: FileItem[]) => {
        if (files.length === 0) {
            return;
        }
        setSource(prev => ({
            ...prev,
            fileName: files[0].fileName,
            fileSrc: files[0].fileSrc,
            fileSrcSmall: files[0].fileSrcSmall,
            fileSrcMedium: files[0].fileSrcMedium
        }));
    };

    // Vložiť predpripravené fotky 
    const [avatarIndex, setAvatarIndex] = useState<number>(0);
    const nextAvatar = () => {
        if (avatars.length === 0 || avatars.length < avatarIndex) {
            return;
        }
        setSource(prev => ({
            ...prev,
            fileName: avatars[avatarIndex].fileName,
            fileSrc: avatars[avatarIndex].fileSrc,
            fileSrcSmall: avatars[avatarIndex].fileSrcSmall,
            fileSrcMedium: avatars[avatarIndex].fileSrcMedium
        }));
        setAvatarIndex(prev => (prev >= 9 ? 0 : prev + 1));
    };

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            setAccordionExpanded([]);
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        setSourcePasswordVerify('');
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'user/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as User);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Pracovné obdobie
    const handleAddWorkingPeriods = () => {
        setSource((prev) => {
            var next = ({ ...prev, workingPeriods: [...prev.workingPeriods ?? []] });
            next.workingPeriods.push({});
            return next;
        });
    };
    const handleChangeWorkingPeriods = (index: number, property: string, value: any) => {
        // Nemením hodnotu pri čiastočne zadanom dátume "Invalid Date" (kontrolovaný vstup by sa nedal prepísať)
        if (property === 'start' || property === 'end') {
            if (value !== null && isNaN(value)) {
                return;
            }
        }
        setSource((p) => {
            const changed = p.workingPeriods ?? [];
            changed[index] = { ...changed[index], [property]: value };
            return { ...p, workingPeriods: changed };
        });
    }
    const handleDeleteWorkingPeriods = (index: number) => {
        const workingPeriods = (source.workingPeriods ?? []);
        workingPeriods.splice(index, 1);
        handleChange('workingPeriods', workingPeriods);
    }

    // Pracovný čas
    const handleAddWorkingTimes = () => {
        setSource((prev) => {
            var next = ({ ...prev, workingTimes: [...prev.workingTimes ?? []] });
            next.workingTimes.push({ day: 0, startHour: 8, endHour: 17, priority: 0 });
            return next;
        });
    };
    const handleChangeWorkingTimes = (index: number, property: string, value: any) => {
        setSource((p) => {
            const changed = p.workingTimes ?? [];
            changed[index] = { ...changed[index], [property]: value };
            return { ...p, workingTimes: changed };
        });
    }
    const handleDeleteWorkingTimes = (index: number) => {
        const workingTimes = (source.workingTimes ?? []);
        workingTimes.splice(index, 1);
        handleChange('workingTimes', workingTimes);
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (source.name === '') {
            setWarning({ ...EmptyWarning, name: 'name', text: localization.mustFill + ': ' + localization.title + '!' });
            return;
        }
        if (source.email === '') {
            setWarning({ ...EmptyWarning, name: 'email', text: localization.mustFill + ': ' + localization.email + '!' });
            return;
        }

        if ((source.password?.length ?? 0) > 0) {
            const password = source.password ?? '';
            const passwordVerify = sourcePasswordVerify ?? '';
            if (password !== passwordVerify) {
                setWarning({ ...EmptyWarning, name: 'password', text: localization.passwordNotMatch });
                return;
            }
        }

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'user', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: localization.saveFailed });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => input?.focus(), 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="md" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ user: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((source?.id ?? 0) === 0 && localization.new + ': ')} {localization.user}
                        {(props.copy && ' (' + localization.copied + ')')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={3} mb={1}>
                                <FormControlLabel control={<Switch checked={source.actived} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label={localization.activeForSelection} />
                            </Grid>

                            <Grid item xs={9} mb={1}>
                                <FormControlLabel control={<Switch checked={source.admin} name="admin" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label={localization.administrator} />
                            </Grid>

                            <Grid item xs={12} md container columnSpacing={1}>

                                <Grid item xs={12} sm={9}>
                                    <TextField required margin="dense" name="name" label={localization.name} fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                        inputProps={{ maxLength: 255 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <EditIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>

                                <Grid item xs={12} sm={3}>
                                    <TextField required margin="dense" name="nameShort" label={localization.nameShort} fullWidth variant="outlined" autoComplete="off" value={source.nameShort ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => (props.autoFocus === 'nameShort' || props.autoFocus === undefined) && setFocus(input)}
                                        inputProps={{ maxLength: 50 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <EditIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField required margin="dense" name="email" type="email" label={localization.email} fullWidth variant="outlined" autoComplete="off" value={source.email ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => (props.autoFocus === 'email') && setFocus(input)}
                                        inputProps={{ maxLength: 255 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <EditIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField margin="dense" error={warning.name === 'password'} name="password" type="password" label={localization.password} fullWidth variant="outlined" autoComplete="new-password" value={source.password ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputProps={{ maxLength: 70 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <LockIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField margin="dense" error={warning.name === 'password'} name="passwordverify" type="password" label={localization.password} fullWidth variant="outlined" autoComplete="new-password" value={sourcePasswordVerify ?? ''} onChange={(e => { setSourcePasswordVerify(e.target.value); })}
                                        inputProps={{ maxLength: 70 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <LockIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3} container sx={{ ml: { sx: 0, md: 1 } }}>

                                <Grid item xs={12}>
                                    <Card sx={{ mt: 1 }}>
                                        {source?.fileName?.length ? (
                                            <CardMedia component="img" height="144" image={source?.fileSrcMedium ?? ''} />
                                        ) : (
                                            <CardMedia component="div" sx={{ display: 'flex', height: { xs: '100px', md: '144px' }, justifyContent: 'center', alignItems: 'center' }}>
                                                <PersonIcon sx={{ color: 'silver', fontSize: 100 }} />
                                            </CardMedia>
                                        )}
                                        <CardActions disableSpacing sx={{ padding: 1 }}>
                                            <FileUpload onUploaded={handleFileUpload} label={localization.insert} multiple={false} acceptedExtensions={['jpg', 'jpeg', 'png', 'gif', 'bmp']} />
                                            <IconButton aria-label="next" onClick={() => nextAvatar()} color="secondary" size="small">
                                                <ReplayIcon fontSize='small' />
                                            </IconButton>
                                            {(source?.fileName?.length ?? 0) > 0 &&
                                                <IconButton aria-label="delete" onClick={() => { setSource(prev => ({ ...prev, fileName: undefined })) }} color="secondary" size="small">
                                                    <DeleteOutlinedIcon fontSize='small' />
                                                </IconButton>
                                            }
                                        </CardActions>
                                    </Card>
                                </Grid>

                                <Grid item xs={12}>
                                </Grid>

                            </Grid>

                            <Grid item xs={12} mt={3} mb={2}>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('workingPeriod')} onChange={() => handleToggleAccordion('workingPeriod')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>{localization.workingPeriod} {(source.workingPeriods?.length ?? 0) > 0 ? ' (' + (source.workingPeriods?.length ?? 0) + ')' : ''}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {(source.workingPeriods?.length ?? 0) > 0 &&
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>{localization.start}</TableCell>
                                                        <TableCell>{localization.end}</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {source.workingPeriods?.map((item, index) => (
                                                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={GetDateLocale()}>
                                                                    <FormControl fullWidth>
                                                                        <DatePicker format='dd.MM.yyyy' value={NullMinDate(item.start)}
                                                                            onChange={(d) => { handleChangeWorkingPeriods(index, 'start', d as any) }}
                                                                            slotProps={{ textField: { size: 'small' } }} />
                                                                    </FormControl>
                                                                </LocalizationProvider>
                                                            </TableCell>
                                                            <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={GetDateLocale()}>
                                                                    <FormControl fullWidth>
                                                                        <DatePicker format='dd.MM.yyyy' minDate={NullMinDate(item.start)} value={NullMinDate(item.end)} onChange={(d) => { handleChangeWorkingPeriods(index, 'end', d as any) }} slotProps={{ textField: { size: 'small' } }} />
                                                                    </FormControl>
                                                                </LocalizationProvider>
                                                            </TableCell>
                                                            <TableCell style={{ padding: 0, width: '50px', textAlign: 'center' }}>
                                                                <IconButton aria-label="Delete" size="small" onClick={() => handleDeleteWorkingPeriods(index)}>
                                                                    <DeleteIcon fontSize="small" />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        }
                                        <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={() => handleAddWorkingPeriods()}>{localization.insertRow}</Button>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('workingTime')} onChange={() => handleToggleAccordion('workingTime')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>{localization.workingTime} {(source.workingTimes?.length ?? 0) > 0 ? ' (' + (source.workingTimes?.length ?? 0) + ')' : ''}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            {(source.workingTimes?.length ?? 0) > 0 &&
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ width: '20%' }}>{localization.day}</TableCell>
                                                            <TableCell>{localization.start}</TableCell>
                                                            <TableCell>{localization.end}</TableCell>
                                                            <TableCell>{localization.priority}</TableCell>
                                                            <TableCell></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {source.workingTimes?.map((item, index) => (
                                                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                                    <FormControl fullWidth size="small">
                                                                        <Select value={item.day ?? 0} name="day" onChange={(e) => { handleChangeWorkingTimes(index, e.target.name, e.target.value as any) }} sx={{ width: 240, background: daysColors[(item.day ?? 0)] ?? 'white' }}>
                                                                            <MenuItem value={0}>{localization.monday}</MenuItem>
                                                                            <MenuItem value={1}>{localization.tuesday}</MenuItem>
                                                                            <MenuItem value={2}>{localization.wednesday}</MenuItem>
                                                                            <MenuItem value={3}>{localization.thursday}</MenuItem>
                                                                            <MenuItem value={4}>{localization.friday}</MenuItem>
                                                                            <MenuItem value={5}>{localization.saturday}</MenuItem>
                                                                            <MenuItem value={6}>{localization.sunday}</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </TableCell>
                                                                <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                                    <FormControl size="small" style={{ width: '49%', marginRight: '2%' }}>
                                                                        <Select value={item.startHour ?? 0} name="startHour" onChange={(e) => { handleChangeWorkingTimes(index, e.target.name, ConvertToInt(e.target.value ?? '0')) }} sx={{ background: '#e0eeef' }}>
                                                                            {Array.from({ length: 24 }, (_, i) => (
                                                                                <MenuItem key={i} value={i}>{i.toString().padStart(2, '0')}</MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                    <FormControl size="small" style={{ width: '49%' }}>
                                                                        <Select value={item.startMinute ?? 0} name="startMinute" onChange={(e) => { handleChangeWorkingTimes(index, e.target.name, ConvertToInt(e.target.value ?? '0')) }} sx={{ background: '#e0eeef' }}>
                                                                            {Array.from({ length: 12 }, (_, i) => (
                                                                                <MenuItem key={i} value={(i * 5)}>{(i * 5).toString().padStart(2, '0')}</MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </TableCell>
                                                                <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                                    <FormControl size="small" style={{ width: '49%', marginRight: '2%' }}>
                                                                        <Select value={item.endHour ?? 0} name="endHour" onChange={(e) => { handleChangeWorkingTimes(index, e.target.name, ConvertToInt(e.target.value ?? '0')) }} sx={{ background: '#efe6e6' }}>
                                                                            {Array.from({ length: 25 }, (_, i) => (
                                                                                <MenuItem disabled={(i + 1) <= (item.startHour ?? 0)} key={i} value={i}>{i.toString().padStart(2, '0')}</MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                    <FormControl size="small" style={{ width: '49%' }}>
                                                                        <Select value={item.endMinute ?? 0} name="endMinute" onChange={(e) => { handleChangeWorkingTimes(index, e.target.name, ConvertToInt(e.target.value ?? '0')) }} sx={{ background: '#efe6e6' }}>
                                                                            {Array.from({ length: 12 }, (_, i) => (
                                                                                <MenuItem key={i} value={(i * 5)}>{(i * 5).toString().padStart(2, '0')}</MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </TableCell>
                                                                <TableCell sx={{ p: 0.5, pl: 0, width: '150px' }}>
                                                                    <FormControl fullWidth size="small">
                                                                        <TextField size='small' name="priority" type="number" variant="outlined" value={item.priority ?? ''} onChange={(e) => { handleChangeWorkingTimes(index, e.target.name, ConvertToInt(e.target.value)) }}
                                                                            inputProps={{ min: 0, max: 255 }}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end">
                                                                                        <SortIcon />
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }} />
                                                                    </FormControl>
                                                                </TableCell>
                                                                <TableCell style={{ padding: 0, width: '50px', textAlign: 'center' }}>
                                                                    <IconButton aria-label="Delete" size="small" onClick={() => handleDeleteWorkingTimes(index)}>
                                                                        <DeleteIcon fontSize="small" />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            }
                                            <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={() => handleAddWorkingTimes()}>{localization.insertRow}</Button>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('note')} onChange={() => handleToggleAccordion('note')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>{localization.note} {(source.note?.length ?? 0) > 0 ? ' (' + localization.yes.toLowerCase() + ')' : ''}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12}>
                                                <TextField fullWidth multiline rows={5} margin="dense" name="note" label={localization.note} variant="outlined" value={source.note ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputProps={{ maxLength: 1000 }}
                                                    inputRef={input => { props.autoFocus === 'note' && setFocus(input) }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EditIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                            </Grid>

                            {(source.actived ?? false) === false && <>
                                <Grid item xs={12} mt={1}>
                                    <Alert severity='info'>
                                        {localization.userDeactivatedInfo}
                                    </Alert>
                                </Grid>
                            </>}

                            {(source.admin ?? false) === false && <>
                                <Grid item xs={12} mt={1}>
                                    <Alert severity='info'>
                                        {localization.userNoAdminInfo}
                                    </Alert>
                                </Grid>
                            </>}

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.user as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>{localization.reset}</Button>
                        <Button disabled={loading} onClick={props.onClose}>{localization.back}</Button>
                        <Button disabled={loading} type="submit" variant="contained">{localization.save}</Button>
                    </DialogActions>
                </Box>
            </Dialog >
        </>
    )
}

export default UserCreate;