import React from 'react';
import { useEffect, useState } from 'react';
import { Localize } from '../../AppLocalization';

// Utility
import { FormatCurrency } from '../../utility/Format';
import Debounce from '../../utility/Debounce';

// Modely
import { InvoiceItem } from '../../models/Models';

// Komponenty
import { Typography } from '@mui/material';

interface SummaryItem {
    title: string,
    value: number
}

interface InvoiceCreateSummaryProps {
    items?: InvoiceItem[];
}

const InvoiceCreateSummary = (props: InvoiceCreateSummaryProps) => {

    // Všeobecne
    const localization = Localize();

    // Stav
    const [summary, setSummary] = useState<SummaryItem[]>([]);

    // Funkcie pre distinct filter a zaokruhlenie
    const onlyUnique = (value: any, index: number, array: any[]): boolean => array.indexOf(value) === index;
    const mathRound2 = (value: number) => Math.round(value * 100) / 100;

    // Prepočet sumáru
    const computeSummary = Debounce((items: InvoiceItem[]) => {

        let summaryPriceNoVat = 0;
        let summaryPriceWithVat = 0;
        let summaryTaxes: SummaryItem[] = [];

        // Pokračujem len ak existujú nejaké položky
        if (items.length) {

            // Získam unikátne sadzby DPH (0 = default)
            let vatRates = items.map(item => (item.priceVatRate ?? 0))?.filter(onlyUnique) ?? [0];

            // Zoradím a postupne prejdem jednotlivé sadzby
            vatRates.sort((a, b) => a < b ? 1 : -1).forEach(vatRate => {

                // Získam základ dane v danej sadzbe a vypočítam cenu s DPH z ktorej následne získam výšku dane
                let vatRatePriceNoVat = (items.filter(item => (item.priceVatRate ?? 0) === vatRate)?.reduce((sum, current) => sum + (current?.price ?? 0), 0)) ?? 0;
                let vatRatePriceWithVat = mathRound2((vatRatePriceNoVat ?? 0) * ((100 + vatRate) / 100));
                let vatRateTax = mathRound2(vatRatePriceWithVat - vatRatePriceNoVat);

                // Pripočítam k celkovému sumáru
                summaryPriceNoVat += vatRatePriceNoVat;
                summaryPriceWithVat += vatRatePriceWithVat;

                // Doplním do zoznamu daní (ak nie je nulová)
                if (vatRateTax > 0) {
                    summaryTaxes.push({
                        title: localization.tax + ' ' + vatRate + '%',
                        value: vatRateTax
                    });
                }
            });
        }

        // Vyplním sumár
        if (summaryTaxes.length > 0) {
            setSummary([
                { title: localization.subtotal, value: summaryPriceNoVat },
                ...summaryTaxes,
                { title: localization.total, value: summaryPriceWithVat },
            ]);
        }
        else {
            setSummary([
                { title: localization.total, value: summaryPriceWithVat },
            ]);
        }

    }, 250);

    // Pri zmene v položkách automaticky získam sumár
    useEffect(() => {
        computeSummary([...props.items ?? []]);
    }, [props.items]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {summary.map((item, index) => (
                <Typography key={index} mt={index === 0 ? 2 : 0} pr={2} variant={(index + 1) < summary.length ? 'body1' : 'h6'} component="div" sx={{ textAlign: 'right' }}>
                    {item.title}: <strong>{FormatCurrency((item.value ?? 0), { surfix: ' EUR' }, 2)}</strong>
                </Typography>
            ))}
        </>
    )
}

export default InvoiceCreateSummary;