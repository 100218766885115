import { FormEvent, useEffect, useState } from 'react';

// Utility
import skLocale from 'date-fns/locale/sk';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr';
import { GetDateLocale, GetLanguage, Localize } from '../../AppLocalization';
import { NullMinDate } from '../../utility/Date';

// Modely
import { Breed, BreedingStation, ProductionFilter as ProductionsFilter } from '../../models/Models';

// Komponenty
import { Alert, AlertColor, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Ikony
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Vstupné parametre
export interface ProductionsFilteringProps {
    open: boolean;
    filter?: ProductionsFilter;
    breeds: Breed[];
    breedingStations: BreedingStation[];
    autoFocus?: string;
    keepMounted?: boolean;
    onSave: (filter: ProductionsFilter) => void;
    onClose: () => void;
}

// Prázdny filter
const EmptyFilter: ProductionsFilter = {
    page: 0,
    pageSize: 25,
    sort: '',
    sortAsc: false
}

const ProductionsFiltering = (props: ProductionsFilteringProps) => {

    // Všobecne
    const localization = Localize();

    // Stav
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<ProductionsFilter>({ ...EmptyFilter });

    // Automaticky naplním zdroj
    useEffect(() => {
        setSource(props.filter ?? EmptyFilter);
    }, [props.filter, props.open]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Reset formuláru (resetujem iba tie hodnoty, ktoré viem upraviť cez tento formulár)
    const handleReset = () => {
        setSource(prev => ({
            page: 0,
            pageSize: prev.pageSize,
            sort: prev.sort,
            sortAsc: prev.sortAsc
        }))
    };

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        props.onSave(source);
        props.onClose();
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {localization.filter}
                    </DialogTitle>
                    <DialogContent sx={{ maxHeight: '75vh' }}>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} sm={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="production-breed">{localization.breed}</InputLabel>
                                    <Select labelId="production-breed" label={localization.breed} value={source.breedId ?? 0} name="breedId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={(input: any) => { props.autoFocus === 'breedId' && setFocus(input) }}>
                                        <MenuItem key={0} value={0}>{localization.all}</MenuItem>
                                        {props.breeds?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField margin="dense" name="color" label={localization.color} fullWidth variant="outlined" autoComplete="off" value={source.color ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={(input: any) => (props.autoFocus === 'color') && setFocus(input)}
                                    inputProps={{ maxLength: 50 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <FormatColorFillIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>


                            <Grid item xs={12} sm={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="production-breedingstation">{localization.breedingStation}</InputLabel>
                                    <Select labelId="production-breedingstation" label={localization.breedingStation} value={source.breedingStationId ?? 0} name="breedingStationId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => { (props.autoFocus === 'breedingStationId' || props.autoFocus === 'breedingStation') && setFocus(input) }}>
                                        <MenuItem key={0} value={0}>{localization.all}</MenuItem>
                                        {props.breedingStations?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="production-gender">{localization.gender}</InputLabel>
                                    <Select labelId="production-gender" label={localization.gender} value={source.genderMale ?? 0} name="genderMale" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => { (props.autoFocus === 'genderMale' || props.autoFocus === 'gender') && setFocus(input) }}>
                                        <MenuItem value={0}>{localization.all}</MenuItem>
                                        <MenuItem value={1}>{localization.male}</MenuItem>
                                        <MenuItem value={2}>{localization.female}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>


                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={GetDateLocale()}>
                                    <FormControl margin="dense" fullWidth>
                                        <DatePicker format='dd.MM.yyyy' label={localization.production + ' (' + localization.from.toLowerCase() + ')'} value={NullMinDate(source.countsFrom)} onChange={(d) => { handleChange('countsFrom', d) }} inputRef={input => { props.autoFocus === 'countsFrom' && setFocus(input) }} />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={GetDateLocale()}>
                                    <FormControl margin="dense" fullWidth>
                                        <DatePicker format='dd.MM.yyyy' label={localization.production + ' (' + localization.to.toLowerCase() + ')'} value={NullMinDate(source.countsTo)} onChange={(d) => { handleChange('countsTo', d) }} inputRef={input => { props.autoFocus === 'countsTo' && setFocus(input) }} />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>


                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={GetDateLocale()}>
                                    <FormControl margin="dense" fullWidth>
                                        <DatePicker format='dd.MM.yyyy' label={localization.dateOfBirth + ' (' + localization.from.toLowerCase() + ')'} value={NullMinDate(source.dateOfBirthFrom)} onChange={(d) => { handleChange('dateOfBirthFrom', d) }} inputRef={input => { props.autoFocus === 'dateOfBirthFrom' && setFocus(input) }} />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={GetDateLocale()}>
                                    <FormControl margin="dense" fullWidth>
                                        <DatePicker format='dd.MM.yyyy' label={localization.dateOfBirth + ' (' + localization.to.toLowerCase() + ')'} value={NullMinDate(source.dateOfBirthTo)} onChange={(d) => { handleChange('dateOfBirthTo', d) }} inputRef={input => { props.autoFocus === 'dateOfBirthTo' && setFocus(input) }} />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>


                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={GetDateLocale()}>
                                    <FormControl margin="dense" fullWidth>
                                        <DatePicker format='dd.MM.yyyy' label={localization.dateOfEntry + ' (' + localization.from.toLowerCase() + ')'} value={NullMinDate(source.dateOfEntryFrom)} onChange={(d) => { handleChange('dateOfEntryFrom', d) }} inputRef={input => { props.autoFocus === 'dateOfEntryFrom' && setFocus(input) }} />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={GetDateLocale()}>
                                    <FormControl margin="dense" fullWidth>
                                        <DatePicker format='dd.MM.yyyy' label={localization.dateOfEntry + ' (' + localization.to.toLowerCase() + ')'} value={NullMinDate(source.dateOfEntryTo)} onChange={(d) => { handleChange('dateOfEntryTo', d) }} inputRef={input => { props.autoFocus === 'dateOfEntryTo' && setFocus(input) }} />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>


                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={GetDateLocale()}>
                                    <FormControl margin="dense" fullWidth>
                                        <DatePicker format='dd.MM.yyyy' label={localization.dateOfExit + ' (' + localization.from.toLowerCase() + ')'} value={NullMinDate(source.dateOfExitFrom)} onChange={(d) => { handleChange('dateOfExitFrom', d) }} inputRef={input => { props.autoFocus === 'dateOfExitFrom' && setFocus(input) }} />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={GetDateLocale()}>
                                    <FormControl margin="dense" fullWidth>
                                        <DatePicker format='dd.MM.yyyy' label={localization.dateOfExit + ' (' + localization.to.toLowerCase() + ')'} value={NullMinDate(source.dateOfExitTo)} onChange={(d) => { handleChange('dateOfExitTo', d) }} inputRef={input => { props.autoFocus === 'dateOfExitTo' && setFocus(input) }} />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>


                            {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleReset} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>{localization.reset}</Button>
                        <Button onClick={props.onClose}>{localization.back}</Button>
                        <Button type="submit" variant="contained">{localization.use}</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default ProductionsFiltering;