import { AppConfig } from './AppConfig';
import { LoadLocalSettings, SaveLocalSettings } from './utility/LocalSettings';
import skLocale from 'date-fns/locale/sk';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr';

// Vráti aktuálne nastavený jazyk (default podľa AppConfig)
export function GetLanguage(): string {
    return LoadLocalSettings('language', AppConfig.Language);
}

export function GetDateLocale(): Locale {
    var locale =
        GetLanguage() === 'en' ? enLocale :
            GetLanguage() === 'fr' ? frLocale :
                skLocale;
    return ({ ...locale, options: { ...locale.options, weekStartsOn: 1 } });
};

// Uloží vybraný jazyk a obnoví stránku
export function SetLanguage(language: string) {
    if (SaveLocalSettings('language', language, AppConfig.Language)) {
        window.location.reload();
    }
}

// Podporované jazyky
export enum LocalizationLanguage {
    sk = 0,
    en = 1,
    fr = 2
}

// Predpis pre lokalizáciu
export interface Localization {
    ok: string;
    yes: string;
    no: string;
    save: string;
    saved: string;
    saveAs: string;
    saveFailed: string;
    cancel: string;
    reset: string;
    back: string;
    newRecord: string;
    activities: string;
    tasks: string;
    projects: string;
    productions: string;
    invoices: string;
    addressBook: string;
    users: string;
    breeds: string;
    projectStates: string;
    projectTypes: string;
    taskTemplates: string;
    taskStates: string;
    taskTypes: string;
    countries: string;
    activity: string;
    task: string;
    forTask: string;
    forProject: string;
    project: string;
    production: string;
    invoice: string;
    breed: string;
    projectState: string;
    projectType: string;
    taskTemplate: string;
    taskState: string;
    taskType: string;
    settings: string;
    enterKeyword: string;
    search: string;
    clean: string;
    created: string;
    updated: string;
    options: string;
    create: string;
    edit: string;
    update: string;
    copy: string;
    copied: string;
    delete: string;
    deleteConfirmation: string;
    deleteSeletedConfirmation: string;
    selected: string;
    selectAll: string;
    cancelSelection: string;
    mustFill: string;
    mustFileRequiredFields: string;
    textIsTooShort: string;
    title: string;
    name: string;
    nameShort: string;
    new: string;
    compact: string;
    normal: string;
    ordinalNumber: string;
    note: string;
    year: string;
    color: string;
    country: string;
    customer: string;
    address: string;
    email: string;
    phone: string;
    call: string;
    dateOfBirth: string;
    files: string;
    owner: string;
    notes: string;
    insert: string;
    insertFile: string;
    fileUploadFailed: string;
    couldNotBeUpload: string;
    maxFileSizeIs: string;
    acceptedFileExtensionsAre: string;
    prepareFiles: string;
    uploading: string;
    file: string;
    open: string;
    date: string;
    Ending: string;
    result: string;
    preview: string;
    numberOfBirths: string;
    numberOfChipped: string;
    print: string;
    areYouSure: string;
    size: string;
    defaultSettings: string;
    user: string;
    userDeactivatedInfo: string;
    userNoAdminInfo: string;
    password: string;
    passwordAgain: string;
    passwordNotMatch: string;
    login: string;
    logout: string;
    rememberMe: string;
    doYouForgotPassword: string;
    forgotPassword: string;
    forgotPasswordConfirmSent: string;
    wrongLogin: string;
    loginBlocked: string;
    send: string;
    emailSent: string;
    emailNotSent: string;
    changePassword: string;
    changeLanguage: string;
    city: string;
    active: string;
    activeForSelection: string;
    administrator: string;
    avatar: string;
    highest: string;
    lowest: string;
    priority: string;
    description: string;
    reminder: string;
    reminderTime: string;
    reminderText: string;
    start: string;
    end: string;
    datePlusDays: string;
    startPlusHours: string;
    deadline: string;
    number: string;
    chipNumber: string;
    chipNumberAllowAllCharacters: string;
    dateOfEntry: string;
    dateOfExit: string;
    chipped: string;
    born: string;
    insertRow: string;
    alright: string;
    verifying: string;
    exists: string;
    all: string;
    from: string;
    to: string;
    filter: string;
    use: string;
    supplier: string;
    street: string;
    houseNumber: string;
    postcode: string;
    regId: string;
    taxId: string;
    vatId: string;
    contact: string;
    price: string;
    subject: string;
    automatically: string;
    item: string;
    subtotal: string;
    total: string;
    withVat: string;
    tax: string;
    vatRate: string;
    select: string;
    detail: string;
    cancelTheLink: string;
    confirmSelection: string;
    calendar: string;
    history: string;
    noRecords: string;
    saveAndContinue: string;
    saveAndNew: string;
    saveAndNewFromCopy: string;
    onlyForSavedRecords: string;
    projectTemplatesInfoSave: string;
    projectTemplatesInfoNotFound: string;
    today: string;
    lastNDays: string;
    refresh: string;
    activityTaskCreated: string;
    activityTaskUpdated: string;
    activityTaskStateUpdated: string;
    activityTaskNoteCreated: string;
    activityTaskNoteUpdated: string;
    noTasksForToday: string;
    noActivities: string;
    wait: string;
    breedingStation: string;
    breedingStations: string;
    male: string;
    female: string;
    gender: string;
    workingPeriod: string;
    workingTime: string;
    day: string;
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
    sunday: string;
    schedule: string;
    scheduleModeGenerateMissing: string;
    scheduleModeGenerateMissingInfo: string;
    scheduleModeGenerateAll: string;
    scheduleModeGenerateAllInfo: string;
    unknownFail: string;
    scheduleCalculation: string;
    scheduleRendering: string;
}

// Vyplnená jazyková mutácia  s informáciou o použitom jazyku
let localization: Localization | null = null;
let localizationLanguage: LocalizationLanguage | null = null;

export function Localize(): Localization {

    // Predvolený jazyk (ak by sa podľa názvu nenašiel)
    let language: LocalizationLanguage = LocalizationLanguage.en;

    // Nastavený jazyk
    let languageName = GetLanguage();

    // Jazyk podľa nastavení, ak je podporovaný
    if (Object.values(LocalizationLanguage).includes(languageName)) {
        language = LocalizationLanguage[languageName as keyof typeof LocalizationLanguage];
    }

    // Ak je už vyplnená daná jazyková mutácia, tak ju vrátim
    if (localization !== null && localizationLanguage === language) {
        return localization;
    }

    // Vyplním jazykovú mutáciu a vrátim
    localizationLanguage = language;
    localization = {
        ok: [
            'Ok',
            'Ok',
            'Ok'][localizationLanguage],
        yes: [
            'Áno',
            'Yes',
            'Oui'][localizationLanguage],
        no: [
            'Nie',
            'No',
            'Non'][localizationLanguage],
        reset: [
            'Reset',
            'Reset',
            'Réinitialiser'][localizationLanguage],
        save: [
            'Uložiť',
            'Save',
            'Sauvegarder'][localizationLanguage],
        saved: [
            'Uložené!',
            'Saved!',
            'Sauvegardé!'][localizationLanguage],
        saveAs: [
            'Uložiť ako',
            'Save as',
            'Enregistrer sous'][localizationLanguage],
        saveFailed: [
            'Záznam sa nepodarilo uložiť!',
            'Failed to save record!',
            'Échec de la sauvegarde de l\'enregistrement !'][localizationLanguage],
        cancel: [
            'Zrušiť',
            'Cancel',
            'Annuler'][localizationLanguage],
        back: [
            'Späť',
            'Back',
            'Retour'][localizationLanguage],
        newRecord: [
            'Nový záznam',
            'New record',
            'Ajouter'][localizationLanguage],
        activities: [
            'Aktivity',
            'Activity',
            'Activité'][localizationLanguage],
        tasks: [
            'Úlohy',
            'Tasks',
            'Tâches'][localizationLanguage],
        projects: [
            'Projekty',
            'Projects',
            'Projets'][localizationLanguage],
        productions: [
            'Produkcia',
            'Production',
            'Production'][localizationLanguage],
        invoices: [
            'Faktúry',
            'Invoices',
            'Factures'][localizationLanguage],
        addressBook: [
            'Adresár',
            'Address book',
            'Carnet d\'adresses'][localizationLanguage],
        users: [
            'Užívatelia',
            'Users',
            'Utilisateurs'][localizationLanguage],
        breeds: [
            'Plemená',
            'Breeds',
            'Races'][localizationLanguage],
        projectStates: [
            'Stavy projektov',
            'Projects states',
            'Statuts du projet'][localizationLanguage],
        projectTypes: [
            'Typy projektov',
            'Projects types',
            'Types de projets'][localizationLanguage],
        taskTemplates: [
            'Šablóny úloh',
            'Tasks templates',
            'Modèles de tâches'][localizationLanguage],
        taskStates: [
            'Stavy úloh',
            'Tasks states',
            'Statuts de travail'][localizationLanguage],
        taskTypes: [
            'Typy úloh',
            'Tasks types',
            'Types de tâches'][localizationLanguage],
        countries: [
            'Krajiny',
            'Countries',
            'Pays'][localizationLanguage],
        settings: [
            'Nastavenia',
            'Settings',
            'Paramètres'][localizationLanguage],
        enterKeyword: [
            'Vložte kľúčové slovo',
            'Enter a keyword',
            'Entrez un mot-clé'][localizationLanguage],
        search: [
            'Hľadať',
            'Search',
            'Chercher'][localizationLanguage],
        clean: [
            'Vyčistiť',
            'Clean',
            'Nettoyer'][localizationLanguage],
        created: [
            'Vytvorené',
            'Created',
            'Établi'][localizationLanguage],
        updated: [
            'Upravené',
            'Updated',
            'Ajusté'][localizationLanguage],
        options: [
            'Možnosti',
            'Options',
            'Choix'][localizationLanguage],
        create: [
            'Vytvoriť',
            'Create',
            'Créer'][localizationLanguage],
        edit: [
            'Upraviť',
            'Edit',
            'Modifier'][localizationLanguage],
        update: [
            'Aktualizovať',
            'Update',
            'Ajuster'][localizationLanguage],
        copy: [
            'Kopírovať',
            'Copy',
            'Copie'][localizationLanguage],
        copied: [
            'Kópia',
            'Copied',
            'Copié'][localizationLanguage],
        delete: [
            'Vymazať',
            'Delete',
            'Supprimer'][localizationLanguage],
        deleteConfirmation: [
            'Skutočne chcete vymazať tento záznam?',
            'Are you sure you want to delete this record?',
            'Voulez-vous vraiment supprimer cette entrée ?'][localizationLanguage],
        deleteSeletedConfirmation: [
            'Skutočne chcete vymazať vybrané záznamy?',
            'Are you sure you want to delete the selected records?',
            'Voulez-vous vraiment supprimer les enregistrements sélectionnés ?'][localizationLanguage],
        selected: [
            'Vybrané',
            'Selected',
            'Choisi'][localizationLanguage],
        selectAll: [
            'Označiť všetko',
            'Select all',
            'Marquer tout'][localizationLanguage],
        cancelSelection: [
            'Zrušiť výber',
            'Cancel selection',
            'Annuler la sélection'][localizationLanguage],
        mustFill: [
            'Musíte vyplniť',
            'You must fill',
            'Vous devez renseigner'][localizationLanguage],
        textIsTooShort: [
            'Text je príliš krátky',
            'The text is too short',
            'Le texte est trop court'][localizationLanguage],
        title: [
            'Názov',
            'Title',
            'Titre'][localizationLanguage],
        name: [
            'Meno',
            'Name',
            'Nom'][localizationLanguage],
        nameShort: [
            'Skrátené meno',
            'Abbreviated name',
            'Nom abrégé'][localizationLanguage],
        new: [
            'Nový',
            'New',
            'Nouveau'][localizationLanguage],
        compact: [
            'Kompaktné',
            'Compact',
            'Compact'][localizationLanguage],
        normal: [
            'Normálne',
            'Normal',
            'Normal'][localizationLanguage],
        ordinalNumber: [
            'Poradové číslo',
            'Ordinal number',
            'Numéro de série'][localizationLanguage],
        note: [
            'Poznámka',
            'Note',
            'Noter'][localizationLanguage],
        year: [
            'Rok',
            'Year',
            'Année'][localizationLanguage],
        color: [
            'Farba',
            'Color',
            'Robe'][localizationLanguage],
        country: [
            'Krajina',
            'Country',
            'Pays'][localizationLanguage],
        customer: [
            'Zákazník',
            'Customer',
            'Consommateur'][localizationLanguage],
        address: [
            'Adresa',
            'Address',
            'Adresse'][localizationLanguage],
        email: [
            'E-mail',
            'E-mail',
            'E-mail'][localizationLanguage],
        phone: [
            'Telefón',
            'Phone',
            'Téléphoner'][localizationLanguage],
        call: [
            'Volať',
            'Call',
            'Appeler'][localizationLanguage],
        dateOfBirth: [
            'Dátum narodenia',
            'Date of birth',
            'Date de naissance'][localizationLanguage],
        files: [
            'Súbory',
            'Files',
            'Documents'][localizationLanguage],
        owner: [
            'Vlastník',
            'Owner',
            'Propriétaire'][localizationLanguage],
        notes: [
            'Poznámky',
            'Notes',
            'Remarques'][localizationLanguage],
        insert: [
            'Vložiť',
            'Insert',
            'Insérer'][localizationLanguage],
        insertFile: [
            'Vložiť súbor',
            'Insert file',
            'Insérer un fichier'][localizationLanguage],
        fileUploadFailed: [
            'Chyba nahrávania',
            'Upload failed',
            'Le téléchargement a échoué'][localizationLanguage],
        couldNotBeUpload: [
            'Súbor nebolo možné nahrať',
            'The file could not be uploaded',
            'Le fichier n\'a pas pu être téléchargé'][localizationLanguage],
        maxFileSizeIs: [
            'Maximálna veľkosť súboru je: ',
            'Maximum file size is: ',
            'La taille maximale du fichier est: '][localizationLanguage],
        acceptedFileExtensionsAre: [
            'Povolené prípony súborov sú: ',
            'Allowed file extensions are: ',
            'Les extensions de fichiers autorisées sont: '][localizationLanguage],
        prepareFiles: [
            'Príprava súborov',
            'Preparing the files',
            'Préparation des fichiers'][localizationLanguage],
        uploading: [
            'Ukladám',
            'Saving',
            'Enregistrement'][localizationLanguage],
        file: [
            'Súbor',
            'File',
            'Le fichier'][localizationLanguage],
        open: [
            'Otvoriť',
            'Open',
            'Ouvrir'][localizationLanguage],
        date: [
            'Dátum',
            'Date',
            'Date'][localizationLanguage],
        Ending: [
            'Ukončenie',
            'Ending',
            'Fin'][localizationLanguage],
        result: [
            'Výsledok',
            'Result',
            'Résultat'][localizationLanguage],
        preview: [
            'Náhľad',
            'Preview',
            'Photo'][localizationLanguage],
        numberOfBirths: [
            'Počet narodení',
            'Number of births',
            'Nombre de naissances'][localizationLanguage],
        numberOfChipped: [
            'Počet začipovaných',
            'Number of chipped',
            'Nombre de puces'][localizationLanguage],
        print: [
            'Vytlačiť',
            'Print',
            'Imprimer'][localizationLanguage],
        areYouSure: [
            'Skutočne ste si istý?',
            'Are you really sure?',
            'Êtes-vous vraiment sûr?'][localizationLanguage],
        size: [
            'Veľkosť',
            'Size',
            'Taille'][localizationLanguage],
        defaultSettings: [
            'Predvolené nastavenia',
            'Default settings',
            'Paramètres par défaut'][localizationLanguage],
        user: [
            'Užívateľ',
            'User',
            'Utilisateur'][localizationLanguage],
        userDeactivatedInfo: [
            'Neaktívny užívateľ pre výber sa nezobrazuje v zoznamoch pre výber, ale naďalej sa môže prihlasovať do systému. Pre zablokovanie prihlásenia je potrebné účet vymazať, alebo nastaviť neexistujúci prihlasovací email.',
            'An inactive user for selection does not appear in the selection lists, but can still log in to the system. To block the login, it is necessary to delete the account or set a non-existent login email.',
            'Un utilisateur inactif pour la sélection n\'apparaît pas dans les listes de sélection, mais peut toujours se connecter au système. Pour bloquer la connexion, il est nécessaire de supprimer le compte ou de définir un email de connexion inexistant.'][localizationLanguage],
        userNoAdminInfo: [
            'Bežný užívateľ nemá prístup do sekcie nastavenia a môže vidieť, upraviť alebo vymazať iba záznamy, ktoré vytvoril (okrem adresára).',
            'A regular user does not have access to the settings section and can only see, edit or delete the records that he has created (except for the directory).',
            'Un utilisateur régulier n\'a pas accès à la section des paramètres et ne peut voir, modifier ou supprimer que les enregistrements qu\'il a créés (à l\'exception du répertoire).'][localizationLanguage],
        password: [
            'Heslo',
            'Password',
            'Mot de passe'][localizationLanguage],
        passwordAgain: [
            'Heslo znovu',
            'Password again',
            'Mot de passe à nouveau'][localizationLanguage],
        passwordNotMatch: [
            'Heslá sa nezhodujú!',
            'Passwords do not match!',
            'Les mots de passe ne correspondent pas!'][localizationLanguage],
        login: [
            'Prihlásiť',
            'Login',
            'Connectez-vous'][localizationLanguage],
        logout: [
            'Odhlásiť',
            'Logout',
            'Déconnexion'][localizationLanguage],
        rememberMe: [
            'Zostať trvale prihlásený',
            'Stay permanently logged in',
            'Restez connecté en permanence'][localizationLanguage],
        doYouForgotPassword: [
            'Zabudli ste heslo?',
            'Forgot your password?',
            'Mot de passe oublié?'][localizationLanguage],
        forgotPassword: [
            'Zabudnuté heslo',
            'Forgot password',
            'Mot de passe oublié'][localizationLanguage],
        forgotPasswordConfirmSent: [
            'Na Vašu emailovú adresu bude odoslaný overovací email, pomocou ktorého bude možné vygenerovať nové heslo.',
            'A verification email will be sent to your email address, with which a new password can be generated.',
            'Un e-mail de vérification sera envoyé à votre adresse e-mail, avec lequel un nouveau mot de passe peut être généré.'][localizationLanguage],
        wrongLogin: [
            'Nesprávne prístupové údaje!',
            'Incorrect access data!',
            'Données d\'accès erronées!'][localizationLanguage],
        loginBlocked: [
            'Prihlásenie je na niekoľko minút zablokované!',
            'Login is blocked for a few minutes!',
            'La connexion est bloquée pendant quelques minutes!'][localizationLanguage],
        send: [
            'Odoslať',
            'Send',
            'Envoyer'][localizationLanguage],
        emailSent: [
            'Email bol úspešne odoslaný!',
            'The email was successfully sent!',
            'L\'e-mail a été envoyé avec succès !'][localizationLanguage],
        emailNotSent: [
            'Email sa nepodarilo odoslať!',
            'Email could not be sent!',
            'Le mail n\'a pas pu être envoyé!'][localizationLanguage],
        changePassword: [
            'Zmeniť heslo',
            'Change password',
            'Changer le mot de passe'][localizationLanguage],
        changeLanguage: [
            'Zmeniť jazyk',
            'Change langauge',
            'Changer de langue'][localizationLanguage],
        city: [
            'Mesto',
            'City',
            'La cité'][localizationLanguage],
        active: [
            'Aktívne',
            'Active',
            'Actif'][localizationLanguage],
        activeForSelection: [
            'Aktívne pre výber',
            'Active for selection',
            'Actif pour la sélection'][localizationLanguage],
        administrator: [
            'Administrátor',
            'Administrator',
            'Administrateur'][localizationLanguage],
        avatar: [
            'Avatar',
            'Avatar',
            'Avatar'][localizationLanguage],
        highest: [
            'Najvyššia',
            'Highest',
            'Le plus haut'][localizationLanguage],
        lowest: [
            'Najnižšia',
            'Lowest',
            'Le plus bas'][localizationLanguage],
        priority: [
            'Priorita',
            'Priority',
            'Priorité'][localizationLanguage],

        activity: [
            'Aktivita',
            'Activity',
            'Activité'][localizationLanguage],
        task: [
            'Úloha',
            'Task',
            'Tâche'][localizationLanguage],
        forTask: [
            'Pre úlohu',
            'For task',
            'Pour tâche'][localizationLanguage],
        forProject: [
            'Pre projekt',
            'For project',
            'Pour projet'][localizationLanguage],
        project: [
            'Projekt',
            'Project',
            'Projet'][localizationLanguage],
        production: [
            'Produkcia',
            'Production',
            'Production'][localizationLanguage],
        invoice: [
            'Faktúra',
            'Invoice',
            'Facture'][localizationLanguage],
        breed: [
            'Plemeno',
            'Breed',
            'Race'][localizationLanguage],
        projectState: [
            'Stav projektu',
            'Project state',
            'État du projet'][localizationLanguage],
        projectType: [
            'Typ projektu',
            'Project type',
            'Type de projet'][localizationLanguage],
        taskTemplate: [
            'Šablóna úlohy',
            'Task template',
            'Modèle de tâche'][localizationLanguage],
        taskState: [
            'Stav úlohy',
            'Task state',
            'État de la tâche'][localizationLanguage],
        taskType: [
            'Typ úlohy',
            'Task type',
            'Type de tâche'][localizationLanguage],
        description: [
            'Popis',
            'Description',
            'Description'][localizationLanguage],
        reminder: [
            'Pripomienka',
            'Reminder',
            'Rappel'][localizationLanguage],
        reminderTime: [
            'Čas pripomienky',
            'Reminder time',
            'Heure de rappel'][localizationLanguage],
        reminderText: [
            'Text pripomienky',
            'Reminder text',
            'Texte de rappel'][localizationLanguage],
        start: [
            'Začiatok',
            'Start',
            'Début'][localizationLanguage],
        end: [
            'Koniec',
            'End',
            'Fin'][localizationLanguage],
        datePlusDays: [
            'Dátum + dni',
            'Date + days',
            'Date + jours'][localizationLanguage],
        startPlusHours: [
            'Začiatok + hodiny',
            'Start + hours',
            'Début + heures'][localizationLanguage],
        deadline: [
            'Konečný termín',
            'Deadline',
            'Date limite'][localizationLanguage],
        number: [
            'Číslo',
            'Number',
            'Nombre'][localizationLanguage],
        chipNumber: [
            'Číslo čipu',
            'Chip number',
            'Numéro de puce'][localizationLanguage],
        chipNumberAllowAllCharacters: [
            'Číslo čipu - povoliť všetky znaky',
            'Chip number - allow all characters',
            'Numéro de puce - activer tous les caractères'][localizationLanguage],
        dateOfEntry: [
            'Dátum vstupu',
            'Date of entry',
            'Date d\'entrée'][localizationLanguage],
        dateOfExit: [
            'Dátum výstupu',
            'Date of exit',
            'Date de sortie'][localizationLanguage],
        chipped: [
            'Začipovaných',
            'Chipped',
            'Ébréché'][localizationLanguage],
        born: [
            'Narodených',
            'Born',
            'Né'][localizationLanguage],
        insertRow: [
            'Vložiť riadok',
            'Insert row',
            'Insérer une ligne'][localizationLanguage],
        alright: [
            'V poriadku',
            'Alright',
            'Bien'][localizationLanguage],
        verifying: [
            'Overujem',
            'Verifying',
            'Vérifie'][localizationLanguage],
        exists: [
            'Existuje',
            'Exists',
            'Existe'][localizationLanguage],
        all: [
            'Všetko',
            'All',
            'Tout'][localizationLanguage],
        from: [
            'Od',
            'From',
            'De'][localizationLanguage],
        to: [
            'Do',
            'To',
            'À'][localizationLanguage],
        filter: [
            'Filter',
            'Filter',
            'Filtre'][localizationLanguage],
        use: [
            'Použiť',
            'Use',
            'Utiliser'][localizationLanguage],
        supplier: [
            'Dodávateľ',
            'Supplier',
            'Fournisseur'][localizationLanguage],
        street: [
            'Ulica',
            'Street',
            'Rue'][localizationLanguage],
        houseNumber: [
            'Číslo domu',
            'House number',
            'Numéro de maison'][localizationLanguage],
        postcode: [
            'PSČ',
            'Zip code',
            'Code postal'][localizationLanguage],
        regId: [
            'IČO',
            'Reg ID',
            'Reg ID'][localizationLanguage],
        taxId: [
            'DIČ',
            'Tax ID',
            'Tax ID'][localizationLanguage],
        vatId: [
            'IČDPH',
            'Vat ID',
            'N° de TVA"'][localizationLanguage],
        contact: [
            'Kontakt',
            'Contact',
            'Contact'][localizationLanguage],
        price: [
            'Cena',
            'Price',
            'Prix'][localizationLanguage],
        subject: [
            'Predmet',
            'Subject',
            'Sujet'][localizationLanguage],
        automatically: [
            'Automaticky',
            'Automatically',
            'Automatiquement'][localizationLanguage],
        item: [
            'Položka',
            'Item',
            'Article'][localizationLanguage],
        subtotal: [
            'Medzisúčet',
            'Subtotal',
            'Subtotal'][localizationLanguage],
        total: [
            'Celkom',
            'Total',
            'Total'][localizationLanguage],
        withVat: [
            's DPH',
            'with VAT',
            'avec TVA'][localizationLanguage],
        tax: [
            'Daň',
            'Tax',
            'Impôt'][localizationLanguage],
        vatRate: [
            'DPH sadzba',
            'VAT rate',
            'Taux de TVA'][localizationLanguage],
        select: [
            'Vybrať',
            'Choose',
            'Choisir'][localizationLanguage],
        detail: [
            'Detail',
            'Detail',
            'Détail'][localizationLanguage],
        cancelTheLink: [
            'Zrušiť prepojenie',
            'Cancel the link',
            'Annuler le lien'][localizationLanguage],
        confirmSelection: [
            'Potvrdiť výber',
            'Confirm selection',
            'Confirmer la sélection'][localizationLanguage],
        calendar: [
            'Kalendár',
            'Calendar',
            'Calendrier'][localizationLanguage],
        history: [
            'História',
            'History',
            'Histoire'][localizationLanguage],
        noRecords: [
            'Žiadne záznamy',
            'No records',
            'Pas d\'enregistrements'][localizationLanguage],
        saveAndContinue: [
            'Uložiť a pokračovať',
            'Save and continue',
            'Sauvegarder et continuer'][localizationLanguage],
        saveAndNew: [
            'Uložiť a nový',
            'Save and new',
            'Enregistrer et nouveau'][localizationLanguage],
        saveAndNewFromCopy: [
            'Uložiť a nový z kópie',
            'Save and new from the copy',
            'Enregistrer et nouveau à partir de la copie'][localizationLanguage],
        mustFileRequiredFields: [
            'Musíte vyplniť povinné polia',
            'You must fill in the required fields',
            'Vous devez remplir les champs obligatoires'][localizationLanguage],
        onlyForSavedRecords: [
            'Táto možnosť je dostupná len pre existujúce (uložené) záznamy.',
            'This option is only available for existing (saved) records.',
            'Cette option n\'est disponible que pour les enregistrements existants (enregistrés).'][localizationLanguage],
        projectTemplatesInfoSave: [
            'Po uložení projektu budú automaticky vytvorené nové úlohy zo všetkých šablón, ktoré majú priradených užívateľov.',
            'After saving the project, new tasks will be automatically created from all templates that have assigned users.',
            'Après avoir enregistré le projet, de nouvelles tâches seront automatiquement créées à partir de tous les modèles auxquels des utilisateurs ont été affectés.'][localizationLanguage],
        projectTemplatesInfoNotFound: [
            'Pre vybraný typ projektu neboli nájdené žiadne šablóny.',
            'No templates were found for the selected project type.',
            'Aucun modèle n\'a été trouvé pour le type de projet sélectionné.'][localizationLanguage],
        today: [
            'Dnes',
            'Today',
            'Aujourd\'hui'][localizationLanguage],
        lastNDays: [
            'Posledné [n] dni',
            'Last [n] days',
            '[n] derniers jours'][localizationLanguage],
        refresh: [
            'Obnoviť',
            'Refresh',
            'Récupérer'][localizationLanguage],
        activityTaskCreated: [
            'Pridal novú úlohu',
            'Added a new task',
            'Ajout d\'une nouvelle tâche'][localizationLanguage],
        activityTaskUpdated: [
            'Upravil úlohu',
            'Edited the task',
            'J\'ai modifié la tâche'][localizationLanguage],
        activityTaskStateUpdated: [
            'Zmenil stav úlohy',
            'Changed the task status',
            'Modification du statut de la tâche'][localizationLanguage],
        activityTaskNoteCreated: [
            'Pridal poznámku v úlohe',
            'Added a note in the task',
            'Ajout d\'une note dans la tâche'][localizationLanguage],
        activityTaskNoteUpdated: [
            'Upravil poznámku v úlohe',
            'Edited the note in the task',
            'Modification de la note dans la tâche'][localizationLanguage],
        noTasksForToday: [
            'Na dnes nemáte žiadne úlohy',
            'You have no tasks for today',
            'Vous n\'avez aucune tâche pour aujourd\'hui'][localizationLanguage],
        noActivities: [
            'Žiadne aktivity',
            'No activities',
            'Aucune activité'][localizationLanguage],
        wait: [
            'Čakajte',
            'Wait',
            'Attendez'][localizationLanguage],
        breedingStation: [
            'Chovná stanica',
            'Breeding station',
            'Station d\'élevage'][localizationLanguage],
        breedingStations: [
            'Chovné stanice',
            'Breeding stations',
            'Stations d\'élevage'][localizationLanguage],
        male: [
            'Samec',
            'Male',
            'Mâle'][localizationLanguage],
        female: [
            'Samica',
            'Female',
            'Femelle'][localizationLanguage],
        gender: [
            'Pohlavie',
            'Gender',
            'Sexe'][localizationLanguage],
        workingPeriod: [
            'Pracovné obdobie',
            'Working period',
            'Période de travail'][localizationLanguage],
        workingTime: [
            'Pracovný čas',
            'Working time',
            'Temps de travail'][localizationLanguage],
        day: [
            'Deň',
            'Day',
            'Jour'][localizationLanguage],
        monday: [
            'Pondelok',
            'Monday',
            'Lundi'][localizationLanguage],
        tuesday: [
            'Utorok',
            'Tuesday',
            'Mardi'][localizationLanguage],
        wednesday: [
            'Streda',
            'Wednesday',
            'Mercredi'][localizationLanguage],
        thursday: [
            'Štvrtok',
            'Thursday',
            'Jeudi'][localizationLanguage],
        friday: [
            'Piatok',
            'Friday',
            'Vendredi'][localizationLanguage],
        saturday: [
            'Sobota',
            'Saturday',
            'Samedi'][localizationLanguage],
        sunday: [
            'Nedeľa',
            'Sunday',
            'Dimanche'][localizationLanguage],
        schedule: [
            'Harmonogram',
            'Schedule',
            'Calendrier'][localizationLanguage],
        scheduleModeGenerateMissing: [
            'Vypočítať iba chýbajúce dni',
            'Calculate only the missing days',
            'Calculer uniquement les jours manquants'][localizationLanguage],
        scheduleModeGenerateMissingInfo: [
            'Existujúci harmonogram bude zachovaný. Pre chýbajúce dni bude automaticky vypočítaný.',
            'The existing schedule will be maintained. It will be automatically calculated for the missing days.',
            'Le calendrier existant sera maintenu. Il sera automatiquement calculé pour les jours manquants.'][localizationLanguage],
        scheduleModeGenerateAll: [
            'Vypočítať znovu všetko',
            'Recalculate everything',
            'Tout recalculer'][localizationLanguage],
        scheduleModeGenerateAllInfo: [
            'Harmonogram bude vypočítaný znovu pre všetky dni.',
            'The schedule will be recalculated for all days.',
            'L\'horaire sera recalculé pour tous les jours.'][localizationLanguage],
        unknownFail: [
            'Nastala neznáma chyba, skúste pokus zopakovať!',
            'An unknown error occurred, please try again!',
            'Une erreur inconnue s\'est produite, veuillez réessayer!'][localizationLanguage],
        scheduleCalculation: [
            'Výpočet harmonogramu',
            'Calculation of the schedule',
            'Calcul de l\'horaire'][localizationLanguage],
        scheduleRendering: [
            'Vykreslenie harmonogramu',
            'Rendering of the schedule',
            'Rendu du planning'][localizationLanguage],
        /*
            : [
                'XXXXXXXXXXXXXX',
                'XXXXXXXXXXXXXX',
                'XXXXXXXXXXXXXX'][localizationLanguage],
        */
    };
    return localization;
};