import { FormEvent, forwardRef, useCallback, useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { GetDateLocale, Localize } from '../../AppLocalization';
import { useHistory } from 'react-router-dom';
import { AppRouteUrl } from '../../AppRoutes';

// Utility
import Debounce from '../../utility/Debounce';
import { NullMinDate } from '../../utility/Date';
import { ConvertToInt } from '../../utility/Convert';

// Modely
import { Breed, BreedingStation, Production } from '../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, FormControl, InputLabel, MenuItem, Select, Typography, Accordion, AccordionDetails, AccordionSummary, Chip, IconButton, Table, TableBody, TableCell, TableHead, TableRow, InputBaseComponentProps, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import MaskedInput, { Mask } from 'react-text-mask';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): Production => ({
    id: 0,
    name: '',
    genderMale: false,
    counts: [{}]
});

// Vstupné parametre
export interface ProductionCreateProps {
    open: boolean;
    id?: number;
    breeds: Breed[];
    breedingStations: BreedingStation[];
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const TextFieldNumberMaskDefault: Mask = [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/];
const TextFieldNumberMaskAll: Mask = [
    /./, /./, /./, /./, /./, /./, /./, /./, /./, /./,
    /./, /./, /./, /./, /./, /./, /./, /./, /./, /./,
    /./, /./, /./, /./, /./, /./, /./, /./, /./, /./
];
const TextFieldNumber = forwardRef<HTMLInputElement, InputBaseComponentProps>((props, ref) => {
    return (
        <MaskedInput
            /* Prvá maska, aby som ju vedel cez props prepísať */
            mask={TextFieldNumberMaskDefault}
            {...props}
            guide={false}
            id="productionCreateChipNumber"
            keepCharPositions={false}
        />
    );
});

const ProductionCreate = (props: ProductionCreateProps) => {

    // Všeobecne
    const localization = Localize();
    const history = useHistory();

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<Production>(EmptySource());
    const [numberExists, setNumberExists] = useState<number | undefined>(0);

    const findNumber = (number: string) => {
        if ((number ?? '').length === 0) {
            setNumberExists(0);
            return;
        }
        setNumberExists(undefined);
        findNumberDelay(number);
    };
    const findNumberDelay = Debounce((number: string) => {
        axios
            .get(AppConfig.ApiUri + 'production/bynumber', {
                params: {
                    number: number
                }
            })
            .then(response => {
                if (response.data !== null) {
                    var numberId = response.data as number;
                    if (numberId > 0 && numberId === source.id) {
                        setNumberExists(0);
                        return;
                    }
                    setNumberExists(numberId);
                }
            });
    }, 1000);

    // Harmonika
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            setNumberExists(0);
            setAccordionExpanded([]);
            return;
        }

        // Fučne focusnem číslo
        setTimeout(() => document.getElementById('productionCreateChipNumber')?.focus(), 0);

        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        if ((props.id ?? 0) === 0) {
            return;
        }

        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'production/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    let entity = response.data as Production;
                    if (props.copy) {
                        entity.id = 0;
                        entity.counts?.forEach(count => count.id = 0);
                        setNumberExists(props.id);
                    }
                    entity.counts?.forEach(count => count.locked = true);
                    setSource(entity);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        
        // Nemením hodnotu pri čiastočne zadanom dátume "Invalid Date" (kontrolovaný vstup by sa nedal prepísať)
        if (property === 'dateOfBirth' || property === 'dateOfEntry' || property === 'dateOfExit') {
            if (value !== null && isNaN(value)) {
                return;
            }
        }

        setSource(prev => ({ ...prev, [property]: value }));
        if (property === 'number') {
            findNumber(value);
        }
    }
    const handleAddCounts = () => {
        setSource((prev) => {
            var next = ({ ...prev, counts: [...prev.counts ?? []] });
            next.counts?.forEach(count => count.locked = true);
            next.counts.push({ id: 0, locked: false });
            return next;
        });
    };
    const handleChangeCounts = (index: number, property: string, value: any) => {

        // Nemením hodnotu pri čiastočne zadanom dátume "Invalid Date" (kontrolovaný vstup by sa nedal prepísať)
        if (property === 'date' || property === 'dateOfFinish') {
            if (value !== null && isNaN(value)) {
                return;
            }
        }

        setSource((p) => {
            const changed = p.counts ?? [];
            changed[index] = { ...changed[index], [property]: value };
            return { ...p, counts: changed };
        });
    }
    const handleDeleteCounts = (index: number) => {
        const counts = (source.counts ?? []);
        counts.splice(index, 1);
        handleChange('counts', counts);
    }

    // Automatická zmena masky čísla čipu
    const [numberMask, setNumberMask] = useState<Mask>(TextFieldNumberMaskDefault);
    useEffect(() => {
        // Nájdem plemeno, ktoré obsahuje informáciu o tom, či môžem použiť všetky znaky
        let breed: Breed = props.breeds?.find(b => b.id === source.breedId) ?? { numberFree: false };
        let mask: Mask = ((breed.numberFree ?? false) ? TextFieldNumberMaskAll : TextFieldNumberMaskDefault);
        if (mask !== numberMask) {
            // Ak sa zmení maska vynulujem číslo (inak by sa po uložení zachovalo pôvodné - nie zobrazené)
            // setSource(prev => ({ ...prev, number: '' })); -- vymaže to pri edite
            setNumberMask(mask);
        }
    }, [source.breedId, props.breeds]); // eslint-disable-line react-hooks/exhaustive-deps

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        // Overovanie
        if (numberExists === undefined) {
            return;
        }
        // Existuje
        if ((numberExists ?? 0) > 0) {
            setWarning({ ...EmptyWarning, name: 'number', text: localization.mustFill + ': ' + localization.chipNumber + ' (' + localization.exists.toLowerCase() + ')!' });
            return;
        }

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'production', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: localization.saveFailed });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => input?.focus(), 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog PaperProps={{ style: { borderLeft: '10px solid ' + (source.genderMale ? '#97cdd2' : '#ffb4be') } }} keepMounted={props.keepMounted ?? false} maxWidth="md" fullWidth open={props.open} scroll="body" disableEnforceFocus onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle sx={{ color: (source.genderMale ? '#00a6b6' : '#f52843') }}>
                        {((source?.id ?? 0) === 0 && localization.new + ': ')} {localization.production}
                        {(props.copy && ' (' + localization.copied + ')')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12}>
                                <RadioGroup row name="genderMale" value={source.genderMale ?? false} onChange={(e) => { handleChange(e.target.name, (e.target.value === 'true')); }}>
                                    <FormControlLabel value="true" control={<Radio />} label={localization.male} />
                                    <FormControlLabel value="false" control={<Radio />} label={localization.female} />
                                </RadioGroup>
                            </Grid>

                            <Grid item xs={12} sm={5}>
                                <TextField required margin="dense" name="number" label={localization.chipNumber} fullWidth variant="outlined" autoComplete="off" value={source.number ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value); }}
                                    inputRef={input => (props.autoFocus === 'number' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 50, style: { fontWeight: 'bold' } }}
                                    color={(numberExists ?? 0) > 0 ? 'error' : 'primary'}
                                    focused={(numberExists ?? 0) > 0}
                                    InputProps={{
                                        inputProps: { mask: numberMask },
                                        inputComponent: TextFieldNumber as any,
                                        endAdornment: (
                                            numberExists === 0 ?
                                                <Chip icon={<SentimentSatisfiedAltIcon />} color="success" variant="outlined" label={localization.alright} /> :
                                                numberExists === undefined ?
                                                    <Chip icon={<CircularProgress size={22} color="inherit" />} color="info" variant="outlined" label={localization.verifying} />
                                                    :
                                                    <Chip icon={<ReportProblemIcon />} color="error" label={localization.exists + ' (' + localization.open.toLowerCase() + ')'} onClick={() => {
                                                        const id = numberExists;
                                                        handleChange('number', ''); // Najskôr musím vyčistiť číslo (nemusím mať právo záznamo zobraziť)
                                                        setNumberExists(0); // Označím, že neexistuje (zatiaľ je prázdne)
                                                        history.push(AppRouteUrl.PRODUCTIONS + '?id=' + id); // Zobrazím príslušný záznam podľa ID (ak nemám právo, vráti sa prázdna trieda)
                                                    }} />
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="production-breedingstation">{localization.breedingStation}</InputLabel>
                                    <Select labelId="production-breedingstation" label={localization.breedingStation} value={source.breedingStationId ?? 0} name="breedingStationId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => { (props.autoFocus === 'breedingStationId' || props.autoFocus === 'breedingStation') && setFocus(input) }}>
                                        <MenuItem key={0} value={0}>-</MenuItem>
                                        {props.breedingStations?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="production-breed">{localization.breed}</InputLabel>
                                    <Select labelId="production-breed" label={localization.breed} value={source.breedId ?? 0} name="breedId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => { props.autoFocus === 'breedId' && setFocus(input) }}>
                                        <MenuItem key={0} value={0}>-</MenuItem>
                                        {props.breeds?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <TextField margin="dense" name="color" label={localization.color} fullWidth variant="outlined" autoComplete="off" value={source.color ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'color') && setFocus(input)}
                                    inputProps={{ maxLength: 50 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <FormatColorFillIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField margin="dense" name="name" label={localization.name} fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'name') && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={6} md={2}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={GetDateLocale()}>
                                    <FormControl margin="dense" fullWidth required>
                                        <DatePicker format='dd.MM.yyyy' label={localization.dateOfBirth} value={NullMinDate(source.dateOfBirth)} onChange={(d) => { handleChange('dateOfBirth', d) }} inputRef={input => { props.autoFocus === 'dateOfBirth' && setFocus(input) }} />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={6} md={2}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={GetDateLocale()}>
                                    <FormControl margin="dense" fullWidth required>
                                        <DatePicker format='dd.MM.yyyy' label={localization.dateOfEntry} value={NullMinDate(source.dateOfEntry)} onChange={(d) => { handleChange('dateOfEntry', d) }} inputRef={input => { props.autoFocus === 'dateOfEntry' && setFocus(input) }} />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={6} md={2}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={GetDateLocale()}>
                                    <FormControl margin="dense" fullWidth required>
                                        <DatePicker format='dd.MM.yyyy' label={localization.dateOfExit} value={NullMinDate(source.dateOfExit)} onChange={(d) => { handleChange('dateOfExit', d) }} inputRef={input => { props.autoFocus === 'dateOfExit' && setFocus(input) }} />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} mt={3}>

                                {(source.counts?.length ?? 0) > 0 &&
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ width: 150 }}>{localization.date}</TableCell>
                                                <TableCell style={{ width: 150 }}>{localization.Ending}</TableCell>
                                                <TableCell style={{ width: 100 }}>{localization.born}</TableCell>
                                                <TableCell style={{ width: 100 }}>{localization.chipped}</TableCell>
                                                <TableCell>{localization.note}</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {source.counts?.map((item, index) => (
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                                                    <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={GetDateLocale()}>
                                                            <FormControl fullWidth>
                                                                <DatePicker format='dd.MM.yyyy' value={NullMinDate(item.date)} onChange={(d) => { handleChangeCounts(index, 'date', d as any) }} disabled={item.locked ?? false} slotProps={{ textField: { size: 'small' } }} />
                                                            </FormControl>
                                                        </LocalizationProvider>
                                                    </TableCell>

                                                    <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={GetDateLocale()}>
                                                            <FormControl fullWidth>
                                                                <DatePicker format='dd.MM.yyyy' value={NullMinDate(item.dateOfFinish)} onChange={(d) => { handleChangeCounts(index, 'dateOfFinish', d as any) }} disabled={item.locked ?? false} slotProps={{ textField: { size: 'small' } }} />
                                                            </FormControl>
                                                        </LocalizationProvider>
                                                    </TableCell>

                                                    <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                        <TextField fullWidth size="small" name="countOfBirths" type="number" variant="outlined" autoComplete="off" value={item.countOfBirths ?? ''}
                                                            onChange={(e) => { handleChangeCounts(index, e.target.name, ConvertToInt(e.target.value)) }}
                                                            disabled={item.locked ?? false}
                                                            inputProps={{ min: 0, max: 32767 }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <ChildFriendlyIcon fontSize="small" />
                                                                    </InputAdornment>
                                                                ),
                                                            }} />
                                                    </TableCell>

                                                    <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                        <TextField fullWidth size="small" name="countOfChipped" type="number" variant="outlined" autoComplete="off" value={item.countOfChipped ?? ''}
                                                            onChange={(e) => { handleChangeCounts(index, e.target.name, ConvertToInt(e.target.value)) }}
                                                            disabled={item.locked ?? false}
                                                            inputProps={{ min: 0, max: 32767 }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <LocalOfferIcon fontSize="small" />
                                                                    </InputAdornment>
                                                                ),
                                                            }} />
                                                    </TableCell>

                                                    <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                        <TextField fullWidth size="small" name="note" type="text" variant="outlined" autoComplete="off" value={item.note ?? ''}
                                                            onChange={(e) => { handleChangeCounts(index, e.target.name, e.target.value) }}
                                                            disabled={item.locked ?? false}
                                                            inputProps={{ maxLength: 1000 }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <EditIcon fontSize="small" />
                                                                    </InputAdornment>
                                                                ),
                                                            }} />
                                                    </TableCell>

                                                    <TableCell style={{ padding: 0, width: '80px', textAlign: 'center' }}>
                                                        <IconButton aria-label="Lock" size="small" onClick={(e) => { handleChangeCounts(index, 'locked', !(item.locked ?? false)) }}>
                                                            {item.locked === true ? <LockIcon fontSize="small" /> : <LockOpenIcon fontSize="small" />}
                                                        </IconButton>
                                                        <IconButton aria-label="Delete" size="small" onClick={() => handleDeleteCounts(index)}>
                                                            <DeleteIcon fontSize="small" />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                }
                                <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={() => handleAddCounts()}>{localization.insertRow}</Button>

                            </Grid>

                            <Grid item xs={12} mt={3}>
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('note')} onChange={() => handleToggleAccordion('note')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>{localization.note} {(source.note?.length ?? 0) > 0 ? ' (' + localization.yes.toLowerCase() + ')' : ''}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12}>
                                                <TextField fullWidth multiline rows={5} margin="dense" name="note" label={localization.note} variant="outlined" value={source.note ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputProps={{ maxLength: 1000 }}
                                                    inputRef={input => { props.autoFocus === 'note' && setFocus(input) }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EditIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>{localization.reset}</Button>
                        <Button disabled={loading} onClick={props.onClose}>{localization.back}</Button>
                        <Button disabled={loading} type="submit" variant="contained">{localization.save}</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default ProductionCreate;