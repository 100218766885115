import { useCallback, useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { Localize } from '../../AppLocalization';

// Utility
import parse from 'html-react-parser';
import { format as dateFormat } from 'date-fns';
import { NullMinDate } from '../../utility/Date';

// Modely
import { PriorityColors, Task, TaskNote, TaskState, TaskType, User } from '../../models/Models';

// Komponenty
import { Avatar, Box, Chip, Drawer, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Menu, MenuItem, Step, StepLabel, Stepper, Tab, Tabs, Typography } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import FileItems from '../file_item/FileItems';
import TaskCreateHistory from './TaskCreateHistory';
import TasksDetailNotes from './TasksDetailNotes';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TopicIcon from '@mui/icons-material/Topic';
import FlagIcon from '@mui/icons-material/Flag';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import CloseIcon from '@mui/icons-material/Close';

interface DetailInfo {
    createdUser?: User;
    updatedUser?: User;
    taskState?: TaskState;
    taskStateColor?: string;
    taskType?: TaskType;
}

interface TasksDetailProps {
    id: number;
    keepMounted?: boolean;
    taskStates: TaskState[];
    taskTypes: TaskType[];
    users: User[];
    onChanged?: () => void;
    onEdit?: (copy?: boolean) => void;
    onDelete?: (name: string) => void;
    onClose: () => void;
}

const TasksDetail = (props: TasksDetailProps) => {

    // Všeobecne
    const localization = Localize();

    // Stav
    const [loading, setLoading] = useState<boolean>(true);
    const [source, setSource] = useState<Task>({});
    const [detailInfo, setDetailInfo] = useState<DetailInfo>({});
    const [tabValue, setTabValue] = useState<string>('task');
    const [taskStateMenuEl, setTaskStateMenuEl] = useState<null | HTMLElement>(null);

    // Obnovenie údajov
    const reloadData = () => {
        setSource(prev => ({ ...prev, id: 0 })); // Vynulujem id, aby sa vyvolal háčik pre "LoadDataInfo" po načítaní "loadData"
        loadData();
        loadDataInfo();
    };
    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (props.id === 0) {
            setSource({});
            setTabValue('task');
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'task/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    const data = response.data as Task;
                    if ((data.canRead ?? false) === false) {
                        props.onClose();
                        return;
                    }
                    setSource(data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id]); // eslint-disable-line react-hooks/exhaustive-deps

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Načítanie relačných údajov
    const loadDataInfo = () => {
        if ((source.id ?? 0) === 0) {
            return;
        }

        let info: DetailInfo = {};

        if ((source.createdUserId ?? 0) > 0) {
            info.createdUser = props.users.find(u => u.id === source.createdUserId);
        }
        if ((source.updatedUserId ?? 0) > 0) {
            info.updatedUser = props.users.find(u => u.id === source.updatedUserId);
        }
        if ((source.taskStateId ?? 0) > 0) {
            info.taskState = props.taskStates.find(s => s.id === source.taskStateId);
            info.taskStateColor = '#' + ((info.taskState?.color ?? '').length ? info.taskState?.color : 'f0f0f0');
        }
        if ((source.taskTypeId ?? 0) > 0) {
            info.taskType = props.taskTypes.find(t => t.id === source.taskTypeId);
        }

        setDetailInfo(info);
    };
    useEffect(() => loadDataInfo(), [source.id]); // eslint-disable-line react-hooks/exhaustive-deps

    // Formátovanie dátumu
    const formatDate = (date: any): string => {
        if (NullMinDate(date) === null) {
            return '-';
        }
        return dateFormat(new Date(date), 'dd.MM.yyyy HH:mm');
    }

    // Ak sa dosiahne niektorý z dátumov, tak sa označí ako vykonaný
    const activeStep = (): number => {
        const now = new Date().getTime();
        if (NullMinDate(source.finish) !== null && now >= new Date(source.finish ?? new Date()).getTime()) {
            return 3;
        }
        if (NullMinDate(source.end) !== null && now >= new Date(source.end ?? new Date()).getTime()) {
            return 2;
        }
        if (NullMinDate(source.start) !== null && now >= new Date(source.start ?? new Date()).getTime()) {
            return 1;
        }
        return -1;
    }

    // Vynuluje prázdny string
    const nullEmptyString = (s?: string): string | null | undefined => {
        return (s?.length ?? 0) === 0 ? null : s;
    }

    // Vygeneruje avatar
    const userAvatar = (user?: User): any => {
        return (
            (user?.fileIsImage ?? false) === true ?
                <Avatar alt={user?.name} src={user?.fileSrcSmall} /> :
                <Avatar><PersonIcon /></Avatar>
        );
    }

    // Zmena stavu úlohy
    const handleStateChange = (taskStateId: number) => {
        setTaskStateMenuEl(null);
        axios
            .post(AppConfig.ApiUri + 'task/change', { changeName: 'state', id: props.id, taskStateId: taskStateId } as Task)
            .then(response => {
                if (response.data !== true) {
                    alert(localization.saveFailed);
                    return;
                }
                reloadData();
                props.onChanged?.();
            })
            .catch(() => alert(localization.saveFailed))
            .finally(() => setLoading(false));
    }

    // Pridanie poznámky
    const handleAddNote = (note: TaskNote) => {
        setTaskStateMenuEl(null);
        axios
            .post(AppConfig.ApiUri + 'task/change', { changeName: 'note', id: props.id, notes: [note] } as Task)
            .then(response => {
                if (response.data !== true) {
                    alert(localization.saveFailed);
                    return;
                }
                reloadData();
                // props.onChanged?.(); -- tu nie je potrebné obnoviť zoznam
            })
            .catch(() => alert(localization.saveFailed))
            .finally(() => setLoading(false));
    }

    return (
        <Drawer keepMounted={props.keepMounted ?? false} anchor="right" open={props.id > 0} onClose={props.onClose} PaperProps={{ sx: { width: '100%', maxWidth: '600px', p: 0, pt: 0 } }}>
            <Box sx={{ position: 'sticky', top: 0, pt: 2, p: 2, pb: 1, background: 'white', zIndex: 9999, borderBottom: '1px solid #F0F0F0' }}>
                <Box sx={{ position: 'absolute', top: '12px', right: '12px' }}>
                    {(source.canWrite ?? false) === true && (
                        <>
                            {props.onEdit !== undefined && (<IconButton aria-label="edit" onClick={() => props.onEdit?.(false)} sx={{ border: '1px solid #f0f0f0', ml: 0.5 }}><EditIcon /></IconButton>)}
                            {props.onEdit !== undefined && (<IconButton aria-label="edit" onClick={() => props.onEdit?.(true)} sx={{ border: '1px solid #f0f0f0', ml: 0.5 }}><ContentCopyIcon /></IconButton>)}
                            {props.onDelete !== undefined && (<IconButton aria-label="delete" onClick={() => props.onDelete?.(source.name ?? '')} sx={{ border: '1px solid #f0f0f0', ml: 0.5 }}><DeleteIcon /></IconButton>)}
                        </>
                    )}
                    <IconButton aria-label="close" onClick={() => props.onClose()} sx={{ border: '1px solid #f0f0f0', ml: 0.5 }}><CloseIcon /></IconButton>
                </Box>
                <Typography mb={1} variant="h5" sx={{ pr: '170px' }}>{loading === true ? localization.wait : source?.name}</Typography>
                {source.users?.map((userId) => {
                    var user = props.users.find(item => item.id === userId) ?? { name: '' };
                    return (
                        <Chip
                            key={user.id}
                            sx={{ mb: .5, mr: .5 }}
                            label={user.name}
                            avatar={(
                                user.fileIsImage ?
                                    <Avatar sx={{ width: 32, height: 32 }} alt={user.name} src={user.fileSrcSmall} /> :
                                    <Avatar sx={{ width: 32, height: 32 }}><PersonIcon /></Avatar>
                            )}
                        />
                    )
                })}
            </Box>
            <Box sx={{ px: 2 }}>

                <Box sx={{ mt: 1 }}>
                    <Tabs value={tabValue} onChange={(e, v) => setTabValue(v)} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                        <Tab value="task" label={localization.task} />
                        <Tab value="notes" label={localization.notes + ' (' + (source.notes?.length ?? 0) + ')'} />
                        <Tab value="files" label={localization.files + ' (' + (source.files?.length ?? 0) + ')'} />
                        <Tab value="history" label={localization.history + ' (' + (source.stateHistory?.length ?? 0) + ')'} />
                    </Tabs>
                </Box>

                <TabContext value={tabValue}>
                    <TabPanel value="task" sx={{ px: 0 }}>

                        <Stepper nonLinear activeStep={-1} alternativeLabel sx={{ mt: 2, mb: 2 }}>
                            <Step completed={activeStep() >= 1}>
                                <StepLabel>{localization.start}<br /><strong>{formatDate(source.start)}</strong></StepLabel>
                            </Step>
                            <Step completed={activeStep() >= 2}>
                                <StepLabel>{localization.end}<br /><strong>{formatDate(source.end)}</strong></StepLabel>
                            </Step>
                            <Step completed={activeStep() >= 3}>
                                <StepLabel>{localization.deadline}<br /><strong>{formatDate(source.finish)}</strong></StepLabel>
                            </Step>
                        </Stepper>

                        <Menu id="menuTaskState" anchorEl={taskStateMenuEl} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right' }} open={Boolean(taskStateMenuEl)} onClose={() => setTaskStateMenuEl(null)} >
                            {props.taskStates.map(item => (<MenuItem disabled={item.id === (source.taskStateId ?? 0)} onClick={() => handleStateChange(item.id ?? 0)} key={item.id} value={item.id} sx={{ minWidth: '215px', color: '#' + ((item.color ?? '').length > 0 ? item.color : 'f0f0f0') }}>{item.name}</MenuItem>))}
                        </Menu>

                        <List dense sx={{
                            display: 'flex', flexWrap: 'wrap', columnGap: '10px', mb: 3,
                            '& > li': { flexGrow: '1', flexBasis: '40%', minWidth: '250px', borderBottom: '1px solid #f0f0f0' },
                            '& > div': { flexGrow: '1', flexBasis: '40%', minWidth: '250px', borderBottom: '1px solid #f0f0f0' },
                        }}>

                            <ListItemButton onClick={(e) => setTaskStateMenuEl(e.currentTarget)}>
                                <ListItemAvatar>
                                    <Avatar sx={{ background: detailInfo.taskStateColor }}>
                                        <FlagIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText sx={{ color: detailInfo.taskStateColor }} primary={nullEmptyString(detailInfo.taskState?.name) ?? '-'} secondary={localization.taskState} />
                            </ListItemButton>

                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar sx={{ background: '#' + PriorityColors[(source.priority ?? 1) - 1] }}>
                                        {source.priority ?? '-'}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText sx={{ color: '#' + PriorityColors[(source.priority ?? 1) - 1] }} primary={localization.priority + ' ' + source.priority ?? '-'} secondary={localization.priority} />
                            </ListItem>

                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <CalendarMonthIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={nullEmptyString(source.taskName) ?? '-'} secondary={localization.task} />
                            </ListItem>

                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <TopicIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={nullEmptyString(source.projectName) ?? '-'} secondary={localization.project} />
                            </ListItem>

                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <HomeRepairServiceIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={nullEmptyString(detailInfo.taskType?.name) ?? '-'} secondary={localization.taskType} />
                            </ListItem>

                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        {userAvatar(detailInfo.createdUser)}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={detailInfo.createdUser?.name ?? '-'} secondary={localization.created + ': ' + formatDate(source.createdDate)} />
                            </ListItem>

                        </List>

                        <div className='html-description'>
                            {parse(source.description ?? '')}
                        </div>
                    </TabPanel>
                    <TabPanel value="notes" sx={{ px: 0 }}>
                        <TasksDetailNotes source={source} users={props.users} onAddNote={handleAddNote} />
                    </TabPanel>
                    <TabPanel value="files" sx={{ px: 0, pt: 1 }}>
                        <FileItems readonly={true} items={source.files} gridXs={6} gridSm={4} />
                    </TabPanel>
                    <TabPanel value="history" sx={{ px: 0 }}>
                        <TaskCreateHistory users={props.users} taskStates={props.taskStates} stateHistory={source.stateHistory} />
                    </TabPanel>
                </TabContext>

            </Box>
        </Drawer>
    )
}

export default TasksDetail