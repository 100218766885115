import { Switch, Route, RouteComponentProps } from 'react-router-dom';
import { Box, CircularProgress, CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import axios from 'axios';

// Nastavenia
import { AppConfig } from './AppConfig';
import { GetRoutes } from './AppRoutes';

// Utility
import { CookieCreate, CookieErase, CookieRead } from './utility/Cookies';

// Téma
import ThemeDefault from './theme/ThemeDefault';
import GlobalStyles from './theme/GlobalStyles';

// Podstránky
import Layout from './layout/Layout';
import Login from './views/system/Login';

import "@fontsource/roboto-condensed"; // Defaults to weight 400.
import { useState, useCallback, useEffect } from 'react';
import { AppContext, AppContextModel } from './AppContext';

enum LoggedType {
    Verification,
    Unauthorized,
    Authorized,
}

const App = () => {

    // Kontext musí byť uložený v stave rodiča, aby sa zbytočne neprekreslovali komponenty: https://legacy.reactjs.org/docs/context.html#caveats 
    const [appContext, setAppContext] = useState<AppContextModel>({
        // ... predvolené hodnoty
    });

    // Autorizácia všetkých volaní pomocou globálnej hlavičky
    axios.defaults.headers.common['session'] = (() => {
        return CookieRead(AppConfig.CookieName.LoggedSession) ?? '';
    })();

    // Kontrola prihláseného užívateľa (cookie je vytvorené kvôli automatickému prihláseniu a predĺženiu platnosti)
    const [logged, setLogged] = useState<LoggedType>(LoggedType.Verification);
    const handleLogged = useCallback(() => {
        axios
            .post(AppConfig.ApiUri + 'user/logged', null, {
                headers: {
                    'session': CookieRead(AppConfig.CookieName.LoggedSession) ?? '',
                    'sessionRemember': CookieRead(AppConfig.CookieName.LoggedSessionRemember) ?? ''
                }
            })
            .then(response => {
                // Ak prihlásenie nie je platné odstránim cookies a nastavím stav "unauthorized"
                if (response.data.message === 'unauthorized') {
                    // CookieErase(AppConfig.CookieName.LoggedSession);
                    // CookieErase(AppConfig.CookieName.LoggedSessionRemember);
                    setLogged(LoggedType.Unauthorized);
                }
                // Ak je prihlásenie platné, tak vytvorím cookies a nastavím stav "authorized"
                if (response.data.message === 'authorized') {
                    if (response.data.session !== '') {
                        CookieCreate(AppConfig.CookieName.LoggedSession, response.data.session, 1);
                        setLogged(LoggedType.Authorized);
                    }
                    if (response.data.sessionRemember !== '') {
                        CookieCreate(AppConfig.CookieName.LoggedSessionRemember, response.data.sessionRemember, 365);
                    }
                    setAppContext(prev => ({
                        ...prev,
                        user: {
                            id: response.data.id ?? 0,
                            admin: response.data.admin ?? false,
                            name: response.data.name ?? '',
                            avatar: response.data.avatar ?? '',
                        }
                    }));
                }
            })
            .catch(err => {
                // Iba dočasne odhlásim, keďže mohlo jednorázovo vypadnúť API (nechcene by to mohlo odhlasovať, cookies odstráni API)
                setLogged(LoggedType.Unauthorized);
                setAppContext(prev => ({ ...prev, user: undefined }));
            });
    }, []);

    // Vykonám kontrolu ihneď po zobrazení kontrolu
    useEffect(() => {
        if (logged === LoggedType.Verification) {
            handleLogged();
        }
        // Vykonám kontrolu každých 5 minút
        if (logged === LoggedType.Authorized) {
            const interval = setInterval(() => {
                handleLogged();
            }, (5 * 60 * 1000));
            return () => clearInterval(interval);
        }
    }, [logged, handleLogged]);


    const RenderApp = () => {
        switch (logged) {
            case LoggedType.Verification:
                return (
                    <Box display="flex"
                        justifyContent="center"
                        alignItems="center"
                        minHeight="100vh">
                        <CircularProgress />
                    </Box>
                )
            case LoggedType.Unauthorized:
                return (
                    <Box display="flex"
                        justifyContent="center"
                        alignItems="center"
                        minHeight="100vh">
                        <Login onLogged={handleLogged} />
                    </Box>
                )
            case LoggedType.Authorized:
                return (
                    <Layout>
                        <Switch>
                            {GetRoutes(appContext.user?.admin ?? false).map((route, index) => {
                                return (
                                    <Route
                                        key={index}
                                        path={route.page.toString()}
                                        exact={route.exact}
                                        render={(props: RouteComponentProps<any>) => (
                                            <route.component
                                                title={route.title}
                                                {...props}
                                                {...route.props}
                                            />
                                        )} />
                                )
                            }
                            )}
                        </Switch>
                    </Layout>
                )
        }
    };

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={ThemeDefault}>
                <GlobalStyles />
                <CssBaseline />
                <AppContext.Provider value={{ ...appContext, setContext: setAppContext }}>
                    {RenderApp()}
                </AppContext.Provider>
            </ThemeProvider>
        </StyledEngineProvider>
    )
}

export default App;