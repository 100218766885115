import { useEffect, useState } from 'react';
import { Localize } from '../AppLocalization';

// Utility
import { LoadLocalSettings, SaveLocalSettings } from '../utility/LocalSettings';

// Komponenty
import { ToggleButton, ToggleButtonGroup, Tooltip, useTheme } from '@mui/material';
import { GridDensity } from '@mui/x-data-grid';

// Ikony
import GridOnIcon from '@mui/icons-material/GridOn';
import GridViewIcon from '@mui/icons-material/GridView';

interface DataGridDensityProps {
    onDensityChanged: (density: GridDensity) => void;
}

const DataGridDensity = (props: DataGridDensityProps) => {

    // Téma
    const theme = useTheme();

    // Jazyková mutácia
    const localization = Localize();

    // Nastavenie zobrazenia    
    const [density, setDensity] = useState<GridDensity>(LoadLocalSettings('datagrid-density', 'compact') as GridDensity);
    const setDensityAndSave = (value: string) => {
        setDensity(value as GridDensity);
        SaveLocalSettings('datagrid-density', value, 'compact');
    };

    // Automaticky odovzdám aktuálne nastavenie
    useEffect(() => {
        props.onDensityChanged(density);
    }, [density]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ToggleButtonGroup exclusive value={density} size="small" sx={{ verticalAlign: 'middle', mr: { xs: 0, md: 1 }, backgroundColor: theme.layout.content.backgroundColor }} onChange={(e: any, d) => { if (d !== null) { setDensityAndSave(d); } }}>
            <ToggleButton value="compact" aria-label={localization.compact}>
                <Tooltip title={localization.compact}>
                    <GridOnIcon />
                </Tooltip>
            </ToggleButton>
            <ToggleButton value="standard" aria-label={localization.normal}>
                <Tooltip title={localization.normal}>
                    <GridViewIcon />
                </Tooltip>
            </ToggleButton>
        </ToggleButtonGroup>
    )
}

export default DataGridDensity;