import { useEffect, useState } from 'react'
import { Localize } from '../../AppLocalization';

// Utility
import { FileOpen } from '../../utility/File';

// Modely
import { FileItem, Task } from '../../models/Models';

// Komponenty
import { Card, CardMedia, MenuItem, CardActionArea, CardActions, IconButton, Typography, Divider, ListItemIcon, Menu } from '@mui/material';
import Grid from '@mui/material/Grid';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import FileItemEdit, { FileItemEditProps } from './FileItemEdit';
import FileUpload from '../../components/FileUpload';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

interface FileItemsProps {
    readonly?: boolean;
    items?: FileItem[];
    gridXs?: number;
    gridSm?: number;
    onChanged?: (items: FileItem[]) => void;
}

const FileItems = (props: FileItemsProps) => {

    // Jazyková mutácia
    const localization = Localize();

    // Kontextové menu
    const [filesMenuItem, setFilesMenuItem] = useState<FileItem>();
    const [filesMenuEl, setFilesMenuEl] = useState<null | HTMLElement>(null);

    // Odovzdanie zmenenej položky cez stav
    const [setFileItemChanged, setSetFileItemChanged] = useState<FileItem | null>(null);
    useEffect(() => {
        if (setFileItemChanged === null)
            return;
        const changed = [...props.items ?? []];
        changed?.every((item, itemIndex) => {
            if (item.fileName === setFileItemChanged.fileName) {
                changed[itemIndex] = setFileItemChanged;
                return false;
            }
            return true;
        });
        props.onChanged?.(changed);
    }, [setFileItemChanged]);

    // Stav
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [fileItemEdit, setFileItemEdit] = useState<FileItemEditProps>({
        open: false,
        keepMounted: true,
        file: {},
        onSave: (file: FileItem) => {
            file.changed = true;
            setSetFileItemChanged(file);
        },
        onClose: () => handleFilesEditClose()
    });
    const handleFilesEditOpen = (file: FileItem) => {
        setFileItemEdit(prev => ({ ...prev, open: true, file: file }));
    };
    const handleFilesEditClose = () => {
        setFileItemEdit(prev => ({ ...prev, open: false }));
    };

    // Funkcia pre otvorenie kontexového menu zložky
    const handleFilesMenuOpen = (e: HTMLElement, file: FileItem) => {
        setFilesMenuItem(file);
        setFilesMenuEl(e);
    };

    // Po dvoj-kliku otvorím súbor
    const handleFileClick = (file: FileItem) => {
        if (file?.fileSrc !== undefined) {
            FileOpen(file?.fileSrc, '_blank');
        }
    };

    // Vymazať súbor 
    const handleFileDelete = (file: FileItem) => {
        setConfirm(prev => ({
            ...prev, open: true, title: localization.file, children: localization.deleteConfirmation, onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                const changed = ([...props.items ?? []])?.filter(item => !(item.fileName === file.fileName));
                props.onChanged?.(changed);
            }
        }));
    }

    // Vložiť nahrané súbory
    const handleFileUpload = (files: FileItem[]) => {
        if (files.length === 0) {
            return;
        }
        const changed = [...props.items ?? []];
        files.forEach(file => {
            const ordinalNumber = ((changed?.length ?? 0) > 0 ? (Math.max(...(changed?.map(i => i.ordinalNumber ?? 0) ?? [])) ?? 0) + 1 : 0);
            changed.push({ ...file, ordinalNumber: ordinalNumber });
        });
        props.onChanged?.(changed);
    };

    // Zoradenie položiek, najskôr podľa poradia potom podľa id súboru (poradie môže byť rovnaké)
    const filesSortComparer = (a: FileItem, b: FileItem): number => {
        if ((a.ordinalNumber ?? 0) !== (b.ordinalNumber ?? 0)) {
            return (a.ordinalNumber ?? 0) > (b.ordinalNumber ?? 0) ? 1 : -1;
        }
        else {
            return (a.fileName ?? 0) > (b.fileName ?? 0) ? 1 : -1;
        }
    }

    return (
        <>
            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            <Grid container columnSpacing={1}>

                {/* Nahrať súbory */}
                {(props.readonly ?? false) === false && (
                    <Grid item xs={12} mb={0}>
                        <FileUpload onUploaded={handleFileUpload} />
                    </Grid>
                )}

                {/* Kontextové menu položky */}
                <Menu id="menu-directories" anchorEl={filesMenuEl} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} open={Boolean(filesMenuEl)} onClose={() => setFilesMenuEl(null)} >
                    <MenuItem onClick={() => { if (filesMenuItem !== undefined && filesMenuItem?.fileSrc !== undefined) { FileOpen(filesMenuItem?.fileSrc, '_blank'); } setFilesMenuEl(null); }}>
                        <ListItemIcon><SearchIcon fontSize="small" /></ListItemIcon> {localization.open}
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => { handleFilesEditOpen(filesMenuItem ?? {}); setFilesMenuEl(null); }}>
                        <ListItemIcon><EditIcon fontSize="small" /></ListItemIcon> {localization.edit}
                    </MenuItem>
                    <MenuItem onClick={() => { handleFileDelete(filesMenuItem ?? {}); setFilesMenuEl(null); }}>
                        <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon> {localization.delete}
                    </MenuItem>
                </Menu>

                {/* Zoznam */}
                <Grid mt={0} item container xs={12} columnSpacing={2} rowSpacing={2} {...(props.items?.length === 0 && { display: "none" })}>
                    {props.items?.sort((a, b) => filesSortComparer(a, b))?.map((file) => (
                        <Grid key={file.fileName} item xs={props.gridXs ?? 4} sm={props.gridSm ?? 3}>
                            <Card>
                                <CardActionArea onClick={() => handleFileClick(file)}>
                                    {file?.fileIsImage ? (
                                        <CardMedia component="img" image={(file?.fileSrcMedium ?? '')} alt={file.name ?? ''} sx={{ height: { xs: '100px', md: '150px' } }} />
                                    ) : (
                                        <CardMedia component="div" sx={{ display: 'flex', height: { xs: '100px', md: '150px' }, justifyContent: 'center', alignItems: 'center' }}>
                                            <InsertDriveFileOutlinedIcon fontSize="large" />
                                        </CardMedia>
                                    )}
                                </CardActionArea>
                                <CardActions sx={{ py: 1 }}>
                                    <Typography noWrap fontSize="small" pl={1}>{'#' + (file.ordinalNumber ?? 0) + ' - ' + ((file.name?.length ?? 0) > 0 ? file.name : '...')}</Typography>
                                    {(props.readonly ?? false) === false && (
                                        <IconButton sx={{ marginLeft: 'auto' }} onClick={(e) => handleFilesMenuOpen(e.currentTarget, file)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    )}
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Grid>

            {/* Upraviť súbor */}
            <FileItemEdit {...fileItemEdit} />
        </>
    )
}

export default FileItems;