import { Localize } from '../../AppLocalization';

// Modely
import { Country, CustomerBase } from '../../models/Models';

// Komponenty
import { Button, TextField, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

// Ikony
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';

interface CustomerCreateBaseProps {
    customer: CustomerBase;
    countries: Country[];
    autoFocus?: string;
    setFocus: (input: any) => void;
    onChange: (property: string, value: any) => void;
}

const CustomerCreateBase = (props: CustomerCreateBaseProps) => {

    // Jazyková mutácia
    const localization = Localize();

    // Otvorenie URL adresy
    const openUrl = (url: string, target?: string) => {
        var link = document.createElement("a");
        link.setAttribute("href", url);
        if (target !== undefined) {
            link.setAttribute("target", target);
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Vyhľadanie adresy, nový email, volať..
    const handleOpenCall = (phone: string) => {
        openUrl('tel:' + phone);
    }
    const handleOpenEmail = (email: string) => {
        openUrl('mailto:' + email);
    }

    return (
        <>
            <Grid item xs={12} md={6}>
                <TextField required margin="dense" name="name" label={localization.title + ' / ' + localization.name} fullWidth variant="outlined" autoComplete="off" value={props.customer.name ?? ''} onChange={(e) => { props.onChange(e.target.name, e.target.value) }}
                    inputRef={input => (props.autoFocus === 'name') && props.setFocus(input)}
                    inputProps={{ maxLength: 100 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <PersonIcon />
                            </InputAdornment>
                        ),
                    }} />
            </Grid>

            <Grid item xs={12} md={2}>
                <TextField margin="dense" name="regId" label={localization.regId} fullWidth variant="outlined" autoComplete="off" value={props.customer.regId ?? ''} onChange={(e) => { props.onChange(e.target.name, e.target.value) }}
                    inputRef={input => props.autoFocus === 'regId' && props.setFocus(input)}
                    inputProps={{ maxLength: 15 }} />
            </Grid>

            <Grid item xs={12} md={2}>
                <TextField margin="dense" name="taxId" label={localization.taxId} fullWidth variant="outlined" autoComplete="off" value={props.customer.taxId ?? ''} onChange={(e) => { props.onChange(e.target.name, e.target.value) }}
                    inputRef={input => props.autoFocus === 'taxId' && props.setFocus(input)}
                    inputProps={{ maxLength: 18 }} />
            </Grid>

            <Grid item xs={12} md={2}>
                <TextField margin="dense" name="vatId" label={localization.vatId} fullWidth variant="outlined" autoComplete="off" value={props.customer.vatId ?? ''} onChange={(e) => { props.onChange(e.target.name, e.target.value) }}
                    inputRef={input => props.autoFocus === 'vatId' && props.setFocus(input)}
                    inputProps={{ maxLength: 20 }} />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField margin="dense" name="email" label={localization.email} fullWidth variant="outlined" autoComplete="off" value={props.customer.email ?? ''} onChange={(e) => { props.onChange(e.target.name, e.target.value) }}
                    inputRef={input => props.autoFocus === 'email' && props.setFocus(input)}
                    inputProps={{ maxLength: 120 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <EmailIcon />
                            </InputAdornment>
                        ),
                    }} />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField margin="dense" name="phone" label={localization.phone} fullWidth variant="outlined" autoComplete="off" value={props.customer.phone ?? ''} onChange={(e) => { props.onChange(e.target.name, e.target.value) }}
                    inputRef={input => props.autoFocus === 'phone' && props.setFocus(input)}
                    inputProps={{ maxLength: 25 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <PhoneIcon />
                            </InputAdornment>
                        ),
                    }} />
            </Grid>

            {((props.customer.phone ?? '').length > 5 || (props.customer.email ?? '').length > 0) && (
                <Grid item xs={12} mb={1}>
                    {(props.customer.phone ?? '').length > 5 && <Button variant="outlined" size="small" sx={{ mt: 1, mr: 1 }} onClick={() => handleOpenCall(props.customer.phone ?? '')} startIcon={<PhoneIcon />}>{localization.call}</Button>}
                    {(props.customer.email ?? '').length > 5 && <Button variant="outlined" size="small" sx={{ mt: 1, mr: 1 }} onClick={() => handleOpenEmail(props.customer.email ?? '')} startIcon={<EmailIcon />}>{localization.email}</Button>}
                </Grid>
            )}

            <Grid item xs={12} mt={2}></Grid>

            <Grid item xs={12} md={8}>
                <TextField margin="dense" name="street" label={localization.street} fullWidth variant="outlined" autoComplete="off" value={props.customer.street ?? ''} onChange={(e) => { props.onChange(e.target.name, e.target.value) }}
                    inputRef={input => props.autoFocus === 'street' && props.setFocus(input)}
                    inputProps={{ maxLength: 50 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <LocationOnIcon />
                            </InputAdornment>
                        ),
                    }} />
            </Grid>

            <Grid item xs={12} md={4}>
                <TextField margin="dense" name="houseNumber" label={localization.houseNumber} fullWidth variant="outlined" autoComplete="off" value={props.customer.houseNumber ?? ''} onChange={(e) => { props.onChange(e.target.name, e.target.value) }}
                    inputRef={input => props.autoFocus === 'houseNumber' && props.setFocus(input)}
                    inputProps={{ maxLength: 15 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <LocationOnIcon />
                            </InputAdornment>
                        ),
                    }} />
            </Grid>

            <Grid item xs={12} md={4}>
                <FormControl margin="dense" variant="outlined" fullWidth>
                    <InputLabel id="props.customer-country">{localization.country}</InputLabel>
                    <Select labelId="props.customer-country" label={localization.country} value={props.customer.countryId ?? 0} name="countryId" onChange={(e) => { props.onChange(e.target.name, e.target.value) }}
                        inputRef={input => props.autoFocus === 'countryId' && props.setFocus(input)}>
                        <MenuItem key={0} value={0}>-</MenuItem>
                        {props.countries?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
                <TextField margin="dense" name="city" label={localization.city} fullWidth variant="outlined" autoComplete="off" value={props.customer.city ?? ''} onChange={(e) => { props.onChange(e.target.name, e.target.value) }}
                    inputRef={input => props.autoFocus === 'city' && props.setFocus(input)}
                    inputProps={{ maxLength: 255 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <LocationOnIcon />
                            </InputAdornment>
                        ),
                    }} />
            </Grid>

            <Grid item xs={12} md={4}>
                <TextField margin="dense" name="postcode" label={localization.postcode} fullWidth variant="outlined" autoComplete="off" value={props.customer.postcode ?? ''} onChange={(e) => { props.onChange(e.target.name, e.target.value) }}
                    inputRef={input => props.autoFocus === 'postcode' && props.setFocus(input)}
                    inputProps={{ maxLength: 15 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <LocationOnIcon />
                            </InputAdornment>
                        ),
                    }} />
            </Grid>
        </>
    )
}

export default CustomerCreateBase;