import { FormEvent, useCallback, useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { GetDateLocale, Localize } from '../../AppLocalization';

// Utility
import { NullMinDate } from '../../utility/Date';
import { ConvertToDecimal, ConvertToDecimalOnKeyDown, ConvertToInt } from '../../utility/Convert';

// Modely
import { Country, Customer, CustomerBaseFromCustomer, CustomerLite, Invoice } from '../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, Typography, Accordion, AccordionDetails, AccordionSummary, IconButton, Table, TableBody, TableCell, TableHead, TableRow, FormControl } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CustomerCreateBase from '../customer/CustomerCreateBase';
import CustomersDialog, { CustomersDialogProps } from '../customer/CustomersDialog';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EuroIcon from '@mui/icons-material/Euro';
import NumbersIcon from '@mui/icons-material/Numbers';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonIcon from '@mui/icons-material/Person';
import { AppRouteUrl } from '../../AppRoutes';
import PercentIcon from '@mui/icons-material/Percent';
import InvoiceCreateSummary from './InvoiceCreateSummary';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): Invoice => ({
    id: 0,
    date: new Date(),
    subject: '',
    supplier: {},
    customer: {},
    items: [{ ordinalNumber: 1 }]
});

// Vstupné parametre
export interface InvoiceCreateProps {
    open: boolean;
    id?: number;
    supplierId?: number; // predvolený dodávateľ pri novom zázname
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    countries: Country[];
    onSave?: () => void;
    onClose: () => void;
}

const InvoiceCreate = (props: InvoiceCreateProps) => {

    // Všeobecne
    const localization = Localize();

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<Invoice>(EmptySource());

    // Harmonika
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);

    // Funkcie pre získanie informácie o tom, či je harmonika otvorená a zmenu stavu
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            setAccordionExpanded([]);
            return;
        }

        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        if ((props.id ?? 0) === 0) {
            // Predvyplním dodávateľa pri novom zázname
            if ((props.supplierId ?? 0) > 0) {
                customerLoad(props.supplierId ?? 0, true);
            }
            return;
        }

        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'invoice/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    let entity = response.data as Invoice;
                    if (props.copy) {
                        entity.id = 0;
                        entity.number = undefined;
                        entity.items?.forEach(count => count.id = 0);
                    }
                    setSource(entity);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        // Nemením hodnotu pri čiastočne zadanom dátume "Invalid Date" (kontrolovaný vstup by sa nedal prepísať)
        if (property === 'date') {
            if (value !== null && isNaN(value)) {
                return;
            }
        }
        setSource(prev => ({ ...prev, [property]: value }));
    }
    const handleChangeSupplier = (property: string, value: any) => {
        setSource(prev => ({ ...prev, supplier: { ...prev.supplier, [property]: value } }));
    }
    const handleChangeCustomer = (property: string, value: any) => {
        setSource(prev => ({ ...prev, customer: { ...prev.customer, [property]: value } }));
    }
    const handleAddItems = () => {
        setSource((prev) => {
            var next = ({ ...prev, items: [...prev.items ?? []] });
            var max = (next.items.length > 0 ? Math.max(...next.items.map(item => item.ordinalNumber ?? 0)) : 0);
            next.items.push({ id: 0, ordinalNumber: max + 1 });
            return next;
        });
    };
    const handleChangeItems = (index: number, property: string, value: any) => {
        setSource((p) => {
            const changed = [...(p.items ?? [])];
            changed[index] = { ...changed[index], [property]: value };
            return { ...p, items: changed };
        });
    }
    const handleDeleteItems = (index: number) => {
        const items = [...(source.items ?? [])];
        items.splice(index, 1);
        handleChange('items', items);
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'invoice', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: localization.saveFailed });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => input?.focus(), 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    // Výber existujúceho zákazníka
    const [customersDialog, setCustomersDialog] = useState<CustomersDialogProps>({
        open: false,
        keepMounted: false, // Ponechať vypnuté! (mohol by cyklicky vykreslovať sám seba, pri prvom zobrazení to v prípade potreby môžem jednorázovo prepnúť)
        onSelect: (customers: CustomerLite[], argument: any) => {
            if (customers.length > 0) {
                const customerId = customers[0].id ?? 0;
                if (customerId > 0) {
                    customerLoad(customerId, argument === true);
                }
            }
            handleCustomersClose();
        },
        onClose: () => handleCustomersClose()
    });

    // Načítanie údajov zákazníka
    const customerLoad = (customerId: number, supplier: boolean) => {
        if (customerId > 0) {
            setLoading(true);
            axios
                .get(AppConfig.ApiUri + 'customer/' + customerId)
                .then(response => {
                    if (response.data !== null) {
                        if (supplier) {
                            setSource(prev => ({ ...prev, supplier: CustomerBaseFromCustomer(response.data as Customer) }));
                        }
                        else {
                            setSource(prev => ({ ...prev, customer: CustomerBaseFromCustomer(response.data as Customer) }));
                        }
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    // Zobrazenie zákazníkov
    const handleCustomersOpen = (supplier: boolean) => {
        setCustomersDialog(prev => ({ ...prev, argument: supplier, filter: { supplier: (supplier === true ? 1 : 2) }, open: true }));
    };
    const handleCustomersClose = () => {
        setCustomersDialog(prev => ({ ...prev, open: false }));
    };

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="md" fullWidth open={props.open} scroll="body" disableEnforceFocus onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((source?.id ?? 0) === 0 && localization.new + ': ')} {localization.invoice}
                        {(props.copy && ' (' + localization.copied + ')')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} sm={8}>
                                <TextField margin="dense" name="subject" label={localization.subject} fullWidth variant="outlined" autoComplete="off" value={source.subject ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'subject' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={6} md={2}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={GetDateLocale()}>
                                    <FormControl margin="dense" fullWidth required>
                                        <DatePicker format='dd.MM.yyyy' label={localization.date} value={NullMinDate(source.date)} onChange={(d) => { handleChange('date', d) }} inputRef={input => { props.autoFocus === 'date' && setFocus(input) }} />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} md={2}>
                                <TextField fullWidth margin="dense" name="number" type="number" label={localization.number + ((source.id ?? 0) === 0 ? ' (' + localization.automatically.toLowerCase() + ')' : '')} variant="outlined" value={source.number ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'number' && setFocus(input)}
                                    inputProps={{ min: 0, max: 32767 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <NumbersIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} mt={2}>
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('supplier')} onChange={() => handleToggleAccordion('supplier')}>
                                    <AccordionSummary>
                                        <Typography>{localization.supplier}{(source.supplier?.name ?? '').length > 0 && <>:&nbsp;<strong> {source.supplier?.name ?? ''}</strong></>}</Typography>
                                        <Button variant={(source.supplier?.id ?? 0) > 0 ? 'outlined' : 'contained'} startIcon={<PersonAddIcon />} onClick={(e) => { e.stopPropagation(); handleCustomersOpen(true); }} size='small' color='info' sx={{ position: 'absolute', top: 7, right: 7, width: 120 }}>{localization.select}</Button>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <CustomerCreateBase
                                                customer={source.supplier ?? {}}
                                                countries={props.countries}
                                                autoFocus={props.autoFocus}
                                                setFocus={setFocus}
                                                onChange={handleChangeSupplier}
                                            />
                                            {(source.supplier?.id ?? 0) > 0 && (
                                                <Grid item mt={2}>
                                                    <Button variant='contained' startIcon={<PersonIcon />} size='small' sx={{ mb: 1, mr: 0.5 }} href={AppRouteUrl.CUSTOMERS + '?id=' + (source.supplier?.id ?? 0).toString()} target="_blank">Detail</Button>
                                                    <Button variant='outlined' size='small' sx={{ mb: 1, mr: 1 }} onClick={() => setSource(prev => ({ ...prev, supplier: { ...prev.supplier, id: 0 } }))}>Zrušiť prepojenie</Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('customer')} onChange={() => handleToggleAccordion('customer')}>
                                    <AccordionSummary>
                                        <Typography>{localization.customer}{(source.customer?.name ?? '').length > 0 && <>:&nbsp;<strong> {source.customer?.name ?? ''}</strong></>}</Typography>
                                        <Button variant={(source.customer?.id ?? 0) > 0 ? 'outlined' : 'contained'} startIcon={<PersonAddIcon />} onClick={(e) => { e.stopPropagation(); handleCustomersOpen(false); }} size='small' color='info' sx={{ position: 'absolute', top: 7, right: 7, width: 120 }}>{localization.select}</Button>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <CustomerCreateBase
                                                customer={source.customer ?? {}}
                                                countries={props.countries}
                                                autoFocus={props.autoFocus}
                                                setFocus={setFocus}
                                                onChange={handleChangeCustomer}
                                            />
                                            {(source.customer?.id ?? 0) > 0 && (
                                                <Grid item mt={2}>
                                                    <Button variant='contained' startIcon={<PersonIcon />} size='small' sx={{ mb: 1, mr: 0.5 }} href={AppRouteUrl.CUSTOMERS + '?id=' + (source.customer?.id ?? 0).toString()} target="_blank">Detail</Button>
                                                    <Button variant='outlined' size='small' sx={{ mb: 1, mr: 1 }} onClick={() => setSource(prev => ({ ...prev, customer: { ...prev.customer, id: 0 } }))}>Zrušiť prepojenie</Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('note')} onChange={() => handleToggleAccordion('note')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>{localization.note} {(source.note?.length ?? 0) > 0 ? ' (' + localization.yes.toLowerCase() + ')' : ''}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12}>
                                                <TextField fullWidth multiline rows={5} margin="dense" name="note" label={localization.note} variant="outlined" value={source.note ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputProps={{ maxLength: 1000 }}
                                                    inputRef={input => { props.autoFocus === 'note' && setFocus(input) }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EditIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid item xs={12} mt={3}>
                                {(source.items?.length ?? 0) > 0 &&
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ width: 70 }}>#</TableCell>
                                                <TableCell>{localization.item}</TableCell>
                                                <TableCell style={{ width: 150 }}>{localization.vatRate}</TableCell>
                                                <TableCell style={{ width: 150 }}>{localization.price}</TableCell>
                                                <TableCell style={{ width: 50 }}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {source.items?.map((item, index) => (
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                                                    <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                        <TextField fullWidth size="small" name="ordinalNumber" type="number" variant="outlined" value={item.ordinalNumber ?? ''} onChange={(e) => { handleChangeItems(index, e.target.name, ConvertToInt(e.target.value)) }}
                                                            inputRef={input => props.autoFocus === 'ordinalNumber' && setFocus(input)}
                                                            inputProps={{ min: 0, max: 32767 }} />
                                                    </TableCell>

                                                    <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                        <TextField fullWidth required size="small" name="name" type="text" variant="outlined" autoComplete="off" value={item.name ?? ''}
                                                            onChange={(e) => { handleChangeItems(index, e.target.name, e.target.value) }}
                                                            inputProps={{ maxLength: 1000 }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <EditIcon fontSize="small" />
                                                                    </InputAdornment>
                                                                ),
                                                            }} />
                                                    </TableCell>

                                                    <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                        <TextField fullWidth size="small" name="priceVatRate" type="text" variant="outlined" autoComplete="off" value={item.priceVatRate ?? ''}
                                                            onChange={(e) => { handleChangeItems(index, e.target.name, ConvertToDecimal(e.target.value)) }}
                                                            inputProps={{ maxLength: 2, onInput: ConvertToDecimalOnKeyDown }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <PercentIcon fontSize="small" />
                                                                    </InputAdornment>
                                                                ),
                                                            }} />
                                                    </TableCell>

                                                    <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                        <TextField fullWidth size="small" name="price" type="text" variant="outlined" autoComplete="off" value={item.price ?? ''}
                                                            onChange={(e) => { handleChangeItems(index, e.target.name, ConvertToDecimal(e.target.value)) }}
                                                            inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <EuroIcon fontSize="small" />
                                                                    </InputAdornment>
                                                                ),
                                                            }} />
                                                    </TableCell>

                                                    <TableCell style={{ padding: 0, textAlign: 'center' }}>
                                                        <IconButton aria-label="Delete" size="small" onClick={() => handleDeleteItems(index)}>
                                                            <DeleteIcon fontSize="small" />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                }
                                <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={() => handleAddItems()}>{localization.insertRow}</Button>

                                <InvoiceCreateSummary items={source.items} />
                            </Grid>

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>{localization.reset}</Button>
                        <Button disabled={loading} onClick={props.onClose}>{localization.back}</Button>
                        <Button disabled={loading} type="submit" variant="contained">{localization.save}</Button>
                    </DialogActions>
                </Box>

                {/* Výber zákazníka (ponechať vnorené v nadradenom dialogu aby sa spoločne odpojil a zachoval zIndex) */}
                <CustomersDialog {...customersDialog} />

            </Dialog >
        </>
    )
}

export default InvoiceCreate;