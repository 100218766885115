import { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { AppRouteUrl } from '../../AppRoutes';
import { Localize } from '../../AppLocalization';

// Modely
import { ProjectType } from '../../models/Models';

// Utility
import { FindText } from '../../utility/Search';
import { Export as DataGridExport, Settings as DataGridSettings, LocalizeGrid } from '../../utility/DataGrid';
import { useQuery } from '../../utility/URL';
import { format as dateFormat } from 'date-fns';

// Komponenty
import { DataGrid, GridColDef, GridValueFormatterParams, GridRenderCellParams, GridValueGetterParams, GridFilterModel, GridRowId, GridDensity } from "@mui/x-data-grid";
import { Button, Divider, Grid, IconButton, ListItemIcon, Menu, useTheme } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Search from '../../components/Search';
import { Content, ContentTop, ContentBottom } from '../../layout/Content';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import ProjectTypeCreate, { ProjectTypeCreateProps } from './ProjectTypeCreate';
import DataGridDensity from '../../components/DataGridDensity';

// Ikony
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import ClearIcon from '@mui/icons-material/Clear';

// Komponent pre zoznam
const ProjectTypes = () => {

    // Jazyková mutácia
    const localization = Localize();

    // Lokálny stav
    const [loading, setLoading] = useState<boolean>(true);
    const [search, setSearch] = useState('');
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [projecttypeCreate, setProjectTypeCreate] = useState<ProjectTypeCreateProps>({
        open: false,
        keepMounted: true,
        onSave: () => loadData(),
        onClose: () => setProjectTypeCreate(prev => ({ ...prev, open: false }))
    });

    // Nastavenie zobrazenia    
    const [density, setDensity] = useState<GridDensity>('standard' as GridDensity);

    // Nastavenia stĺpcov
    const gridSettings = new DataGridSettings({ uid: 'projecttypes' });
    const columnsDefault: GridColDef[] = [
        { field: 'id', headerName: 'Id', hide: true, minWidth: 20, width: 90, type: 'number', align: 'center', headerAlign: 'center' },
        { field: 'name', headerName: localization.title, hide: false, minWidth: 50, flex: 0.8 },
        { field: 'note', headerName: localization.note, hide: false, minWidth: 50, flex: 0.8 },
        { field: 'ordinalNumber', headerName: localization.ordinalNumber, hide: false, minWidth: 50, flex: 0.2, type: 'number', align: 'center', headerAlign: 'center' },
        {
            field: 'createdDate', headerName: localization.created, hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['createdDate']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? dateFormat((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        {
            field: 'updatedDate', headerName: localization.updated, hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => (new Date(params.row['updatedDate'])),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? dateFormat((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        {
            field: 'options', headerName: localization.options, hide: false, width: 120, sortable: false, filterable: false, align: 'right', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => (
                <>
                    <IconButton aria-label={localization.edit} title={localization.edit + ' (enter)'} size="small" onClick={() => handleCreate(params.value ?? 0, false)}>
                        <EditIcon color="primary" fontSize="small" />
                    </IconButton>
                    <IconButton aria-label={localization.copy} title={localization.copy + ' (ctrl + enter)'} size="small" onClick={() => handleCreate(params.value ?? 0, true)}>
                        <ContentCopyIcon fontSize="small" />
                    </IconButton>
                    <IconButton aria-label={localization.delete} title={localization.delete + ' (delete)'} size="small" onClick={() => handleDelete(params.value ?? 0, params.row.name)}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </>
            )
        }
    ];

    // Aplikujem uložené nastavenia
    useEffect(() => setColumns(gridSettings.columnApply(columns)), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Tabuľka
    const [rows, setRows] = useState<ProjectType[]>([]);
    const [rowsSelected, setRowsSelected] = useState<GridRowId[]>([])
    const [rowsSelectedMenuEl, setRowsSelectedMenuEl] = useState<null | HTMLElement>(null);
    const [rowsFiltered, setRowsFiltered] = useState<ProjectType[]>([]);
    const [rowsPageSize, setRowsPageSize] = useState<number>(gridSettings.pageSizeApply(25));
    const [columns, setColumns] = useState<GridColDef[]>(columnsDefault);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), []);

    // Pridať upraviť záznam
    const handleCreate = (id: number, copy?: boolean, field?: string) => {
        setProjectTypeCreate(prev => ({
            ...prev,
            id: id,
            copy: copy ?? false,
            open: true,
            autoFocus: field
        }));
    };

    // Úprava záznamu podľa "id" v URL
    const history = useHistory();
    const requestId: number = parseInt(useQuery().get('id') ?? '0');
    useEffect(() => {
        if (requestId > 0) {
            history.push(AppRouteUrl.PROJECT_TYPES);
            handleCreate(requestId);
        }
    }, [requestId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Vymazať záznam
    const handleDelete = (id: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: localization.deleteConfirmation, onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'projecttype/' + id)
                    .then(response => {
                        if (response.data === true) {
                            setRows(prev => [...prev.filter(r => r.id !== id)]);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    const handleDeleteList = (ids: number[]) => {
        if (ids.length === 0) {
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: localization.delete + ': ' + ids.length, children: localization.deleteSeletedConfirmation, onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'projecttype/list', {
                        params: {
                            'ids': ids
                        }
                    })
                    .then(response => {
                        if (response.data === true) {
                            setRows(prev => [...prev.filter(r => !ids.includes(r?.id ?? 0))]);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    const handleExportCSV = () => {
        if (rowsSelected.length === 0) {
            return;
        }
        DataGridExport({
            type: 'csv',
            columns: columns,
            columnsSkip: ['options'],
            rows: rows,
            ids: rowsSelected.map(r => r as number)
        });
    };

    const handleExportXML = () => {
        if (rowsSelected.length === 0) {
            return;
        }
        DataGridExport({
            type: 'xml',
            columns: columns,
            columnsSkip: ['options'],
            rows: rows,
            ids: rowsSelected.map(r => r as number)
        });
    };

    // Funkcia pre načítanie dát z API
    const loadData = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'projecttype')
            .then(response => {
                setRows(response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Vyhľadávanie (automaticky odfiltrujem záznamy po zmene hľadaného textu, alebo obnovení riadkov)
    useEffect(() => setRowsFiltered((search.length > 0 ? FindText(rows, search) : rows)), [rows, search]);

    return (
        <>
            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Formulár pre nový záznam */}
            <ProjectTypeCreate {...projecttypeCreate} />

            {/* Obsah */}
            <Content>
                <ContentTop>
                    <Grid container alignItems="center">

                        {/* Možnosti */}
                        <Grid item xs={9} md lg>

                            {/* Nový záznam */}
                            <Button variant="contained" sx={{ mr: 1 }} size="large" startIcon={<AddIcon />} onClick={() => handleCreate(0)}>{localization.newRecord}</Button>

                            {/* Označené záznamy (možnosti) */}
                            <Button variant="text" size="large" disabled={rowsSelected?.length === 0} aria-controls="menu-selected" aria-haspopup="true" onClick={(e) => setRowsSelectedMenuEl(e.currentTarget)} endIcon={<ExpandMoreIcon />}>{localization.selected} {'(' + rowsSelected.length.toString() + ')'}</Button>
                            <Menu id="menu-selected" anchorEl={rowsSelectedMenuEl} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} sx={{ mt: '55px' }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} open={Boolean(rowsSelectedMenuEl)} onClose={() => setRowsSelectedMenuEl(null)} >
                                <MenuItem onClick={() => { handleExportCSV(); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> {localization.saveAs} CSV (Excel)
                                </MenuItem>
                                <MenuItem onClick={() => { handleExportXML(); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> {localization.saveAs} XML
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { handleDeleteList(rowsSelected.map(r => r as number)); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon> {localization.delete}
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { setRowsSelected([]); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><ClearIcon fontSize="small" /></ListItemIcon> {localization.cancelSelection}
                                </MenuItem>
                            </Menu>
                        </Grid>

                        {/* Zobrazenie */}
                        <Grid item xs md={2} textAlign="right">
                            <DataGridDensity onDensityChanged={setDensity} />
                        </Grid>

                        {/* Vyhľadávanie */}
                        <Grid item xs={12} md={4} sx={{ mt: { xs: 1, md: 0 } }}>
                            <Search onSearch={s => setSearch(s)}
                                onClear={() => {
                                    setSearch('');
                                    setFilterModel({ items: [], linkOperator: undefined });
                                }}
                                autoFocus={true} />
                        </Grid>
                    </Grid>
                </ContentTop>
                <ContentBottom>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <DataGrid
                            density={density}
                            checkboxSelection
                            disableSelectionOnClick
                            columns={columns}
                            rows={rowsFiltered}
                            localeText={LocalizeGrid()}
                            loading={loading}

                            rowsPerPageOptions={[10, 25, 50]}
                            pageSize={rowsPageSize}
                            onPageSizeChange={(pageSize) => {
                                setRowsPageSize(pageSize);
                                 gridSettings.pageSizeChanged(pageSize);
                            }}
                            
                            // Dvoj-klik (úprava)
                            onCellDoubleClick={(e) => {
                                handleCreate(e.row.id, false, e.field);
                            }}

                            // Klávesnica (shift+enter => upraviť, shift+delete => vymazať, shift+space => označiť, vstavaná funkcia)
                            onCellKeyDown={(e, c) => {
                                if (c.code === 'Enter' && c.ctrlKey) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    handleCreate(e.row.id, true, e.field);
                                    return;
                                }
                                if (c.code === 'Enter' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    handleCreate(e.row.id, false, e.field);
                                    return;
                                }
                                if (c.code === 'Delete' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    handleDelete(e.row.id, e.row.name);
                                    return;
                                }
                            }}

                            // Filtrácia
                            filterModel={filterModel}
                            onFilterModelChange={e => setFilterModel(e)}

                            // Vybrané záznamy
                            selectionModel={rowsSelected}
                            onSelectionModelChange={e => setRowsSelected(e)}

                            // Stĺpce (automatické ukladanie nastavení)
                            onColumnVisibilityChange={e => gridSettings.columnVisibilityChanged(e, columnsDefault)}
                        />
                    </div>
                </ContentBottom>
            </Content>

        </>
    )
}

export default ProjectTypes;