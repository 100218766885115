import { FormEvent, useEffect, useState } from 'react';
import { Localize } from '../../AppLocalization';

// Komponenty
import { Dialog, Box, DialogTitle, DialogContent, Grid, Alert, AlertColor, DialogActions, Button } from '@mui/material';
import TinyMCE from '../../components/TinyMCE';

// Modely
import { TaskNote } from '../../models/Models';

// Ikony

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): TaskNote => ({
    id: 0,
    note: ''
});

// Vstupné parametre
export interface TaskCreateNoteProps {
    note?: TaskNote;
    noteIndex?: number;
    open: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave: (note: TaskNote, noteIndex?: number) => void;
    onClose: () => void;
}

const TaskCreateNote = (props: TaskCreateNoteProps) => {

    // Jazyková mutácia
    const localization = Localize();

    // Stav
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<TaskNote>(EmptySource());

    // Automaticky vyplním okno
    useEffect(() => {
        if (!props.open) {
            return;
        }
        // Použijem props.item alebo emptySource
        var data = props.note ?? { ...EmptySource() };
        setSource(data);
    }, [props.note, props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => input?.focus(), 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    // Reset formuláru (resetujem iba tie hodnoty, ktoré viem upraviť cez tento formulár)
    const handleReset = () => {
        setSource(prev => ({
            ...prev,
            noteId: undefined,
            result: undefined,
            note: ''
        }))
    };

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        // Minimálne 3 znaky = 10 znakov: "<p>123</p>"
        if ((source.note ?? '').length < 10) {
            setWarning({ ...EmptyWarning, text: localization.mustFill + ': ' + localization.note + ' (' + localization.textIsTooShort.toLowerCase() + ')!' });
            return;
        }

        props.onSave({ ...source }, props.noteIndex);
        props.onClose();
    };

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }

    return (
        <>
            <Dialog maxWidth="md" fullWidth disableEnforceFocus open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick' && props.onClose) { props.onClose(); } }}>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {props.noteIndex === undefined && localization.new + ': '} {localization.note}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>
                            <Grid item xs={12}>
                                <TinyMCE onChange={(s) => handleChange('note', s)} content={source.note ?? ''} height={400} />
                            </Grid>
                        </Grid>
                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleReset} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>{localization.reset}</Button>
                        <Button onClick={props.onClose}>{localization.back}</Button>
                        <Button type="submit" variant="contained">{localization.save}</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default TaskCreateNote;