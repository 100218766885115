import { FormEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { GetDateLocale, Localize } from '../../AppLocalization';
import { AppRouteUrl } from '../../AppRoutes';
import { AppContext } from '../../AppContext';

// Utility
import { NullMinDate } from '../../utility/Date';
import { format as dateFormat } from 'date-fns';
import { addDays, addHours, addMinutes } from 'date-fns';

// Modely
import { PriorityColors, SaveAction, Task, TaskFilter, TaskLite, TaskState, TaskTemplate, TaskType, User } from '../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, Typography, Accordion, AccordionDetails, AccordionSummary, FormControl, InputLabel, MenuItem, Select, Chip, Avatar, ListItemAvatar, ListItemText, FormControlLabel, Switch, Divider, ListItemIcon, Tab, Tabs, Menu, ButtonGroup, Link, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { ArrowDropDownIcon, LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TinyMCE from '../../components/TinyMCE';
import FileItems from '../file_item/FileItems';
import { TabContext, TabPanel } from '@mui/lab';
import TasksSimple from './TasksSimple';
import TaskCreateNotes from './TaskCreateNotes';
import TaskCreateHistory from './TaskCreateHistory';
import Confirm, { ConfirmProps } from '../../components/Confirm';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import AddBoxIcon from '@mui/icons-material/AddBox';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Predvolený čas "od teraz" pre novú úlohu
const TaskDefaultTime = {
    toStartHours: 1, // from now
    toEndHours: 1, // from start
    toFinishDays: 1 // from end
}

// Prázdny záznam (predvolený)
const EmptySource = (): Task => {
    let start = addHours(new Date(new Date().toDateString()), new Date().getHours() + TaskDefaultTime.toStartHours);
    let end = addHours(start, TaskDefaultTime.toEndHours);
    let finish = addDays(end, TaskDefaultTime.toFinishDays);
    return ({
        id: 0,
        taskId: 0,
        taskTypeId: 0,
        taskStateId: 0,
        start: start,
        end: end,
        finish: finish,
        priority: 5,
        name: '',
        description: '',
        reminder: false,
        users: []
    });
};

// Vstupné parametre
export interface TaskCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    task?: Task; // predvyplnený záznam, ktorý sa aplikujem za emptysource
    autoFocus?: string;
    keepMounted?: boolean;
    taskStates: TaskState[];
    taskTypes: TaskType[];
    taskTemplates: TaskTemplate[];
    users: User[];
    onTasksChanged?: () => void; // udalosť pre oznámenie zmeny vnorených úloh
    onSave?: (id?: number, action?: SaveAction) => void;
    onClose: () => void;
}

const TaskCreate = (props: TaskCreateProps) => {

    // Všeobecne
    const localization = Localize();
    const formRef = useRef<HTMLFormElement>(null);
    const formRefNameEl = useRef<HTMLInputElement>(null);
    const context = useContext(AppContext);

    // Stav
    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<Task>(EmptySource());
    const [tabValue, setTabValue] = useState<string>('description');
    const [saveMenuEl, setSaveMenuEl] = useState<null | HTMLElement>(null);

    // Harmonika
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);

    // Funkcie pre získanie informácie o tom, či je harmonika otvorená a zmenu stavu
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            setTabValue('description');
            setAccordionExpanded([]);
            return;
        }

        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        handleReset();
        if ((props.id ?? 0) === 0) {
            return;
        }

        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'task/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    let entity = response.data as Task;
                    if (props.copy) {
                        entity.id = 0;
                        entity.notes = [];
                        entity.files = [];
                        entity.stateHistory = [];
                    }
                    setSource(entity);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {

        // Nemením hodnotu pri čiastočne zadanom dátume "Invalid Date" (kontrolovaný vstup by sa nedal prepísať)
        if (property === 'start' || property === 'end' || property === 'finish' || property === 'reminderDate') {
            if (value !== null && isNaN(value)) {
                return;
            }
        }

        setSource(prev => {
            var changed = ({ ...prev, [property]: value });

            // Automatická úprava po zmene: start
            if (property === 'start' && NullMinDate(value) && NullMinDate(prev.start) !== null && NullMinDate(prev.end) !== null) {
                var start = NullMinDate(prev.start)?.getTime() ?? 0;
                var end = NullMinDate(prev.end)?.getTime() ?? 0;
                var diffMs = end - start;
                var diffMins = Math.round((diffMs / 1000) / 60);
                if (diffMins < 0) {
                    diffMins = 0;
                }
                changed.end = addMinutes(value, diffMins);
            }

            // Automatická úprava po zmene: start
            if (property === 'start' && NullMinDate(value) && NullMinDate(prev.start) !== null && NullMinDate(prev.finish) !== null) {
                var start = NullMinDate(prev.start)?.getTime() ?? 0;
                var finish = NullMinDate(prev.finish)?.getTime() ?? 0;
                var diffMs = finish - start;
                var diffMins = Math.round((diffMs / 1000) / 60);
                if (diffMins < 0) {
                    diffMins = 0;
                }
                changed.finish = addMinutes(value, diffMins);
            }

            // Automatické doplnenie času pripomienky
            if (property === 'reminder' && value === true && NullMinDate(prev.start) !== null && NullMinDate(prev.reminderDate) === null) {
                changed.reminderDate = addMinutes((prev.start ?? new Date()), -60);
            }

            return changed;
        });
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Reset formuláru
    const handleReset = (keepId?: boolean) => {
        setSource(prev => ({
            ...EmptySource(),
            id: (keepId ?? false) === true ? prev.id : 0,
            taskStateId: props.taskStates[0]?.id ?? 0,
            ...(props.task ?? {}) // ako posledné
        }));
    };

    // Uloženie formuláru
    const handleSave = (event?: FormEvent<HTMLFormElement>, action?: SaveAction) => {
        if (event !== undefined) {
            event.preventDefault();
            event.stopPropagation();
        }

        // Manuálna kontrola
        if (event === undefined && formRef) {
            if (formRef.current?.checkValidity() === false) {
                setWarning({ ...EmptyWarning, text: localization.mustFileRequiredFields });
                return;
            }
        }

        // Povinné údaje
        if ((source.reminder ?? false) === true && NullMinDate(source.reminderDate) === null) {
            setWarning({ ...EmptyWarning, text: localization.mustFill + ': ' + localization.reminderTime + '!' });
            if (!isAccordionExpanded('reminder')) {
                handleToggleAccordion('reminder');
            }
            return;
        }

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'task', source)
            .then(response => {
                if (response.data > 0) {
                    props.onClose();
                    if (props.onSave) {
                        props.onSave();
                        props.onSave((response.data ?? 0) as number, action);
                    }
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: localization.saveFailed });
            })
            .finally(() => {
                setLoading(false);
            });
    };
    
    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => input?.focus(), 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    // Automatická zmena farby okna
    const [dialogColor, setDialogColor] = useState<string>('');

    useEffect(() => {
        var color = props.taskStates.find(item => item.id === (source.taskStateId ?? 0))?.color ?? 'f0f0f0';
        if (dialogColor !== color) {
            setDialogColor(color);
        }
    }, [source.taskStateId]);

    // ---------------------------------------------------------------------------------
    // Vnorené úlohy
    // ---------------------------------------------------------------------------------

    const [tasks, setTasks] = useState<TaskLite[]>([]);
    const [tasksFilter, setTasksFilter] = useState<TaskFilter>({ page: 0, pageSize: 999 });

    // Načítanie úloh po zmene id nadradenej úlohy
    const loadTasksSafe = () => setTasksFilter(prev => ({ ...prev }));
    const loadTasks = () => {
        if ((source.id ?? 0) === 0) {
            setTasks([]);
            return;
        }
        axios
            .get(AppConfig.ApiUri + 'task', {
                params: { ...tasksFilter, taskId: props.id } as TaskFilter
            })
            .then(response => setTasks((response.data?.list ?? []) as TaskLite[]));

    };
    useEffect(() => loadTasksSafe(), [source.id]);
    useEffect(() => loadTasks(), [tasksFilter]);

    const [taskCreate, setTaskCreate] = useState<TaskCreateProps>({
        open: false,
        keepMounted: false,
        taskStates: [],
        taskTypes: [],
        taskTemplates: [],
        users: [],
        onSave: (id?: number, action?: SaveAction) => {
            loadTasksSafe(); // Obnovím zoznam
            props.onTasksChanged?.(); // Oznámim zmenu

            // Rozšírené funkcie po uložení
            if (id !== undefined && action !== undefined) {
                if (action === SaveAction.SaveAndNew) {
                    handleTaskCreate(0);
                    return;
                }
                if (action === SaveAction.SaveAndContinue) {
                    handleTaskCreate(id ?? 0);
                    return;
                }
                if (action === SaveAction.SaveAndNewFromCopy) {
                    handleTaskCreate(id ?? 0, true);
                    return;
                }
            }
        },
        onClose: () => setTaskCreate(prev => ({ ...prev, id: 0, copy: false, task: undefined, open: false }))
    });

    // Pridať upraviť záznam
    const handleTaskCreate = (id: number, copy?: boolean) => setTaskCreate(prev => ({
        ...prev,
        id: id,
        copy: copy ?? false,
        open: true,
        task: {
            taskId: props.id,
            taskName: source.name,
            users: source.users
        }
    }));

    // Vymazať záznam
    const handleTaskDelete = (id: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: localization.deleteConfirmation, onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'task/' + id)
                    .then(response => {
                        if (response.data === true) {
                            loadTasks();
                            props.onTasksChanged?.(); // Oznámim zmenu
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    // ---------------------------------------------------------------------------------
    // Šablóny
    // ---------------------------------------------------------------------------------

    // Zoznam šablón podľa vybraného typu (obnovím automaticky)
    const [taskTemplatesByType, setTaskTemplatesByType] = useState<TaskTemplate[]>([]);
    const [taskTemplateToUse, setTaskTemplateToUse] = useState<number>(0);
    const [taskTemplateMenuEl, setTaskTemplateMenuEl] = useState<null | HTMLElement>(null);

    useEffect(() => {
        setTaskTemplatesByType(props.taskTemplates?.filter(t => t.taskTypeId === (source.taskTypeId ?? 0)) ?? []);
    }, [source.id, source.taskTypeId, props.taskTemplates]);


    useEffect(() => {
        if (taskTemplateToUse === 0) {
            return;
        }
        // Uchovám id šablóny
        const templateId = taskTemplateToUse;

        // Vyresetujem šablónu pre použite
        setTaskTemplateToUse(0);

        // Načítam šablónu
        axios
            .get(AppConfig.ApiUri + 'tasktemplate/' + templateId)
            .then(response => {
                if (response.data !== null) {
                    const template = response.data as TaskTemplate;

                    // Aplikujem údaje
                    setSource(prev => {

                        // Dni prepočítam na hodiny, hodiny na minúty, aby som mohol používať desatinné číslo, napr. 0.5 hod = 30 minút
                        const start = NullMinDate(prev.start) ?? new Date(new Date().toDateString());
                        const end = addMinutes(start, (template.endHours ?? 0) * 60);
                        const date = addHours(start, (((template.startDays ?? 0) * -1)) * 24);  // negujem aby som získal dátum
                        const finish = addHours(date, (template.finishDays ?? 0) * 24);
                        const reminder = addMinutes(start, ((template.reminderHours ?? 0) * 60));

                        return ({
                            ...prev,
                            end: end,
                            finish: finish,
                            name: template.name ?? prev.name,
                            description: template.description ?? prev.description,
                            priority: template.priority ?? prev.priority,
                            reminder: template.reminder ?? prev.reminder,
                            reminderDate: (template.reminder ?? false) === true ? reminder : undefined,
                            reminderText: template.reminderText ?? prev.reminderText,
                        });
                    });

                    // Nastavím focus na názov
                    setTimeout(() => formRefNameEl?.current?.focus(), 50);
                }
            });
    }, [taskTemplateToUse]);

    return (
        <>
            {/* Formulár pre nový záznam (vnorený) */}
            {(props.open && taskCreate.open && (props.id ?? 0) > 0 && <TaskCreate {...taskCreate} onTasksChanged={props.onTasksChanged} taskStates={props.taskStates} taskTypes={props.taskTypes} taskTemplates={props.taskTemplates} users={props.users} />)}

            <Dialog keepMounted={props.keepMounted ?? false} PaperProps={{ style: { borderLeft: '10px solid #' + (dialogColor.length ? dialogColor : 'f0f0f0') } }}
                maxWidth="md" fullWidth open={props.open} scroll="body" disableEnforceFocus onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>

                {/* Preloader  */}
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                {/* Potvrdzovacie okno */}
                <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

                <Box ref={formRef} component="form" onSubmit={handleSave}>
                    <DialogTitle sx={{ color: '#' + (dialogColor.length ? dialogColor : 'f0f0f0'), px: 3 }} noWrap>
                        {((source?.id ?? 0) === 0 && localization.new + ': ')} {(source.name?.length ?? 0) > 0 ? source.name : localization.task} {(props.copy && ' (' + localization.copied + ')')}
                        {(source.taskName?.length ?? 0) > 0 && (
                            <Typography>{localization.forTask}: <Link color="inherit" href={AppRouteUrl.TASKS + '?id=' + (source.taskId ?? 0).toString()} target="_blank">{source.taskName}</Link></Typography>
                        )}
                        {(source.projectName?.length ?? 0) > 0 && (
                            <Typography>{localization.forProject}: <Link color="inherit" href={AppRouteUrl.PROJECTS + '?id=' + (source.projectId ?? 0).toString()} target="_blank">{source.projectName}</Link></Typography>
                        )}
                    </DialogTitle>
                    <DialogContent sx={{ pb: 0 }}>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} sm={6}>
                                <TextField required margin="dense" name="name" label={localization.title} fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}

                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        inputRef: formRefNameEl,
                                        endAdornment: (
                                            taskTemplatesByType.length > 0 ?
                                                <IconButton size="small" title={localization.taskTemplates} sx={{ backgroundColor: 'whitesmoke' }} onClick={(e) => setTaskTemplateMenuEl(e.currentTarget.parentElement)}>
                                                    <ExpandMoreIcon />
                                                </IconButton>
                                                :
                                                <InputAdornment position="end">
                                                    <EditIcon />
                                                </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Menu id="menuTaskState" anchorEl={taskTemplateMenuEl} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'left' }} open={Boolean(taskTemplateMenuEl)} onClose={() => setTaskTemplateMenuEl(null)} >
                                {taskTemplatesByType.map(item => (<MenuItem onClick={() => { setTaskTemplateToUse(item.id ?? 0); setTaskTemplateMenuEl(null); }} key={item.id} value={item.id} sx={{ minWidth: '215px' }}>{item.name}</MenuItem>))}
                            </Menu>

                            <Grid item xs={12} sm={3}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="taskcreate-task-type">{localization.taskType}</InputLabel>
                                    <Select labelId="taskcreate-task-type" label={localization.taskType} value={source.taskTypeId ?? 0} name="taskTypeId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => { props.autoFocus === 'taskTypeId' && setFocus(input) }}>
                                        <MenuItem key={0} value={0}>-</MenuItem>
                                        {props.taskTypes?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="taskcreate-task-state">{localization.taskState}</InputLabel>
                                    <Select labelId="taskcreate-task-state" label={localization.taskState} value={source.taskStateId ?? 0} name="taskStateId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => { props.autoFocus === 'taskStateId' && setFocus(input) }}
                                        renderValue={(value) => {
                                            if (value === 0) {
                                                return <>-</>
                                            }
                                            var state = props.taskStates.find(item => item.id === value) ?? { name: '', color: '333333' };
                                            return (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: .3, height: 22 }}>
                                                    <Chip size="small" key={value} label={state.name} sx={{ m: 0, bgcolor: '#' + ((state.color ?? '').length > 0 ? state.color : 'f0f0f0'), color: ((state.color ?? '').length > 0 ? '#ffffff' : '#222222') }} />
                                                </Box>
                                            )
                                        }}>
                                        <MenuItem key={0} value={0}>-</MenuItem>
                                        {props.taskStates.map(item => (<MenuItem key={item.id} value={item.id} sx={{ color: '#' + ((item.color ?? '').length > 0 ? item.color : 'f0f0f0') }}>{item.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>



                            <Grid item xs={12} sm={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={GetDateLocale()}>
                                    <FormControl margin="dense" fullWidth required>
                                        <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label={localization.start} value={NullMinDate(source.start)} onChange={(d) => { handleChange('start', d) }} slotProps={{ textField: { required: true } }} inputRef={input => { props.autoFocus === 'start' && setFocus(input) }} />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={GetDateLocale()}>
                                    <FormControl margin="dense" variant="outlined" fullWidth>
                                        <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label={localization.end} value={NullMinDate(source.end)} onChange={(d) => { handleChange('end', d) }} inputRef={input => { props.autoFocus === 'end' && setFocus(input) }} />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={GetDateLocale()}>
                                    <FormControl margin="dense" variant="outlined" fullWidth>
                                        <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label={localization.deadline} value={NullMinDate(source.finish)} onChange={(d) => { handleChange('finish', d) }} inputRef={input => { props.autoFocus === 'finish' && setFocus(input) }} />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="user-create-role">{localization.priority}</InputLabel>
                                    <Select labelId="user-create-role" label={localization.priority} value={source.priority ?? 0} name="priority" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => { props.autoFocus === 'priority' && setFocus(input) }}
                                        renderValue={(value) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: .3, height: 22 }}>
                                                <Chip size="small" key={value} label={value} sx={{ m: 0, bgcolor: '#' + PriorityColors[value - 1], color: '#ffffff' }} />
                                            </Box>)}>
                                        <MenuItem value={1} sx={{ color: '#' + PriorityColors[0] }}>1 ({localization.highest.toLowerCase()})</MenuItem>
                                        <MenuItem value={2} sx={{ color: '#' + PriorityColors[1] }}>2</MenuItem>
                                        <MenuItem value={3} sx={{ color: '#' + PriorityColors[2] }}>3</MenuItem>
                                        <MenuItem value={4} sx={{ color: '#' + PriorityColors[3] }}>4</MenuItem>
                                        <MenuItem value={5} sx={{ color: '#' + PriorityColors[4] }}>5 ({localization.lowest.toLowerCase()})</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            {(context.user?.admin ?? false) === true && (
                                <Grid item xs={12}>
                                    <FormControl margin="dense" fullWidth>
                                        <InputLabel id="tasks-create-users">{localization.users}</InputLabel>
                                        <Select multiple labelId="tasks-create-users" label={localization.users} name="users" value={source.users ?? []}
                                            onChange={(e) => {
                                                let values = e.target.value as number[];
                                                if (values.includes(-1)) {
                                                    values = Array.from(props.users?.map(user => user.id ?? 0) ?? []);
                                                }
                                                if (values.includes(-2)) {
                                                    values = [];
                                                }
                                                handleChange(e.target.name, values);
                                            }}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, py: 0, /*height: 22*/ }}>
                                                    {selected.map((value) => {
                                                        var user = props.users.find(item => item.id === value) ?? { name: '' };
                                                        return (
                                                            <Box key={value} sx={{ display: 'inline-block', position: 'relative', paddingLeft: '35px' }}>
                                                                <Box sx={{ position: 'absolute', top: '-5px', left: '-5px' }}>
                                                                    {(
                                                                        user.fileIsImage ?
                                                                            <Avatar sx={{ width: 32, height: 32 }} alt={user.name} src={user.fileSrcSmall} /> :
                                                                            <Avatar sx={{ width: 32, height: 32 }}><PersonIcon /></Avatar>
                                                                    )}
                                                                </Box>
                                                                {user.name}
                                                            </Box>
                                                        )
                                                    })}
                                                </Box>
                                            )}>
                                            {props.users.map(user => (
                                                <MenuItem key={user.id} value={user.id}>
                                                    <ListItemAvatar>
                                                        {user.fileIsImage ?
                                                            <Avatar alt={user.name} src={user.fileSrcSmall} /> :
                                                            <Avatar><PersonIcon /></Avatar>}
                                                    </ListItemAvatar>
                                                    <ListItemText>
                                                        {user.name}
                                                    </ListItemText>
                                                </MenuItem>
                                            ))}
                                            <Divider />
                                            <MenuItem key={-1} value={-1}>
                                                <ListItemIcon>
                                                    <CheckBoxIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={localization.selectAll} />
                                            </MenuItem>
                                            <MenuItem key={-2} value={-2}>
                                                <ListItemIcon>
                                                    <CheckBoxOutlineBlankIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={localization.cancelSelection} />
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}

                            <Grid item xs={12} mt={2}>
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('reminder')} onChange={() => handleToggleAccordion('reminder')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>{localization.reminder + (source.reminder === true && NullMinDate(source.reminderDate) !== null ? ': ' + dateFormat(NullMinDate(source.reminderDate) ?? new Date(), 'dd.MM.yyyy HH:mm') : '')}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>

                                            <Grid item xs={12} md mb={1}>
                                                <FormControlLabel sx={{ mt: 2 }} control={<Switch checked={source.reminder} name="reminder" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label={localization.active} />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={GetDateLocale()}>
                                                    <FormControl margin="dense" fullWidth required>
                                                        <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label={localization.reminderTime} value={NullMinDate(source.reminderDate)} onChange={(d) => { handleChange('reminderDate', d) }} slotProps={{ textField: { required: (source.reminder === true) } }} inputRef={input => { props.autoFocus === 'reminderDate' && setFocus(input) }} />
                                                    </FormControl>
                                                </LocalizationProvider>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextField fullWidth multiline rows={5} margin="dense" name="reminderText" label={localization.reminderText} variant="outlined" value={source.reminderText ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputProps={{ maxLength: 1000 }}
                                                    inputRef={input => { props.autoFocus === 'reminderText' && setFocus(input) }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EditIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid item xs={12} mt={2} mb={0}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={tabValue} onChange={(e, v) => setTabValue(v)} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                                        <Tab value="description" label={localization.description + ((source.description ?? '').length > 0 ? ' (' + localization.yes.toLowerCase() + ')' : '')} />
                                        <Tab value="tasks" label={localization.tasks + ' (' + (tasks.length ?? 0) + ')'} />
                                        <Tab value="notes" label={localization.notes + ' (' + (source.notes?.length ?? 0) + ')'} />
                                        <Tab value="files" label={localization.files + ' (' + (source.files?.length ?? 0) + ')'} />
                                        <Tab value="history" label={localization.history + ' (' + (source.stateHistory?.length ?? 0) + ')'} />
                                    </Tabs>
                                </Box>
                                <TabContext value={tabValue}>
                                    <TabPanel value="description" sx={{ px: 0 }}>
                                        <TinyMCE onChange={(s) => handleChange('description', s)} content={source.description ?? ''} />
                                    </TabPanel>
                                    <TabPanel value="tasks" sx={{ px: 0 }}>
                                        <Grid container columnSpacing={1}>
                                            {(source.id ?? 0) === 0 && (
                                                <Grid item xs={12} mb={0}>
                                                    <Alert severity='info' action={<Button variant='contained' color="info" size="small" onClick={(e) => handleSave(undefined, SaveAction.SaveAndContinue)}>{localization.saveAndContinue}</Button>}>
                                                        {localization.onlyForSavedRecords}
                                                    </Alert>
                                                </Grid>
                                            )}
                                            {(source.id ?? 0) > 0 && (
                                                <Grid item xs={12} mb={0}>
                                                    <Button variant="contained" size="small" startIcon={<AddIcon />} color="secondary" sx={{ mr: 1 }} onClick={() => handleTaskCreate(0)}>{localization.newRecord}</Button>
                                                </Grid>
                                            )}
                                            {(tasks.length ?? 0) > 0 && (
                                                <Grid item xs={12} mt={2}>
                                                    <TasksSimple tasks={tasks} taskStates={props.taskStates} onCreate={(id: number, copy?: boolean) => handleTaskCreate(id, copy)} onDelete={(id: number, name: string) => handleTaskDelete(id, name)} />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value="notes" sx={{ px: 0 }}>
                                        <TaskCreateNotes source={source} setSource={setSource} users={props.users} />
                                    </TabPanel>
                                    <TabPanel value="files" sx={{ px: 0 }}>
                                        <FileItems items={source.files} onChanged={(files) => handleChange('files', files)} />
                                    </TabPanel>
                                    <TabPanel value="history" sx={{ px: 0 }}>
                                        <TaskCreateHistory users={props.users} taskStates={props.taskStates} stateHistory={source.stateHistory} />
                                    </TabPanel>
                                </TabContext>
                            </Grid>

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => handleReset(true)} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>{localization.reset}</Button>
                        <Button disabled={loading} onClick={props.onClose}>{localization.back}</Button>
                        <ButtonGroup disabled={loading} variant="contained" aria-label="Uložiť">
                            <Button type="button" variant="contained" size="small" aria-controls="save-menu" aria-haspopup="true" onClick={(e) => setSaveMenuEl(e.currentTarget)}>
                                <ArrowDropDownIcon />
                            </Button>
                            <Button type="submit" variant="contained">{localization.save}</Button>
                        </ButtonGroup>
                    </DialogActions>
                </Box>

                <Menu id="save-menu" anchorEl={saveMenuEl} open={Boolean(saveMenuEl)} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} transformOrigin={{ vertical: 'bottom', horizontal: 'left', }} onClose={() => setSaveMenuEl(null)}>
                    <MenuItem onClick={() => { handleSave(undefined, SaveAction.SaveAndContinue); setSaveMenuEl(null); }}>
                        <ListItemIcon><SaveIcon fontSize="small" /></ListItemIcon> {localization.saveAndContinue}
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => { handleSave(undefined, SaveAction.SaveAndNew); setSaveMenuEl(null); }}>
                        <ListItemIcon><AddBoxIcon fontSize="small" /></ListItemIcon> {localization.saveAndNew}
                    </MenuItem>
                    <MenuItem onClick={() => { handleSave(undefined, SaveAction.SaveAndNewFromCopy); setSaveMenuEl(null); }}>
                        <ListItemIcon><AddBoxIcon fontSize="small" /></ListItemIcon> {localization.saveAndNewFromCopy}
                    </MenuItem>
                </Menu>

            </Dialog>
        </>
    )
}

export default TaskCreate;