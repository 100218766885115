import { useContext } from 'react';
import { Localize } from '../AppLocalization';
import { AppContext } from '../AppContext';

// Ikony
import PeopleIcon from '@mui/icons-material/People';
import TuneIcon from '@mui/icons-material/Tune';
import PublicIcon from '@mui/icons-material/Public';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TopicIcon from '@mui/icons-material/Topic';
import PetsIcon from '@mui/icons-material/Pets';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GroupsIcon from '@mui/icons-material/Groups';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import MenuIcon from '@mui/icons-material/Menu';
import HouseSidingIcon from '@mui/icons-material/HouseSiding';

// Podstránky
import { AppRouteUrl } from '../AppRoutes';
import Navigation, { Item } from '../components/Navigation';

// Vstupné parametre
interface SidebarProps {
    open: boolean;
    onClickToggle?: () => void;
}

const Sidebar = (props: SidebarProps) => {

    // Globálne premenné
    const context = useContext(AppContext);

    // Jazyková mutácia
    const localization = Localize();

    // Zoznam položiek menu 
    const items: Item[] = [

        // Agendy
        { path: AppRouteUrl.ACTIVITIES, title: localization.activities, icon: <DynamicFeedIcon /> },
        { path: AppRouteUrl.TASKS, title: localization.tasks, icon: <CalendarMonthIcon /> },
        { path: AppRouteUrl.PROJECTS, title: localization.projects, icon: <TopicIcon /> },
        { path: AppRouteUrl.PRODUCTIONS, title: localization.productions, icon: <PetsIcon /> },
        { path: AppRouteUrl.INVOICES, title: localization.invoices, icon: <ReceiptLongIcon /> },
        { path: AppRouteUrl.CUSTOMERS, title: localization.addressBook, icon: <GroupsIcon /> },

        // Nastavenia
        ...(context.user?.admin ?? false ? [{
            path: AppRouteUrl.USERS, title: localization.settings, icon: <TuneIcon />,
            items:
                [
                    { path: AppRouteUrl.USERS, title: localization.users, icon: <PeopleIcon /> },
                    { path: AppRouteUrl.TASK_TEMPLATES, title: localization.taskTemplates, icon: <AssignmentIcon /> },
                    { path: AppRouteUrl.TASK_STATES, title: localization.taskStates, icon: <TimelapseIcon /> },
                    { path: AppRouteUrl.TASK_TYPES, title: localization.taskTypes, icon: <MenuIcon /> },
                    { path: AppRouteUrl.PROJECT_STATES, title: localization.projectStates, icon: <TimelapseIcon /> },
                    { path: AppRouteUrl.PROJECT_TYPES, title: localization.projectTypes, icon: <MenuIcon /> },
                    { path: AppRouteUrl.BREEDS, title: localization.breeds, icon: <PetsIcon /> },
                    { path: AppRouteUrl.BREEDING_STATIONS, title: localization.breedingStations, icon: <HouseSidingIcon /> },
                    { path: AppRouteUrl.COUNTRIES, title: localization.countries, icon: <PublicIcon /> },
                ]
        }] : [])];

    return <Navigation items={items} onClickToggle={props.onClickToggle} />
}

export default Sidebar;