import { FormEvent, useContext, useEffect, useState } from 'react';

// Utility
import skLocale from 'date-fns/locale/sk';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr';
import { GetDateLocale, GetLanguage, Localize } from '../../AppLocalization';
import Debounce from '../../utility/Debounce';

// Modely
import { PriorityColors, TaskState, TaskType, TaskFilter as TasksFilter, User } from '../../models/Models';

// Komponenty
import { Alert, AlertColor, Button, TextField, Box, FormControl, InputLabel, MenuItem, Select, Chip, List, ListItem, Checkbox, ListItemButton, ListItemAvatar, Avatar, ListItemText } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { NullMinDate } from '../../utility/Date';
import { AppContext } from '../../AppContext';

// Ikony
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Vstupné parametre
export interface TasksFilteringProps {
    filter: TasksFilter;
    hideStartDate?: boolean;
    taskStates: TaskState[];
    taskTypes: TaskType[];
    users: User[];
    onSaveDelay?: number; // ak je nastavené, tak sa filter po zmene a uplinutí delay automaticky potvrdí
    onSave: (filter: TasksFilter) => void;
    onReset: () => void;
}

// Prázdny filter
const EmptyFilter: TasksFilter = {
    page: 0,
    pageSize: 25,
    sort: '',
    sortAsc: false
}

const TasksFiltering = (props: TasksFilteringProps) => {

    // Globálne premenné
    const context = useContext(AppContext);

    // Všobecne
    const localization = Localize();

    // Stav
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<TasksFilter>({ ...EmptyFilter });
    const [sourceChanged, setSourceChanged] = useState<boolean>(false);

    // Automaticky naplním zdroj
    useEffect(() => {
        setSourceChanged(false);
        setSource(props.filter);
    }, [props.filter]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSourceChanged(true);
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Označí alebo zruší odznačenie užívateľa
    const handleToggleUser = (userId: number) => {
        setSourceChanged(true);
        setSource(prev => ({ ...prev, userIds: (source.userIds ?? []).includes(userId) === true ? (prev.userIds ?? []).filter(i => i !== userId) : [...prev.userIds ?? [], userId] }));
    };

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        props.onSave(source);
    };

    // Automatické potvrdenie filtra po zmene
    useEffect(() => {
        if ((props.onSaveDelay ?? 0) > 0 && sourceChanged) {
            handleSaveDebounce(source);
        }
    }, [props.onSaveDelay, source, sourceChanged]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSaveDebounce = Debounce((s: TasksFilter) => {
        props.onSave(s);
    }, props.onSaveDelay ?? 500);

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Box component="form" onSubmit={handleSave}>
            <Grid container columnSpacing={0}>

                {context.user?.admin && (
                    <Grid item xs={12} mb={1}>
                        <List dense sx={{ maxHeight: 350, overflow: 'auto' }}>
                            {props.users.map(user => {
                                const labelId = `tasks-filter-user-label-${user.id}`;
                                const checked = (source.userIds ?? []).includes(user.id ?? 0) === true;
                                return (
                                    <ListItem
                                        sx={{ borderTop: '1px solid whitesmoke' }}
                                        key={user.id}
                                        secondaryAction={
                                            <Checkbox edge="end" inputProps={{ 'aria-labelledby': labelId }}
                                                checked={checked}
                                                onChange={() => handleToggleUser(user.id ?? 0)}
                                            />
                                        }
                                        disablePadding>
                                        <ListItemButton onClick={() => handleToggleUser(user.id ?? 0)} selected={checked}>
                                            <ListItemAvatar>
                                                {user.fileIsImage ?
                                                    <Avatar alt={user.name} src={user.fileSrcSmall} /> :
                                                    <Avatar><PersonIcon /></Avatar>}
                                            </ListItemAvatar>
                                            <ListItemText id={labelId}>
                                                {checked ? <strong>{user.name}</strong> : user.name}
                                            </ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                )
                            }
                            )}
                        </List>
                    </Grid>
                )}

                <Grid item xs={12}>
                    <FormControl variant="filled" fullWidth sx={{ mt: 0, backgroundColor: 'white' }}>
                        <InputLabel id="tasks-filter-taskstate">{localization.taskState}</InputLabel>
                        <Select multiple labelId="tasks-filter-taskstate" label={localization.taskState} name="taskStateIds" value={source.taskStateIds ?? []} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: .3, height: 22 }}>
                                    {selected.map((value) => {
                                        var item = props.taskStates.find(item => item.id === value) ?? { name: '', color: '333333' };
                                        return (
                                            <Chip size="small" key={value} label={item.name} sx={{ m: 0, bgcolor: '#' + ((item.color ?? '').length > 0 ? item.color : 'f0f0f0'), color: ((item.color ?? '').length > 0 ? '#ffffff' : '#222222') }} />
                                        )
                                    })}
                                </Box>
                            )}>
                            {props.taskStates.map(item => (<MenuItem key={item.id} value={item.id} sx={{ color: '#' + ((item.color ?? '').length > 0 ? item.color : 'f0f0f0') }}>{item.name}</MenuItem>))}
                        </Select>
                    </FormControl>
                </Grid>

                {(props.hideStartDate ?? false) === false && (
                    <>
                        <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={GetDateLocale()}>
                                <FormControl margin="dense" fullWidth>
                                    <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label={localization.start + ' (' + localization.from.toLowerCase() + ')'} value={NullMinDate(source.startFrom)} onChange={(d) => { handleChange('startFrom', d) }} slotProps={{ textField: { variant: 'filled' } }} />
                                </FormControl>
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={GetDateLocale()}>
                                <FormControl margin="dense" fullWidth>
                                    <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label={localization.start + ' (' + localization.to.toLowerCase() + ')'} value={NullMinDate(source.startTo)} onChange={(d) => { handleChange('startTo', d) }} slotProps={{ textField: { variant: 'filled' } }} />
                                </FormControl>
                            </LocalizationProvider>
                        </Grid>
                    </>
                )}

                <Grid item xs={6}>
                    <FormControl variant="filled" fullWidth>
                        <InputLabel id="tasks-filter-tasktype">{localization.taskType}</InputLabel>
                        <Select multiple labelId="tasks-filter-tasktype" label={localization.taskType} name="taskTypeIds" value={source.taskTypeIds ?? []} onChange={(e) => { handleChange(e.target.name, e.target.value) }}>
                            {props.taskTypes.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl variant="filled" fullWidth>
                        <InputLabel id="tasks-filter-priority">{localization.priority}</InputLabel>
                        <Select multiple labelId="tasks-filter-priority" label={localization.priority} name="priorityIds" value={source.priorityIds ?? []} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: .3, height: 22 }}>
                                    {selected.map((value) => {
                                        return (
                                            <Chip size="small" key={value} label={value} sx={{ m: 0, bgcolor: '#' + PriorityColors[value - 1], color: '#ffffff' }} />
                                        )
                                    })}
                                </Box>
                            )}>
                            <MenuItem value={1} sx={{ color: '#' + PriorityColors[0] }}>1 ({localization.highest.toLowerCase()})</MenuItem>
                            <MenuItem value={2} sx={{ color: '#' + PriorityColors[1] }}>2</MenuItem>
                            <MenuItem value={3} sx={{ color: '#' + PriorityColors[2] }}>3</MenuItem>
                            <MenuItem value={4} sx={{ color: '#' + PriorityColors[3] }}>4</MenuItem>
                            <MenuItem value={5} sx={{ color: '#' + PriorityColors[4] }}>5 ({localization.lowest.toLowerCase()})</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <TextField name="name" label={localization.title} fullWidth variant="filled" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                        inputProps={{ maxLength: 255 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <EditIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>

                {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                <Grid item xs={12} mt={1}>
                    <Button type="submit" size="large" fullWidth variant="contained" startIcon={<SearchIcon />}>{localization.search}</Button>
                    <Button size="large" onClick={() => props.onReset()} fullWidth variant="text" color="secondary" sx={{ mt: .5 }} startIcon={<ClearIcon />}>{localization.reset}</Button>
                </Grid>

            </Grid>
        </Box>
    )
}

export default TasksFiltering;