import { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { AppRouteUrl } from '../../AppRoutes';
import { Localize } from '../../AppLocalization';

// Modely
import { ProductionLite, ProductionFilter, Breed, BreedingStation } from '../../models/Models';

// Utility
import { Export as DataGridExport, Settings as DataGridSettings, LocalizeGrid } from '../../utility/DataGrid';
import { useQuery } from '../../utility/URL';
import { NullMinDate } from '../../utility/Date';
import { format as dateFormat } from 'date-fns';

// Komponenty
import { DataGrid, GridColDef, GridValueFormatterParams, GridRenderCellParams, GridValueGetterParams, GridFilterModel, GridRowId, GridDensity, GridSortModel } from "@mui/x-data-grid";
import { Button, Chip, Divider, Grid, IconButton, ListItemIcon, Menu, Box, darken, lighten } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Search from '../../components/Search';
import { Content, ContentTop, ContentBottom } from '../../layout/Content';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import ProductionCreate, { ProductionCreateProps } from './ProductionCreate';
import ProductionsCounts from './ProductionsCounts';
import DataGridDensity from '../../components/DataGridDensity';
import ProductionsFiltering, { ProductionsFilteringProps } from './ProductionsFiltering';
import Reporting, { ReportingProps } from '../report/Reporting';
import ProductionsSchedule, { ProductionsScheduleProps } from './ProductionsSchedule';

// Ikony
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import ClearIcon from '@mui/icons-material/Clear';
import Debounce from '../../utility/Debounce';
import PrintIcon from '@mui/icons-material/Print';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

// Komponent pre zoznam
const Productions = () => {

    // Jazyková mutácia
    const localization = Localize();

    // Lokálny stav
    const [loading, setLoading] = useState<boolean>(true);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [breeds, setBreeds] = useState<Breed[]>([]);
    const [breedingStations, setBreedingStations] = useState<BreedingStation[]>([]);
    const [productionCreate, setProductionCreate] = useState<ProductionCreateProps>({
        open: false,
        keepMounted: true,
        breeds: [],
        breedingStations: [],
        onSave: () => loadDataSafe(),
        onClose: () => setProductionCreate(prev => ({ ...prev, open: false }))
    });
    const [productionsFiltering, setProductionsFiltering] = useState<ProductionsFilteringProps>({
        open: false,
        breeds: [],
        breedingStations: [],
        keepMounted: true,
        onSave: (filter) => setRowsFilter({ ...filter, page: 0 }),
        onClose: () => setProductionsFiltering(prev => ({ ...prev, open: false }))
    });
    const [productionsSchedule, setProductionsSchedule] = useState<ProductionsScheduleProps>({
        open: false,
        keepMounted: true,
        onClose: () => setProductionsSchedule(prev => ({ ...prev, open: false }))
    });

    // Načítam číselníky
    const loadBreeds = () => {
        axios
            .get(AppConfig.ApiUri + 'breed')
            .then(response => setBreeds(response.data));
    };
    const loadBreedingStations = () => {
        axios
            .get(AppConfig.ApiUri + 'breedingstation')
            .then(response => setBreedingStations(response.data));
    };
    useEffect(() => {
        loadBreeds();
        loadBreedingStations();
    }, []);

    // Tlač dokladu
    const [reporting, setReporting] = useState<ReportingProps>({
        type: 'productions',
        settings: '',
        open: false,
        preview: true,
        print: false,
        format: 'html',
        onClose: () => setReporting(prev => ({ ...prev, open: false }))
    })

    // Nastavenie zobrazenia    
    const [density, setDensity] = useState<GridDensity>('standard' as GridDensity);

    // Nastavenia stĺpcov
    const gridSettings = new DataGridSettings({ uid: 'productions' });
    const columnsDefault: GridColDef[] = [
        { field: 'id', headerName: 'Id', hide: true, minWidth: 20, width: 90, type: 'number', align: 'center', headerAlign: 'center' },
        {
            field: 'number', headerName: localization.chipNumber, hide: false, minWidth: 170, flex: 0.25,
            renderCell: (params: GridRenderCellParams<number>) => <Chip variant='outlined' label={params.row.number} sx={{ color: ((params.row.genderMale ?? false) === true ? '#00a6b6' : '#f52843'), borderColor: ((params.row.genderMale ?? false) === true ? '#00a6b6' : '#f52843') }} />
        },
        { field: 'name', headerName: localization.name, hide: false, minWidth: 50, flex: 0.3 },
        { field: 'breed', headerName: localization.breed, hide: false, minWidth: 50, flex: 0.2 },
        { field: 'color', headerName: localization.color, hide: false, minWidth: 50, flex: 0.2 },
        { field: 'breedingStation', headerName: localization.breedingStation, hide: true, minWidth: 50, flex: 0.2, sortable: false },
        {
            field: 'genderMale', headerName: localization.gender, hide: true, minWidth: 50, flex: 0.2,
            renderCell: (params: GridRenderCellParams<boolean>) => ((params?.value ?? false) === true ? localization.male : localization.female)
        },
        { field: 'note', headerName: localization.note, hide: true, minWidth: 50, flex: 0.6, sortable: false },
        {
            field: 'dateOfBirth', headerName: localization.dateOfBirth, hide: false, minWidth: 50, flex: 0.2, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['dateOfBirth']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? dateFormat((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        {
            field: 'dateOfEntry', headerName: localization.dateOfEntry, hide: true, minWidth: 50, flex: 0.2, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['dateOfEntry']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? dateFormat((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        {
            field: 'dateOfExit', headerName: localization.dateOfExit, hide: true, minWidth: 50, flex: 0.2, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['dateOfExit']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? dateFormat((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        {
            field: 'countsRendered', headerName: localization.production, hide: false, minWidth: 60, flex: 0.7, type: '', sortable: false,
            renderCell: (params: GridRenderCellParams<number>) => (params.row.countsRendered)
        },
        {
            field: 'createdDate', headerName: localization.created, hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['createdDate']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? dateFormat((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        {
            field: 'updatedDate', headerName: localization.updated, hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => (new Date(params.row['updatedDate'])),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? dateFormat((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        {
            field: 'options', headerName: localization.options, hide: false, width: 120, sortable: false, filterable: false, align: 'right', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => (
                <>
                    <IconButton aria-label={localization.edit} title={localization.edit + ' (enter)'} size="small" onClick={() => handleCreate(params.value ?? 0, false)}>
                        <EditIcon color="primary" fontSize="small" />
                    </IconButton>
                    <IconButton aria-label={localization.copy} title={localization.copy + ' (ctrl + enter)'} size="small" onClick={() => handleCreate(params.value ?? 0, true)}>
                        <ContentCopyIcon fontSize="small" />
                    </IconButton>
                    <IconButton aria-label={localization.delete} title={localization.delete + ' (delete)'} size="small" onClick={() => handleDelete(params.value ?? 0, params.row.number)}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </>
            )
        }
    ];

    // Aplikujem uložené nastavenia
    useEffect(() => setColumns(gridSettings.columnApply(columns)), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Tabuľka
    const [rows, setRows] = useState<ProductionLite[]>([]);
    const [rowsSelected, setRowsSelected] = useState<GridRowId[]>([])
    const [rowsSelectedMenuEl, setRowsSelectedMenuEl] = useState<null | HTMLElement>(null);
    const [rowsCount, setRowsCount] = useState<number>(0);
    const [rowsFilter, setRowsFilter] = useState<ProductionFilter>({ page: 0, pageSize: gridSettings.pageSizeApply(25) });
    const [columns, setColumns] = useState<GridColDef[]>(columnsDefault);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();
    const [sortModel, setSortModel] = useState<GridSortModel>();

    // Funkcia pre získanie počtu aktívnych filtrov
    const filterCount = (): number => {
        let count = 0;
        if ((rowsFilter.breedId ?? 0) !== 0) { count++; }
        if ((rowsFilter.breedingStationId ?? 0) !== 0) { count++; }
        if ((rowsFilter.genderMale ?? 0) !== 0) { count++; }
        if ((rowsFilter.color ?? '') !== '') { count++; }
        if (rowsFilter.countsFrom !== undefined) { count++; }
        if (rowsFilter.countsTo !== undefined) { count++; }
        if (rowsFilter.dateOfBirthFrom !== undefined) { count++; }
        if (rowsFilter.dateOfBirthTo !== undefined) { count++; }
        if (rowsFilter.dateOfEntryFrom !== undefined) { count++; }
        if (rowsFilter.dateOfEntryTo !== undefined) { count++; }
        if (rowsFilter.dateOfExitFrom !== undefined) { count++; }
        if (rowsFilter.dateOfExitTo !== undefined) { count++; }
        return count;
    };

    // Ak sa v zozname zmení poradie, tak automaticky upravím filter
    useEffect(() => {
        // Predvolené
        let sort: string = 'id';
        let sortAsc: boolean = false;
        // Vybrané
        if (sortModel !== undefined && sortModel.length > 0) {
            sort = sortModel[0].field;
            sortAsc = (sortModel[0].sort === 'asc');
        }
        if (rowsFilter.sort !== sort || rowsFilter.sortAsc !== sortAsc) {
            setRowsFilter(prev => ({ ...prev, sort: sort, sortAsc: sortAsc }));
        }
    }, [sortModel]); // eslint-disable-line react-hooks/exhaustive-deps

    // Pridať upraviť záznam
    const handleCreate = (id: number, copy?: boolean, field?: string) => {
        setProductionCreate(prev => ({
            ...prev,
            id: id,
            copy: copy ?? false,
            open: true,
            autoFocus: field
        }));
    };

    // Úprava záznamu podľa "id" v URL
    const history = useHistory();
    const requestId: number = parseInt(useQuery().get('id') ?? '0');
    useEffect(() => {
        if (requestId > 0) {
            history.push(AppRouteUrl.PRODUCTIONS);
            handleCreate(requestId);
        }
    }, [requestId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Vymazať záznam
    const handleDelete = (id: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: localization.deleteConfirmation, onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'production/' + id)
                    .then(response => {
                        if (response.data === true) {
                            setRows(prev => [...prev.filter(r => r.id !== id)]);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };
    const handleDeleteList = (ids: number[]) => {
        if (ids.length === 0) {
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: localization.delete + ': ' + ids.length, children: localization.deleteSeletedConfirmation, onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'production/list', {
                        params: {
                            'ids': ids
                        }
                    })
                    .then(response => {
                        if (response.data === true) {
                            setRows(prev => [...prev.filter(r => !ids.includes(r?.id ?? 0))]);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    const handleExportCSV = () => {
        if (rowsSelected.length === 0) {
            return;
        }
        DataGridExport({
            type: 'csv',
            columns: columns,
            columnsSkip: ['options'],
            rows: rows,
            ids: rowsSelected.map(r => r as number)
        });
    };
    const handleExportXML = () => {
        if (rowsSelected.length === 0) {
            return;
        }
        DataGridExport({
            type: 'xml',
            columns: columns,
            columnsSkip: ['options'],
            rows: rows,
            ids: rowsSelected.map(r => r as number)
        });
    };

    // Funkcia pre načítanie dát z API
    const loadData = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'production', {
                params: rowsFilter
            })
            .then(response => {
                var list = (response.data?.list ?? []) as ProductionLite[];
                if (list.length > 0) {
                    list.forEach(item => item.countsRendered = <ProductionsCounts number={item.number} counts={item.counts} />);
                }
                setRows(list);
                setRowsCount(response.data?.itemsCount ?? 0);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Funkcia pre bezpečné obnovenie zoznamu pomocou zmeny stavu (cudzie vlákno môže mať problém so stavom filtra)
    const loadDataSafe = () => {
        setRowsFilter(prev => ({ ...prev }));
    }

    // Automatická obnova zoznamu po otvorení stránky alebo zmene filtru
    const loadDataDebounce = Debounce(() => loadData(), 100);
    useEffect(() => loadDataDebounce(), [rowsFilter]); // eslint-disable-line react-hooks/exhaustive-deps

    // Prepočíta farbu (zosvetlenie alebo stmavenie, napr. pre hover)
    const getHoverBackgroundColor = (color: string, mode: string) => mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

    return (
        <>
            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Formulár pre nový záznam */}
            <ProductionCreate {...productionCreate} breeds={breeds} breedingStations={breedingStations} />

            {/* Obsah */}
            <Content>
                <ContentTop>
                    <Grid container alignItems="center">

                        {/* Možnosti */}
                        <Grid item xs={9} md lg>

                            {/* Nový záznam */}
                            <Button variant="contained" sx={{ mr: 1 }} size="large" startIcon={<AddIcon />} onClick={() => handleCreate(0)}>{localization.newRecord}</Button>

                            {/* Označené záznamy (možnosti) */}
                            <Button variant="text" size="large" disabled={rowsSelected?.length === 0} aria-controls="menu-selected" aria-haspopup="true" onClick={(e) => setRowsSelectedMenuEl(e.currentTarget)} endIcon={<ExpandMoreIcon />}>{localization.selected} {'(' + rowsSelected.length.toString() + ')'}</Button>
                            <Menu id="menu-selected" anchorEl={rowsSelectedMenuEl} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} sx={{ mt: '55px' }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} open={Boolean(rowsSelectedMenuEl)} onClose={() => setRowsSelectedMenuEl(null)} >
                                <MenuItem onClick={() => { handleExportCSV(); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> {localization.saveAs} CSV (Excel)
                                </MenuItem>
                                <MenuItem onClick={() => { handleExportXML(); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> {localization.saveAs} XML
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { handleDeleteList(rowsSelected.map(r => r as number)); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon> {localization.delete}
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { setRowsSelected([]); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><ClearIcon fontSize="small" /></ListItemIcon> {localization.cancelSelection}
                                </MenuItem>
                            </Menu>
                        </Grid>

                        <Grid item xs md={3} textAlign="right">
                            {/* Harmonogram */}
                            <IconButton aria-label="schedule" title={localization.schedule} onClick={() => setProductionsSchedule(prev => ({ ...prev, open: true }))}>
                                <CalendarMonthIcon />
                            </IconButton>

                            {/* Tlač */}
                            <IconButton aria-label="print" title={localization.print} sx={{ mr: 1 }} onClick={() => setReporting(prev => ({ ...prev, open: true, settings: encodeURIComponent(JSON.stringify(rowsFilter)) }))}>
                                <PrintIcon />
                            </IconButton>

                            {/* Zobrazenie */}
                            <DataGridDensity onDensityChanged={setDensity} />
                        </Grid>

                        {/* Vyhľadávanie */}
                        <Grid item xs={12} md={4} sx={{ mt: { xs: 1, md: 0 } }}>
                            <Search
                                onSearch={s => setRowsFilter(prev => ({ ...prev, page: 0, search: s }))}
                                onClear={() => {
                                    setRowsFilter(prev => ({
                                        page: 0,
                                        pageSize: prev.pageSize,
                                        sort: prev.sort,
                                        sortAsc: prev.sortAsc
                                    }));
                                }}
                                autoFocus={true}
                                filter={true}
                                filterCount={filterCount()}
                                onFilter={() => setProductionsFiltering(prev => ({ ...prev, filter: rowsFilter, open: true }))}
                            />
                        </Grid>
                    </Grid>
                </ContentTop>
                <ContentBottom>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <Box sx={{
                            height: '100%', width: '100%',
                            '& .gridstyle-male': {
                                color: '#00a6b6',
                                ' .MuiDataGrid-cell:first-child': {
                                    position: 'relative',
                                    '&:before': {
                                        display: 'block', position: 'absolute', top: 0, left: 0, width: '3px', height: '100%', background: '#00a6b6', content: '" "'
                                    }
                                }
                            },
                            '& .gridstyle-female': {
                                color: '#f52843',
                                '.MuiDataGrid-cell:first-child': {
                                    position: 'relative',
                                    '&:before': {
                                        display: 'block', position: 'absolute', top: 0, left: 0, width: '3px', height: '100%', background: '#f52843', content: '" "'
                                    }
                                }
                            },
                            '& .gridstyle-out': {
                                opacity: .4,
                                bgcolor: (theme) => '#f0f0f0',
                                '&:hover': {
                                    bgcolor: (theme) => getHoverBackgroundColor('#f3f4f5', theme.palette.mode),
                                },
                            },
                        }}>
                            <DataGrid
                                getRowClassName={(params) => {
                                    let classNames = (params.row.genderMale === true ? 'gridstyle-male' : 'gridstyle-female');
                                    if (NullMinDate(params.row.dateOfExit) !== null) {
                                        classNames += ' gridstyle-out';
                                    }
                                    return classNames;
                                }}
                                getRowId={row => row.id}
                                density={density}
                                checkboxSelection
                                disableSelectionOnClick
                                disableVirtualization

                                columns={columns}
                                rows={rows}
                                rowCount={rowsCount}

                                pagination
                                paginationMode="server"
                                page={rowsFilter.page}
                                pageSize={rowsFilter.pageSize}

                                rowsPerPageOptions={[10, 25, 50]}
                                onPageChange={(page) => setRowsFilter(prev => ({ ...prev, page: page }))}
                                onPageSizeChange={(pageSize) => {
                                    setRowsFilter(prev => ({ ...prev, page: 0, pageSize: pageSize }));
                                    gridSettings.pageSizeChanged(pageSize);
                                }}

                                sortingMode="server"
                                sortModel={sortModel}
                                onSortModelChange={(model) => setSortModel(model)}

                                localeText={LocalizeGrid()}
                                loading={loading}

                                // Dvoj-klik (úprava)
                                onCellDoubleClick={(e) => {
                                    handleCreate(e.row.id, false, e.field);
                                }}

                                // Klávesnica (shift+enter => upraviť, shift+delete => vymazať, shift+space => označiť, vstavaná funkcia)
                                onCellKeyDown={(e, c) => {
                                    if (c.code === 'Enter' && c.ctrlKey) {
                                        c.preventDefault();
                                        c.stopPropagation();
                                        handleCreate(e.row.id, true, e.field);
                                        return;
                                    }
                                    if (c.code === 'Enter' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                        c.preventDefault();
                                        c.stopPropagation();
                                        handleCreate(e.row.id, false, e.field);
                                        return;
                                    }
                                    if (c.code === 'Delete' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                        c.preventDefault();
                                        c.stopPropagation();
                                        handleDelete(e.row.id, e.row.number);
                                        return;
                                    }
                                }}

                                // Filtrácia
                                filterModel={filterModel}
                                onFilterModelChange={e => setFilterModel(e)}

                                // Vybrané záznamy
                                selectionModel={rowsSelected}
                                onSelectionModelChange={e => setRowsSelected(e)}

                                // Stĺpce (automatické ukladanie nastavení)
                                onColumnVisibilityChange={e => gridSettings.columnVisibilityChanged(e, columnsDefault)}
                            />
                        </Box>
                    </div>
                </ContentBottom>
            </Content>

            {/* Harmonogram */}
            <ProductionsSchedule {...productionsSchedule} />

            {/* Formulár pre filtráciu */}
            <ProductionsFiltering {...productionsFiltering} breeds={breeds} breedingStations={breedingStations} />

            {/* Tlač dokladu */}
            <Reporting {...reporting} />
        </>
    )
}

export default Productions;