
export const ExportXML = (obj: any) => {
    var xml = 'data:text/xml;charset=utf-8,<?xml version="1.0" encoding="UTF-8"?>' + ExportXMLNode(obj);
    var encodedUri = encodeURI(xml);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", document.title + ".xml");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const ExportXMLNode = (obj: any) => {
    var xml = '';
    for (var prop in obj) {
        xml += obj[prop] instanceof Array ? '' : "<" + prop + ">";
        if (obj[prop] instanceof Array) {
            for (var array in obj[prop]) {
                xml += "<" + prop + ">";
                xml += ExportXMLNode(obj[prop][array]);
                xml += "</" + prop + ">";
            }
        } else if (typeof obj[prop] == "object") {
            xml += ExportXMLNode(obj[prop]);
        } else {
            var value = obj[prop];
            if (typeof value === 'number') {
                value = value?.toLocaleString() ?? '';
            }
            xml += EscapeXmlEntities(value);
        }
        xml += obj[prop] instanceof Array ? '' : "</" + prop + ">";
    }
    return xml.replace(/<\/?[0-9]{1,}>/g, '');
}

const EscapeXmlEntities = (s: string): string => {
    return s.replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&apos;');
}

/*
const UnescapeXmlEntities = (s: string) : string => {
    return s.replace(/&apos;/g, "'")
            .replace(/&quot;/g, '"')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<')
            .replace(/&amp;/g, '&');
}
*/