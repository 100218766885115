import { createTheme } from "@mui/material";
import type {} from '@mui/x-data-grid/themeAugmentation';

const ThemeComponents = createTheme({ 
    components: {
        /* Dialog */
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: 30,
                    textAlign: 'center',
                    padding: '40px 0 10px 0'
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: 20
                }
            }
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    /*backdropFilter: 'blur(3px)',*/
                    background: 'rgba(0, 0, 0, .3)'
                }
            }
        }
    }

});
export default ThemeComponents;
