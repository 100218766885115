import { useEffect, useRef, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { AppRouteUrl } from '../../AppRoutes';
import { GetDateLocale, GetLanguage, Localize } from '../../AppLocalization';

// Modely
import { TaskLite, TaskFilter, TaskState, TaskType, User, Task, PriorityColors, SaveAction, TaskTemplate } from '../../models/Models';

// Utility
import { Export as DataGridExport, Settings as DataGridSettings, LocalizeGrid } from '../../utility/DataGrid';
import { useQuery } from '../../utility/URL';
import { LoadLocalSettings, SaveLocalSettings } from '../../utility/LocalSettings';

// Komponenty
import { DataGrid, GridColDef, GridValueFormatterParams, GridRenderCellParams, GridValueGetterParams, GridFilterModel, GridRowId, GridSortModel } from "@mui/x-data-grid";
import { Button, Divider, Grid, IconButton, ListItemIcon, Menu, Box, Paper, Typography, ToggleButton, ToggleButtonGroup, Tooltip, useTheme, Chip, SxProps } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Search from '../../components/Search';
import { Content, ContentTop, ContentBottom } from '../../layout/Content';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import TaskCreate, { TaskCreateProps } from './TaskCreate';
import TasksFiltering from './TasksFiltering';
import TasksDetail from './TasksDetail';

// Formátovanie dátumu
import { addDays, addHours, format as dateFormat } from 'date-fns';
import skLocale from 'date-fns/locale/sk';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr';

// Kalendár
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import multiMonthPlugin from '@fullcalendar/multimonth';
import interactionPlugin from "@fullcalendar/interaction";
import calendarSkLocale from '@fullcalendar/core/locales/sk';
import calendarEnLocale from '@fullcalendar/core/locales/en-gb';
import calendarFrLocale from '@fullcalendar/core/locales/fr';

// Ikony
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import ClearIcon from '@mui/icons-material/Clear';
import Debounce from '../../utility/Debounce';
import GridOnIcon from '@mui/icons-material/GridOn';
import GridViewIcon from '@mui/icons-material/GridView';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DateSelectArg, EventInput, LocaleInput } from '@fullcalendar/core';
import InfoIcon from '@mui/icons-material/Info';
import { Theme } from '@mui/material/styles/createTheme';

// Predvolený čas pre novú úlohu bez konkrétneho času (all day) od polnoci
const TaskDefaultTime = {
    toStartHours: 7, // from middle night
    toEndHours: 1, // from start
    toFinishDays: 1 // from end
}

// Komponent pre zoznam
const Tasks = () => {

    // Všeobecne
    const theme = useTheme();
    const localization = Localize();

    // Lokálny stav
    const [loading, setLoading] = useState<boolean>(true);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [rowsDetailId, setDetailId] = useState<number>(0);
    const [taskCreate, setTaskCreate] = useState<TaskCreateProps>({
        open: false,
        keepMounted: true,
        taskStates: [],
        taskTypes: [],
        taskTemplates: [],
        users: [],
        onTasksChanged: () => loadDataSafe(),
        onSave: (id?: number, action?: SaveAction) => {
            // Obnovím zoznam
            loadDataSafe();

            // Rozšírené funkcie po uložení
            if (id !== undefined && action !== undefined) {
                if (action === SaveAction.SaveAndNew) {
                    handleCreate(0);
                    return;
                }
                if (action === SaveAction.SaveAndContinue) {
                    handleCreate(id ?? 0);
                    return;
                }
                if (action === SaveAction.SaveAndNewFromCopy) {
                    handleCreate(id ?? 0, true);
                    return;
                }
            }
        },
        onClose: () => setTaskCreate(prev => ({ ...prev, id: 0, copy: false, task: undefined, open: false }))
    });

    // Číselníky
    const [taskStates, setTaskStates] = useState<TaskState[]>([]);
    const [taskTypes, setTaskTypes] = useState<TaskType[]>([]);
    const [taskTemplates, setTaskTemplates] = useState<TaskTemplate[]>([]);
    const [users, setUsers] = useState<User[]>([]);

    // Načítam číselníky jedným volaním
    const loadFormData = () => {
        axios
            .get(AppConfig.ApiUri + 'task/formdata')
            .then(response => {
                if (response.data) {
                    applyFormData(response.data);
                }
            });
    };
    useEffect(() => loadFormData(), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Funkcia pre naplnenie stavu číselníkov
    const applyFormData = (data: any) => {
        if (data.taskstate) {
            setTaskStates(data.taskstate);
        }
        if (data.tasktype) {
            setTaskTypes(data.tasktype);
        }
        if (data.tasktemplate) {
            setTaskTemplates(data.tasktemplate);
        }
        if (data.user) {
            setUsers(data.user);
        }
    };

    // Nastavenie zobrazenia    
    const [display, setDisplay] = useState<string>(LoadLocalSettings('tasks-display', 'calendar'));
    const setDisplayAndSave = (value: string) => {
        setDisplay(value);
        SaveLocalSettings('tasks-display', value, 'calendar');
    };

    // Nastavenia stĺpcov
    const gridSettings = new DataGridSettings({ uid: 'tasks' });
    const columnsDefault: GridColDef[] = [
        { field: 'id', headerName: 'Id', hide: true, minWidth: 20, width: 90, type: 'number', align: 'center', headerAlign: 'center' },
        {
            field: 'start', headerName: localization.start, hide: false, minWidth: 50, flex: 0.2, type: 'start',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['start']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? dateFormat((params?.value as Date), 'dd.MM.yyyy HH:mm') : '-')
        },
        {
            field: 'end', headerName: localization.end, hide: false, minWidth: 50, flex: 0.2, type: 'end',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['end']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? dateFormat((params?.value as Date), 'dd.MM.yyyy HH:mm') : '-')
        },
        {
            field: 'finish', headerName: localization.deadline, hide: false, minWidth: 50, flex: 0.2, type: 'finish',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['finish']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? dateFormat((params?.value as Date), 'dd.MM.yyyy HH:mm') : '-')
        },
        { field: 'name', headerName: localization.title, hide: false, minWidth: 50, flex: 0.4 },
        {
            field: 'priority', headerName: localization.priority, hide: true, minWidth: 50, flex: 0.2,
            renderCell: (params: GridRenderCellParams<number>) => {
                return <Chip label={(params.row.priority ?? 1)} size="small" variant="filled" sx={{ bgcolor: '#' + PriorityColors[(params.row.priority ?? 1) - 1], color: '#ffffff' }} />
            }
        },
        { field: 'projectName', headerName: localization.project, hide: true, minWidth: 50, flex: 0.3 },
        { field: 'taskName', headerName: localization.task, hide: true, minWidth: 50, flex: 0.3 },
        { field: 'taskTypeName', headerName: localization.taskType, hide: true, minWidth: 50, flex: 0.3 },
        {
            field: 'taskStateName', headerName: localization.taskState, hide: false, minWidth: 60, flex: 0.4,
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => {
                let label = (params.row.taskStateName ?? '');
                let color = (params.row.taskStateColor ?? '');
                return <Chip label={label.length > 0 ? label : '...'} size="small" variant="filled" sx={{ bgcolor: '#' + (color.length > 0 ? color : 'f0f0f0'), color: (color.length > 0 ? '#ffffff' : '#222222') }} />
            }
        },
        {
            field: 'createdDate', headerName: localization.created, hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['createdDate']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? dateFormat((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        {
            field: 'updatedDate', headerName: localization.updated, hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => (new Date(params.row['updatedDate'])),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? dateFormat((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        {
            field: 'options', headerName: localization.options, hide: false, width: 140, sortable: false, filterable: false, align: 'right', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => (
                <>
                    <IconButton aria-label={localization.print} title={localization.detail} size="small" onClick={(e) => handleDetail(params.value ?? 0)}>
                        <InfoIcon color="info" fontSize="small" />
                    </IconButton>
                    {(params.row.canWrite === true) && (
                        <>
                            <IconButton aria-label={localization.edit} title={localization.edit + ' (enter)'} size="small" onClick={() => handleCreate(params.value ?? 0, false)}>
                                <EditIcon color="primary" fontSize="small" />
                            </IconButton>
                            <IconButton aria-label={localization.copy} title={localization.copy + ' (ctrl + enter)'} size="small" onClick={() => handleCreate(params.value ?? 0, true)}>
                                <ContentCopyIcon fontSize="small" />
                            </IconButton>
                            <IconButton aria-label={localization.delete} title={localization.delete + ' (delete)'} size="small" onClick={() => handleDelete(params.value ?? 0, params.row.name)}>
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </>
                    )}
                </>
            )
        }
    ];

    // Aplikujem uložené nastavenia
    useEffect(() => setColumns(gridSettings.columnApply(columns)), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Tabuľka
    const [rows, setRows] = useState<TaskLite[]>([]);
    const [rowsHighlighted, setRowsHighlighted] = useState<Date[]>([]);
    const [rowsSelected, setRowsSelected] = useState<GridRowId[]>([])
    const [rowsSelectedMenuEl, setRowsSelectedMenuEl] = useState<null | HTMLElement>(null);
    const [rowsCount, setRowsCount] = useState<number>(0);
    const [rowsFilter, setRowsFilter] = useState<TaskFilter>({ page: 0, pageSize: gridSettings.pageSizeApply(25) });
    const [rowsFilterCalendar, setRowsFilterCalendar] = useState<TaskFilter>({ calendarView: true });
    const [columns, setColumns] = useState<GridColDef[]>(columnsDefault);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();
    const [sortModel, setSortModel] = useState<GridSortModel>();
    
    // Ak sa v zozname zmení poradie, tak automaticky upravím filter
    useEffect(() => {
        // Predvolené
        let sort: string = '';
        let sortAsc: boolean = false;
        // Vybrané
        if (sortModel !== undefined && sortModel.length > 0) {
            sort = sortModel[0].field;
            sortAsc = (sortModel[0].sort === 'asc');
        }
        if (rowsFilter.sort !== sort || rowsFilter.sortAsc !== sortAsc) {
            setRowsFilter(prev => ({ ...prev, sort: sort, sortAsc: sortAsc }));
        }
    }, [sortModel]); // eslint-disable-line react-hooks/exhaustive-deps

    // Pridať upraviť záznam (gridview)
    const handleCreate = (id: number, copy?: boolean, field?: string) => {
        setTaskCreate(prev => ({
            ...prev,
            id: id,
            copy: copy ?? false,
            open: true,
            autoFocus: field
        }));
    };

    // Upraviť záznam (calendar)
    const handleChange = (id: number, start: Date, end: Date) => {
        // Aktualizujem pôvodný záznam
        setRows(prev => {
            let newRows = [...prev];
            newRows.forEach(item => {
                if (item.id === id) {
                    item.start = start;
                    item.end = end;
                }
            });
            return newRows;
        });
        setLoading(true);
        axios
            .post(AppConfig.ApiUri + 'task/change', { changeName: 'date', id: id, start: start, end: end } as Task)
            .then(response => {
                if (response.data !== true) {
                    alert(localization.saveFailed);
                }
                loadDataSafe();
            })
            .catch(() => {
                alert(localization.saveFailed);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    // Zobrazenie detailu (postranný panel)
    const handleDetail = (id: number) => setDetailId(id);

    // Úprava záznamu podľa "id" v URL
    const history = useHistory();
    const requestId: number = parseInt(useQuery().get('id') ?? '0');
    useEffect(() => {
        if (requestId > 0) {
            history.push(AppRouteUrl.TASKS);
            handleCreate(requestId);
        }
    }, [requestId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Detail záznamu podľa "id" v URL
    const detailId: number = parseInt(useQuery().get('detailId') ?? '0');
    useEffect(() => {
        if (detailId > 0) {
            history.push(AppRouteUrl.TASKS);
            handleDetail(detailId);
        }
    }, [detailId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Vymazať záznam
    const handleDelete = (id: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: localization.deleteConfirmation, onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'task/' + id)
                    .then(response => {
                        if (response.data === true) {
                            setRows(prev => [...prev.filter(r => r.id !== id)]);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    const handleDeleteList = (ids: number[]) => {
        if (ids.length === 0) {
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: localization.delete + ': ' + ids.length, children: localization.deleteSeletedConfirmation, onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'task/list', {
                        params: {
                            'ids': ids
                        }
                    })
                    .then(response => {
                        if (response.data === true) {
                            setRows(prev => [...prev.filter(r => !ids.includes(r?.id ?? 0))]);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    const handleExportCSV = () => {
        if (rowsSelected.length === 0) {
            return;
        }
        DataGridExport({
            type: 'csv',
            columns: columns,
            columnsSkip: ['options'],
            rows: rows,
            ids: rowsSelected.map(r => r as number)
        });
    };

    const handleExportXML = () => {
        if (rowsSelected.length === 0) {
            return;
        }
        DataGridExport({
            type: 'xml',
            columns: columns,
            columnsSkip: ['options'],
            rows: rows,
            ids: rowsSelected.map(r => r as number)
        });
    };

    // Funkcia pre načítanie dát z API
    const loadData = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'task', {
                params: { ...rowsFilter, ...(display === 'calendar' ? { ...rowsFilterCalendar } : {}) } // ak je zobrazený kalendár, prepíšem hodnoty použité vo filtri kalendáru
            })
            .then(response => {
                setRows((response.data?.list ?? []) as TaskLite[]);
                setRowsHighlighted((response.data?.highlighted ?? []) as Date[]);
                setRowsCount(response.data?.itemsCount ?? 0);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Funkcia pre bezpečné obnovenie zoznamu pomocou zmeny stavu (cudzie vlákno môže mať problém so stavom filtra)
    const loadDataSafe = () => {
        setRowsFilter(prev => ({ ...prev }));
    }

    // Automatická obnova zoznamu po otvorení stránky alebo zmene filtru
    const loadDataDebounce = Debounce(() => loadData(), 100);
    useEffect(() => loadDataDebounce(), [rowsFilter, rowsFilterCalendar, display]); // eslint-disable-line react-hooks/exhaustive-deps

    // Kalendár
    const [calendarView, setCalendarView] = useState<string>('timeGridWeek');
    const calendarRef = useRef(null);
    const calendarLocale = (): LocaleInput => {
        return GetLanguage() === 'en' ? calendarEnLocale :
            GetLanguage() === 'fr' ? calendarFrLocale :
                calendarSkLocale;
    };

    // Generovanie štýlu pre tabuľku (iba pri zmene zoznamu stavov, ktoré obsahujú farbu)
    const [tasksGridStyles, setTasksGridStyles] = useState<SxProps<Theme>>({});
    useEffect(() => {
        let gridStyles: SxProps<Theme> = { '& .gridstyle-state-0': { borderLeft: '3px solid #f3f3f3' } };
        taskStates?.forEach(taskState => {
            let styleName = '& .gridstyle-state-' + (taskState.id ?? 0).toString();
            let styleColor = (taskState.color ?? '');
            if (styleColor.length > 0) {
                gridStyles = {
                    ...gridStyles, [styleName]: { color: '#' + styleColor, borderLeft: '3px solid #' + styleColor }
                }
            };
        });
        setTasksGridStyles(gridStyles);
    }, [taskStates]);

    return (
        <>
            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Formulár pre nový záznam */}
            <TaskCreate {...taskCreate} taskStates={taskStates} taskTypes={taskTypes} taskTemplates={taskTemplates} users={users} />

            {/* Obsah */}
            <Content>
                <ContentTop>
                    <Grid container alignItems="center">

                        {/* Možnosti */}
                        <Grid item xs={8} md lg>

                            {/* Nový záznam */}
                            <Button variant="contained" sx={{ mr: 1 }} size="large" startIcon={<AddIcon />} onClick={() => handleCreate(0)}>{localization.newRecord}</Button>

                            {/* Označené záznamy (možnosti) */}
                            <Button variant="text" size="large" disabled={rowsSelected?.length === 0} aria-controls="menu-selected" aria-haspopup="true" onClick={(e) => setRowsSelectedMenuEl(e.currentTarget)} endIcon={<ExpandMoreIcon />}>{localization.selected} {'(' + rowsSelected.length.toString() + ')'}</Button>
                            <Menu id="menu-selected" anchorEl={rowsSelectedMenuEl} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} sx={{ mt: '55px' }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} open={Boolean(rowsSelectedMenuEl)} onClose={() => setRowsSelectedMenuEl(null)} >
                                <MenuItem onClick={() => { handleExportCSV(); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> {localization.saveAs} CSV (Excel)
                                </MenuItem>
                                <MenuItem onClick={() => { handleExportXML(); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> {localization.saveAs} XML
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { handleDeleteList(rowsSelected.map(r => r as number)); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon> {localization.delete}
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { setRowsSelected([]); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><ClearIcon fontSize="small" /></ListItemIcon> {localization.cancelSelection}
                                </MenuItem>
                            </Menu>
                        </Grid>

                        {/* Zobrazenie */}
                        <Grid item xs md={2} textAlign="right">
                            <ToggleButtonGroup exclusive value={display} size="small" aria-label="Veľkosť zobrazenia" sx={{ mr: { xs: 0, md: 1 }, backgroundColor: theme.layout.content.backgroundColor }} onChange={(e: any, d) => { if (d !== null) { setDisplayAndSave(d); } }}>
                                <ToggleButton value="calendar" aria-label="Normálne zobrazenie">
                                    <Tooltip title={localization.calendar}>
                                        <CalendarMonthIcon />
                                    </Tooltip>
                                </ToggleButton>
                                <ToggleButton value="compact" aria-label={localization.compact}>
                                    <Tooltip title={localization.compact}>
                                        <GridOnIcon />
                                    </Tooltip>
                                </ToggleButton>
                                <ToggleButton value="standard" aria-label={localization.normal}>
                                    <Tooltip title={localization.normal}>
                                        <GridViewIcon />
                                    </Tooltip>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>

                        {/* Vyhľadávanie */}
                        <Grid item xs={12} md={4} sx={{ mt: { xs: 1, md: 0 } }}>
                            <Search
                                onSearch={s => setRowsFilter(prev => ({ ...prev, page: 0, search: s }))}
                                onClear={() => {
                                    setRowsFilter(prev => ({
                                        page: 0,
                                        pageSize: prev.pageSize,
                                        sort: prev.sort,
                                        sortAsc: prev.sortAsc
                                    }));
                                }}
                                autoFocus={true}
                            />
                        </Grid>
                    </Grid>
                </ContentTop>
                <ContentBottom>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <Grid container columnSpacing={1}>
                            <Grid item xs={12} sm={5} lg={4} xl={3} sx={{ maxWidth: '380px!important' }}>
                                <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                                    <Paper variant="outlined" sx={{ p: 1, position: 'absolute', width: '100%', height: '100%' }}>
                                        <Box sx={{ width: '100%', height: '100%', overflowY: 'auto' }}>
                                            <Typography mt={1} textAlign="center" variant="h6">{localization.search}</Typography>
                                            <TasksFiltering
                                                filter={rowsFilter}
                                                hideStartDate={display === 'calendar'}
                                                taskStates={taskStates}
                                                taskTypes={taskTypes}
                                                users={users}
                                                onSaveDelay={250}
                                                onSave={(filter) => setRowsFilter({ ...filter, page: 0 })}
                                                onReset={() => setRowsFilter(prev => ({
                                                    page: 0,
                                                    pageSize: prev.pageSize,
                                                    sort: prev.sort,
                                                    sortAsc: prev.sortAsc
                                                }))}
                                            />
                                        </Box>
                                    </Paper>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm lg xl>
                                <Box sx={{ height: '100%', width: '100%', ...tasksGridStyles }}>
                                    <div style={{ display: ((display === 'standard' || display === 'compact') ? 'flex' : 'none'), height: '100%' }}>
                                        <DataGrid
                                            getRowClassName={(params) => 'gridstyle-state-' + (params.row.taskStateId ?? 0)}
                                            getRowId={row => row.id}
                                            density={(display === 'standard' ? 'standard' : 'compact')}
                                            checkboxSelection
                                            disableSelectionOnClick

                                            columns={columns}
                                            rows={rows}
                                            rowCount={rowsCount}

                                            pagination
                                            paginationMode="server"
                                            page={rowsFilter.page}
                                            pageSize={rowsFilter.pageSize}

                                            rowsPerPageOptions={[10, 25, 50]}
                                            onPageChange={(page) => setRowsFilter(prev => ({ ...prev, page: page }))}
                                            onPageSizeChange={(pageSize) => {
                                                setRowsFilter(prev => ({ ...prev, page: 0, pageSize: pageSize }));
                                                gridSettings.pageSizeChanged(pageSize);
                                            }}

                                            sortingMode="server"
                                            sortModel={sortModel}
                                            onSortModelChange={(model) => setSortModel(model)}

                                            localeText={LocalizeGrid()}
                                            loading={loading}

                                            // Dvoj-klik (úprava)
                                            onCellDoubleClick={(e, c) => {

                                                // Skontrolujem oprávnenia
                                                if ((e.row.canWrite ?? false) === false) {
                                                    return;
                                                }

                                                handleCreate(e.row.id, false, e.field);
                                            }}

                                            // Klávesnica (shift+enter => upraviť, shift+delete => vymazať, shift+space => označiť, vstavaná funkcia)
                                            onCellKeyDown={(e, c) => {

                                                // Skontrolujem oprávnenia
                                                if ((e.row.canWrite ?? false) === false) {
                                                    return;
                                                }

                                                if (c.code === 'Enter' && c.ctrlKey) {
                                                    c.preventDefault();
                                                    c.stopPropagation();
                                                    handleCreate(e.row.id, true, e.field);
                                                    return;
                                                }
                                                if (c.code === 'Enter' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                                    c.preventDefault();
                                                    c.stopPropagation();
                                                    handleCreate(e.row.id, false, e.field);
                                                    return;
                                                }
                                                if (c.code === 'Delete' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                                    c.preventDefault();
                                                    c.stopPropagation();
                                                    handleDelete(e.row.id, e.row.name);
                                                    return;
                                                }
                                            }}

                                            // Filtrácia
                                            filterModel={filterModel}
                                            onFilterModelChange={e => setFilterModel(e)}

                                            // Vybrané záznamy
                                            selectionModel={rowsSelected}
                                            onSelectionModelChange={e => setRowsSelected(e)}

                                            // Stĺpce (automatické ukladanie nastavení)
                                            onColumnVisibilityChange={e => gridSettings.columnVisibilityChanged(e, columnsDefault)}
                                        />
                                    </div>

                                    {/* 
                                        PRIKLADY
                                        ----------------
                                        https://github.com/fullcalendar/fullcalendar-react
                                        https://github.com/fullcalendar/fullcalendar-examples/blob/main/react/src/DemoApp.jsx 
                                        https://codesandbox.io/p/sandbox/react-fullcalendar-0ienw?file=%2Fsrc%2Findex.js 
                                        https://isamatov.com/react-fullcalendar-tutorial/ 
                                    */}

                                    <div style={{ display: ((display === 'calendar') ? 'flex' : 'none'), height: '100%', position: 'relative' }}>
                                        <Paper variant="outlined" sx={{ p: 1, position: 'absolute', width: '100%', height: '100%' }}>
                                            <FullCalendar
                                                ref={calendarRef}
                                                plugins={[dayGridPlugin, timeGridPlugin, listPlugin, multiMonthPlugin, interactionPlugin]}
                                                height='100%'
                                                locale={calendarLocale()}

                                                firstDay={1}
                                                selectable={true}
                                                editable={true}
                                                allDaySlot={false}
                                                expandRows={true}
                                                dayMaxEventRows={true}
                                                multiMonthMaxColumns={2}

                                                headerToolbar={{
                                                    left: 'prev,today,next prevYear,nextYear',
                                                    center: 'title',
                                                    right: 'listWeek dayGridMonth,timeGridWeek,timeGridDay,multiMonthYear',
                                                }}

                                                datesSet={(e) => {
                                                    setRowsFilterCalendar(prev => ({
                                                        ...prev,
                                                        startFrom: e.start,
                                                        startTo: e.end
                                                    }));
                                                }}

                                                events={[
                                                    // Úlohy
                                                    ...rows.map(item => ({
                                                        id: (item.id ?? 0).toString(),
                                                        title: item.name,
                                                        start: item.start,
                                                        end: item.end,
                                                        borderColor: '#' + (item.taskStateColor ?? ''),
                                                        backgroundColor: '#' + (item.taskStateColor ?? ''),
                                                        editable: (item.canWrite ?? false)
                                                    })),
                                                    // Zvýraznenie dní s úlohami s najvyššou prioritou
                                                    ...rowsHighlighted?.map(date => ({
                                                        display: 'background',
                                                        start: date,
                                                        color: '#ff0000',
                                                        allDay: true
                                                    })),

                                                ] as EventInput[]}

                                                // Poznačím si aktuálne zobrazenie po zmene, aby som vedel meniť formát v stĺpci dňa
                                                viewDidMount={(v) => setCalendarView(v.view.type)}
                                                initialView={calendarView}

                                                // Kalendár formátuje dátum rovnako ako prehliadač, takže pri EN: 12/12/2023, pri SK: 12.12.2023 preto vynútim formát ručne
                                                // Ak je zobrazený mesiac, tak zobrazujem len názov dňa "Utorok"
                                                // Štandardne zobrazím aj dátum "Utorok 12.12"
                                                dayHeaderFormat={(d) => {
                                                    if (calendarView === 'dayGridMonth') {
                                                        return dateFormat(d.date.marker, 'EEEE', { locale: GetDateLocale() });
                                                    }
                                                    return dateFormat(d.date.marker, 'EEEE dd.MM', { locale: GetDateLocale() });
                                                }}

                                                // Detail úlohy
                                                eventClick={(e) => {
                                                    handleDetail(parseInt(e.event.id));
                                                }}

                                                // Vytvorenie úlohy po označení času v kalendári
                                                select={(e: DateSelectArg) => {
                                                    const newTask: Task = {
                                                        start: e.start ?? new Date(),
                                                        end: e.end ?? new Date(),
                                                        finish: addDays(e.end ?? new Date(), TaskDefaultTime.toFinishDays)
                                                    };
                                                    if (e.allDay) {
                                                        newTask.start = addHours(new Date((newTask.start ?? new Date()).toDateString()), TaskDefaultTime.toStartHours);
                                                        newTask.end = addHours(newTask.start ?? new Date(), TaskDefaultTime.toEndHours);
                                                        newTask.finish = addDays(newTask.end ?? new Date(), TaskDefaultTime.toFinishDays);
                                                    }
                                                    setTaskCreate((prev: TaskCreateProps) => ({
                                                        ...prev,
                                                        task: newTask,
                                                        open: true
                                                    }));
                                                }}

                                                // Zmena času úlohy
                                                eventChange={(e) => {
                                                    handleChange(parseInt(e.event.id), e.event.start ?? new Date(), e.event.end ?? new Date());
                                                }}

                                            />
                                        </Paper>
                                    </div>

                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                </ContentBottom>
            </Content>

            {/* Detail úlohy  */}
            <TasksDetail id={rowsDetailId} taskStates={taskStates} taskTypes={taskTypes} users={users}
                onChanged={() => loadDataSafe()}
                onClose={() => setDetailId(0)}
                onEdit={(copy?: boolean) => { handleCreate(rowsDetailId, copy); setDetailId(0); }}
                onDelete={(name: string) => { handleDelete(rowsDetailId, name); setDetailId(0); }} />
        </>
    )
}

export default Tasks;