import { useCallback, useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { GetLanguage, Localize } from '../../AppLocalization';

// Modely
import { TaskState, TaskType, User, SaveAction, TaskLite, PriorityColors, ActivityList, TaskTemplate } from '../../models/Models';

// Utility
import { format as dateFormat } from 'date-fns';
import { ConvertToInt } from '../../utility/Convert';

// Komponenty
import { Box, Button, Card, CardContent, Chip, Grid, Paper, Select, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { Content, ContentTop, ContentBottom } from '../../layout/Content';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import TasksDetail from '../task/TasksDetail';
import TaskCreate, { TaskCreateProps } from '../task/TaskCreate';
import ActivitiesItem from './ActivitiesItem';
import { Timeline, timelineOppositeContentClasses } from '@mui/lab';

// Ikony
import ReplayIcon from '@mui/icons-material/Replay';

interface ActivitiesProps {

}

const Activities = (props: ActivitiesProps) => {

    // Jazyková mutácia
    const localization = Localize();

    // Všeobecne
    const [loading, setLoading] = useState<boolean>(true);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });

    // Aktivity
    const timerTime = 60;
    const [activities, setActivities] = useState<ActivityList>();
    const [activitiesLastDays, setActivitiesLastDays] = useState<number>(2);
    const [timer, setTimer] = useState<number>(timerTime);

    // Funkcia pre načítanie dát z API
    const loadActivities = useCallback(() => {
        setLoading(true);
        setTimer(timerTime);
        axios
            .get(AppConfig.ApiUri + 'activity', {
                params: {
                    days: activitiesLastDays,
                    language: GetLanguage()
                }
            })
            .then(response => {
                setActivities(response.data as ActivityList);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [activitiesLastDays]);
    useEffect(() => { loadActivities(); }, [activitiesLastDays]);  // eslint-disable-line react-hooks/exhaustive-deps

    // Časovač pre automatickú obnovu
    useEffect(() => {
        const interval = setInterval(() => {
            setTimer(prev => (prev - 1));
        }, 1000);
        return () => clearInterval(interval);
    }, []);
    useEffect(() => {
        if (timer < 0) {
            loadActivities();
        }
    }, [timer]); // eslint-disable-line react-hooks/exhaustive-deps

    // Úlohy dnes
    const [tasks, setTasks] = useState<TaskLite[]>([]);
    const [tasksDetailId, setTasksDetailId] = useState<number>(0);
    const [taskStates, setTaskStates] = useState<TaskState[]>([]);
    const [taskTypes, setTaskTypes] = useState<TaskType[]>([]);
    const [taskTemplates, setTaskTemplates] = useState<TaskTemplate[]>([]);
    const [users, setUsers] = useState<User[]>([]);

    // Funkcia pre načítanie dát z API
    const loadTasks = () => {
        axios
            .get(AppConfig.ApiUri + 'task/today')
            .then(response => {
                setTasks(response.data as TaskLite[]);
            });
    };

    // Načítam dáta po zobrazení
    useEffect(() => loadTasks(), []);

    // Načítam číselníky jedným volaním
    const loadFormData = () => {
        axios
            .get(AppConfig.ApiUri + 'task/formdata')
            .then(response => {
                if (response.data) {
                    applyFormData(response.data);
                }
            });
    };
    useEffect(() => loadFormData(), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Funkcia pre naplnenie stavu číselníkov
    const applyFormData = (data: any) => {
        if (data.taskstate) {
            setTaskStates(data.taskstate);
        }
        if (data.tasktype) {
            setTaskTypes(data.tasktype);
        }
        if (data.tasktemplate) {
            setTaskTemplates(data.tasktemplate);
        }
        if (data.user) {
            setUsers(data.user);
        }
    };

    const [taskCreate, setTaskCreate] = useState<TaskCreateProps>({
        open: false,
        keepMounted: true,
        taskStates: [],
        taskTypes: [],
        taskTemplates: [],
        users: [],
        onTasksChanged: () => loadTasks(),
        onSave: (id?: number, action?: SaveAction) => {
            // Obnovím zoznam
            loadTasks();
            loadActivities();

            // Rozšírené funkcie po uložení
            if (id !== undefined && action !== undefined) {
                if (action === SaveAction.SaveAndNew) {
                    handleCreate(0);
                    return;
                }
                if (action === SaveAction.SaveAndContinue) {
                    handleCreate(id ?? 0);
                    return;
                }
                if (action === SaveAction.SaveAndNewFromCopy) {
                    handleCreate(id ?? 0, true);
                    return;
                }
            }
        },
        onClose: () => setTaskCreate(prev => ({ ...prev, id: 0, copy: false, task: undefined, open: false }))
    });

    // Pridať upraviť záznam (gridview)
    const handleCreate = (id: number, copy?: boolean, field?: string) => {
        setTaskCreate(prev => ({
            ...prev,
            id: id,
            copy: copy ?? false,
            open: true,
            autoFocus: field
        }));
    };

    // Vymazať záznam
    const handleDelete = (id: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: localization.task + ': ' + name, children: localization.deleteConfirmation, onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'task/' + id)
                    .then(response => {
                        if (response.data === true) {
                            loadTasks();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    return (
        <>
            <Content>
                <ContentTop>
                    <Grid container alignItems="center" columnSpacing={2}>
                        <Grid item xs sm lg>
                            <Button variant="contained" sx={{ mr: 1 }} size="large"
                                startIcon={
                                    loading === true ?
                                        <ReplayIcon sx={{ animation: "spin 2s linear infinite", "@keyframes spin": { "0%": { transform: "rotate(360deg)", }, "100%": { transform: "rotate(0deg)", }, }, }} /> :
                                        <ReplayIcon />
                                }
                                onClick={() => loadActivities()}>{localization.refresh} <Typography component="div" sx={{ width: '35px', textAlign: 'right', opacity: 0.4 }}>({timer})</Typography></Button>
                            <Select size="small" variant="standard" sx={{ ml: 1 }} value={activitiesLastDays} onChange={(e) => { setActivitiesLastDays(ConvertToInt(e.target.value) ?? activitiesLastDays); }}>
                                <MenuItem value={1}>{localization.today}</MenuItem>
                                <MenuItem value={2}>{localization.lastNDays.replace('[n]', '2')}</MenuItem>
                                <MenuItem value={7}>{localization.lastNDays.replace('[n]', '7')}</MenuItem>
                                <MenuItem value={30}>{localization.lastNDays.replace('[n]', '30')}</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={5} lg={4}>
                            <Typography pl={2} mb={-1} variant="h6"><strong>{localization.today}</strong>, {localization.tasks.toLowerCase()}: {tasks.length ?? 0}</Typography>
                        </Grid>
                    </Grid>
                </ContentTop>
                <ContentBottom>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <Grid container columnSpacing={1}>

                            <Grid item xs sm lg>
                                <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                                    <Paper variant="outlined" sx={{ p: 1, position: 'absolute', width: '100%', height: '100%' }}>
                                        <Box sx={{ width: '100%', height: '100%', overflowY: 'auto' }}>
                                            {activities?.days?.map((day, dayIndex) =>
                                                <Box key={dayIndex}>
                                                    <Typography variant='h4' sx={{ position: 'sticky', p: '15px 0 10px 20px', top: 0, left: 0, zIndex: 10, background: 'white' }}>
                                                        {day.isToday === true ? localization.today : dateFormat(new Date(day.date ?? new Date()), 'dd.MM.yyyy')}
                                                    </Typography>
                                                    <Timeline
                                                        sx={{
                                                            mt: 2,
                                                            [`& .${timelineOppositeContentClasses.root}`]: {
                                                                maxWidth: '100px'
                                                            },
                                                        }}>
                                                        {day.items?.map((item, itemIndex) =>
                                                            <ActivitiesItem
                                                                key={itemIndex}
                                                                item={item}
                                                                connector={(itemIndex + 1) < (day.items?.length ?? 0)}
                                                                taskStates={taskStates}
                                                                onTaskDetail={(id: number) => setTasksDetailId(id)}
                                                            />)}
                                                    </Timeline>
                                                </Box>
                                            )}
                                            {(activities?.days?.length ?? 0) === 0 &&
                                                <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', height: '100%', opacity: .3 }}>
                                                    <Typography gutterBottom variant="h6" component="div" textAlign="center">
                                                        {activities !== undefined ? localization.noActivities : localization.wait + '...'}
                                                    </Typography>
                                                </Box>
                                            }
                                        </Box>
                                    </Paper>
                                </Box>
                            </Grid>

                            <Grid item xs={5} lg={4}>
                                <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                                    <Box sx={{ p: 0, position: 'absolute', width: '100%', height: '100%' }}>
                                        <Box sx={{ px: 1, py: .1, width: '100%', height: '100%', overflowY: 'auto' }}>
                                            {tasks?.map(task => {
                                                return (
                                                    <Card key={task.id} sx={{ mb: 1, position: 'relative', borderLeft: '5px solid #' + ((task.taskStateColor ?? '').length > 0 ? task.taskStateColor : 'f0f0f0') }}>
                                                        <Button onClick={() => setTasksDetailId(task.id ?? 0)} sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></Button>
                                                        <CardContent>
                                                            <Typography color="text.secondary">
                                                                {dateFormat(new Date(task.start ?? new Date()), 'HH:mm')}
                                                                {task.start !== task.end && ' - ' + dateFormat(new Date(task.end ?? new Date()), 'HH:mm')}
                                                            </Typography>
                                                            <Typography gutterBottom variant="h6" component="div">
                                                                {task.name}
                                                            </Typography>
                                                            <Chip size="small" label={task.taskStateName} sx={{ mr: .5, bgcolor: '#' + ((task.taskStateColor ?? '').length > 0 ? task.taskStateColor : 'f0f0f0'), color: ((task.taskStateColor ?? '').length > 0 ? '#ffffff' : '#222222') }} />
                                                            <Chip size="small" variant='outlined' label={localization.priority + ': ' + (task.priority ?? 0)} sx={{ mr: .5, borderColor: '#' + (PriorityColors[(task.priority ?? 1) - 1]), color: '#' + (PriorityColors[(task.priority ?? 1) - 1]) }} />
                                                            {(task.taskTypeName?.length ?? 0) > 0 && <Chip size="small" variant='outlined' label={task.taskTypeName} sx={{ mr: .5 }} />}
                                                        </CardContent>
                                                    </Card>
                                                )
                                            })}
                                            {(tasks?.length ?? 0) === 0 &&
                                                <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', height: '100%', background: '#d6d6d6', borderRadius: '3px', opacity: .3 }}>
                                                    <Typography gutterBottom variant="h6" component="div" textAlign="center">
                                                        {activities !== undefined ? localization.noTasksForToday : localization.wait + '...'}
                                                    </Typography>
                                                </Box>
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                        </Grid>
                    </div>
                </ContentBottom>
            </Content>

            {/* Detail úlohy  */}
            <TasksDetail id={tasksDetailId}
                taskStates={taskStates}
                taskTypes={taskTypes}
                users={users}
                onChanged={() => { loadTasks(); loadActivities(); }}
                onClose={() => setTasksDetailId(0)}
                onEdit={(copy?: boolean) => { handleCreate(tasksDetailId, copy); setTasksDetailId(0); }}
                onDelete={(name: string) => { handleDelete(tasksDetailId, name); setTasksDetailId(0); }} />

            {/* Formulár pre nový záznam */}
            <TaskCreate {...taskCreate} taskStates={taskStates} taskTypes={taskTypes} taskTemplates={taskTemplates} users={users} />

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

        </>
    )
}

export default Activities;